import axios from 'axios'
import { SERVICE_ELEND_TASK_DOMAIN } from 'src/constants/constants'
import { accountHelpers } from '../utils/account-helper'

const createTask = (data) => {
  return axios.post(SERVICE_ELEND_TASK_DOMAIN + '/api/tasks/create', data)
}

const updateTask = (data) => {
  return axios.put(SERVICE_ELEND_TASK_DOMAIN + '/api/tasks/update', data)
}

const updateTaskStatus = (data) => {
  return axios.put(SERVICE_ELEND_TASK_DOMAIN + '/api/tasks/status', data)
}

const getTasks = (data) => {
  let url =
    SERVICE_ELEND_TASK_DOMAIN +
    `/api/tasks?sort=statusProgressIndex,desc&sort=orderIndex,asc&sort=dueDate,asc`

  if (!accountHelpers.hasAccessPermission() && data.status.toLowerCase() === 'delay') {
    data.assignTo = accountHelpers.getAccount().id
  } else if (!accountHelpers.hasAccessPermission() || data.status.toLowerCase() === 'my-tasks') {
    data.assignTo = accountHelpers.getAccount().id
  }

  if (data.assignTo && data.assignTo.length > 0) {
    url += `&assignTo.contains=${data.assignTo}`
  }

  if (data.status && data.status.length > 0) {
    if (data.status.toLowerCase() === 'delay') {
      url += `&warning.equals=true&status.notEquals=DONE`
    } else if (data.status.toLowerCase() !== 'my-tasks') {
      url += `&status.equals=${data.status}`
    }
  } else {
    url += '&status.notEquals=DONE'
  }

  if (data && data.all) {
    return axios.get(url)
  } else {
    url += `&page=${data.page}&size=${data.size}`
  }
  return axios.get(url)
}

const getTask = (taskId) => {
  return axios.get(SERVICE_ELEND_TASK_DOMAIN + `/api/tasks/${taskId}`)
}

const deleteTask = (taskId) => {
  return axios.delete(SERVICE_ELEND_TASK_DOMAIN + `/api/tasks/${taskId}`)
}

const createTaskNote = (data) => {
  return axios.post(SERVICE_ELEND_TASK_DOMAIN + `/api/tasks/${data.taskId}/comment`, data)
}

const updateTaskNote = (data) => {
  return axios.put(SERVICE_ELEND_TASK_DOMAIN + `/api/tasks/${data.taskId}/comment`, data)
}

const deleteTaskNote = (data) => {
  return axios.delete(SERVICE_ELEND_TASK_DOMAIN + `/api/tasks/comment/${data.commentId}`)
}

export const taskService = {
  createTask,
  updateTask,
  getTasks,
  getTask,
  deleteTask,
  createTaskNote,
  updateTaskNote,
  deleteTaskNote,
  updateTaskStatus,
}
