import * as t from './actionTypes'

const initialState = {
  isLoading: false,
  hasError: false,
}

const registerReducer = (state = initialState, action) => {
  switch (action.type) {
    case t.ACTIVATE_ACCOUNT_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        hasError: false,
      })
    case t.ACTIVATE_ACCOUNT_FAILURE:
      return Object.assign({}, state, {
        isLoading: false,
        hasError: true,
      })
    default:
      return state
  }
}

export default registerReducer
