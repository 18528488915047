export const CREATE_LOAN_REQUEST = 'CREATE_LOAN_REQUEST'
export const CREATE_LOAN_SUCCESS = 'CREATE_LOAN_SUCCESS'
export const CREATE_LOAN_FAILURE = 'CREATE_LOAN_FAILURE'

export const UPDATE_LOAN_REQUEST = 'UPDATE_LOAN_REQUEST'
export const UPDATE_LOAN_SUCCESS = 'UPDATE_LOAN_SUCCESS'
export const UPDATE_LOAN_FAILURE = 'UPDATE_LOAN_FAILURE'

export const GET_ALL_LOANS_REQUEST = 'GET_ALL_LOANS_REQUEST'
export const GET_ALL_LOANS_SUCCESS = 'GET_ALL_LOANS_SUCCESS'
export const GET_ALL_LOANS_FAILURE = 'GET_ALL_LOANS_FAILURE'

export const GET_LOAN_REQUEST = 'GET_LOAN_REQUEST'
export const GET_LOAN_SUCCESS = 'GET_LOAN_SUCCESS'
export const GET_LOAN_FAILURE = 'GET_LOAN_FAILURE'

export const APPROVE_LOAN_REQUEST = 'APPROVE_LOAN_REQUEST'
export const APPROVE_LOAN_SUCCESS = 'APPROVE_LOAN_SUCCESS'
export const APPROVE_LOAN_FAILURE = 'APPROVE_LOAN_FAILURE'

export const UNDO_APPROVAL_LOAN_REQUEST = 'UNDO_APPROVAL_LOAN_REQUEST'
export const UNDO_APPROVAL_LOAN_SUCCESS = 'UNDO_APPROVAL_LOAN_SUCCESS'
export const UNDO_APPROVAL_LOAN_FAILURE = 'UNDO_APPROVAL_LOAN_FAILURE'

export const REJECT_LOAN_REQUEST = 'REJECT_LOAN_REQUEST'
export const REJECT_LOAN_SUCCESS = 'REJECT_LOAN_SUCCESS'
export const REJECT_LOAN_FAILURE = 'REJECT_LOAN_FAILURE'

export const UNDO_REJECT_LOAN_REQUEST = 'UNDO_REJECT_LOAN_REQUEST'
export const UNDO_REJECT_LOAN_SUCCESS = 'UNDO_REJECT_LOAN_SUCCESS'
export const UNDO_REJECT_LOAN_FAILURE = 'UNDO_REJECT_LOAN_FAILURE'

export const DISBURSE_LOAN_REQUEST = 'DISBURSE_LOAN_REQUEST'
export const DISBURSE_LOAN_SUCCESS = 'DISBURSE_LOAN_SUCCESS'
export const DISBURSE_LOAN_FAILURE = 'DISBURSE_LOAN_FAILURE'

export const UNDO_DISBURSE_LOAN_REQUEST = 'UNDO_DISBURSE_LOAN_REQUEST'
export const UNDO_DISBURSE_LOAN_SUCCESS = 'UNDO_DISBURSE_LOAN_SUCCESS'
export const UNDO_DISBURSE_LOAN_FAILURE = 'UNDO_DISBURSE_LOAN_FAILURE'

export const CREATE_PAYMENT_REQUEST = 'CREATE_PAYMENT_REQUEST'
export const CREATE_PAYMENT_SUCCESS = 'CREATE_PAYMENT_SUCCESS'
export const CREATE_PAYMENT_FAILURE = 'CREATE_PAYMENT_FAILURE'

export const GET_PAYMENT_REQUEST = 'GET_PAYMENT_REQUEST'
export const GET_PAYMENT_SUCCESS = 'GET_PAYMENT_SUCCESS'
export const GET_PAYMENT_FAILURE = 'GET_PAYMENT_FAILURE'

export const REVERSE_PAYMENT_REQUEST = 'REVERSE_PAYMENT_REQUEST'
export const REVERSE_PAYMENT_SUCCESS = 'REVERSE_PAYMENT_SUCCESS'
export const REVERSE_PAYMENT_FAILURE = 'REVERSE_PAYMENT_FAILURE'

export const UPDATE_PAYMENT_REQUEST = 'UPDATE_PAYMENT_REQUEST'
export const UPDATE_PAYMENT_SUCCESS = 'UPDATE_PAYMENT_SUCCESS'
export const UPDATE_PAYMENT_FAILURE = 'UPDATE_PAYMENT_FAILURE'

export const PROCESS_LOAN_CALCULATOR_REQUEST = 'PROCESS_LOAN_CALCULATOR_REQUEST'
export const PROCESS_LOAN_CALCULATOR_SUCCESS = 'PROCESS_LOAN_CALCULATOR_SUCCESS'
export const PROCESS_LOAN_CALCULATOR_FAILURE = 'PROCESS_LOAN_CALCULATOR_FAILURE'

export const GET_PAYMENT_TYPES_REQUEST = 'GET_PAYMENT_TYPES_REQUEST'
export const GET_PAYMENT_TYPES_SUCCESS = 'GET_PAYMENT_TYPES_SUCCESS'
export const GET_PAYMENT_TYPES_FAILURE = 'GET_PAYMENT_TYPES_FAILURE'

export const GET_METHOD_REPAYMENTS_REQUEST = 'GET_METHOD_REPAYMENTS_REQUEST'
export const GET_METHOD_REPAYMENTS_SUCCESS = 'GET_METHOD_REPAYMENTS_SUCCESS'
export const GET_METHOD_REPAYMENTS_FAILURE = 'GET_METHOD_REPAYMENTS_FAILURE'

export const RESET_LOAN_REQUEST = 'RESET_LOAN_REQUEST'
export const RESET_LOAN_SUCCESS = 'RESET_LOAN_SUCCESS'
export const RESET_LOAN_FAILURE = 'RESET_LOAN_FAILURE'

export const DELETE_LOAN_REQUEST = 'DELETE_LOAN_REQUEST'
export const DELETE_LOAN_SUCCESS = 'DELETE_LOAN_SUCCESS'
export const DELETE_LOAN_FAILURE = 'DELETE_LOAN_FAILURE'

export const CONFIRM_REPAYMENT_REQUEST = 'CONFIRM_REPAYMENT_REQUEST'
export const CONFIRM_REPAYMENT_SUCCESS = 'CONFIRM_REPAYMENT_SUCCESS'
export const CONFIRM_REPAYMENT_FAILURE = 'CONFIRM_REPAYMENT_FAILURE'

export const PAY_OFF_DEBT_REPAYMENT_REQUEST = 'PAY_OFF_DEBT_REPAYMENT_REQUEST'
export const PAY_OFF_DEBT_REPAYMENT_SUCCESS = 'PAY_OFF_DEBT_REPAYMENT_SUCCESS'
export const PAY_OFF_DEBT_REPAYMENT_FAILURE = 'PAY_OFF_DEBT_REPAYMENT_FAILURE'

export const CLOSE_LOAN_REQUEST = 'CLOSE_LOAN_REQUEST'
export const CLOSE_LOAN_SUCCESS = 'CLOSE_LOAN_SUCCESS'
export const CLOSE_LOAN_FAILURE = 'CLOSE_LOAN_FAILURE'

export const GET_LOAN_NOTES_REQUEST = 'GET_LOAN_NOTES_REQUEST'
export const GET_LOAN_NOTES_SUCCESS = 'GET_LOAN_NOTES_SUCCESS'
export const GET_LOAN_NOTES_FAILURE = 'GET_LOAN_NOTES_FAILURE'

export const GET_LOAN_NOTE_DETAIL_REQUEST = 'GET_LOAN_NOTE_DETAIL_REQUEST'
export const GET_LOAN_NOTE_DETAIL_SUCCESS = 'GET_LOAN_NOTE_DETAIL_SUCCESS'
export const GET_LOAN_NOTE_DETAIL_FAILURE = 'GET_LOAN_NOTE_DETAIL_FAILURE'

export const CREATE_LOAN_NOTE_REQUEST = 'CREATE_LOAN_NOTE_REQUEST'
export const CREATE_LOAN_NOTE_SUCCESS = 'CREATE_LOAN_NOTE_SUCCESS'
export const CREATE_LOAN_NOTE_FAILURE = 'CREATE_LOAN_NOTE_FAILURE'

export const UPDATE_LOAN_NOTE_REQUEST = 'UPDATE_LOAN_NOTE_REQUEST'
export const UPDATE_LOAN_NOTE_SUCCESS = 'UPDATE_LOAN_NOTE_SUCCESS'
export const UPDATE_LOAN_NOTE_FAILURE = 'UPDATE_LOAN_NOTE_FAILURE'

export const DELETE_LOAN_NOTE_REQUEST = 'DELETE_LOAN_NOTE_REQUEST'
export const DELETE_LOAN_NOTE_SUCCESS = 'DELETE_LOAN_NOTE_SUCCESS'
export const DELETE_LOAN_NOTE_FAILURE = 'DELETE_LOAN_NOTE_FAILURE'

export const BLACKLIST_LOAN_REQUEST = 'BLACKLIST_LOAN_REQUEST'
export const BLACKLIST_LOAN_SUCCESS = 'BLACKLIST_LOAN_SUCCESS'
export const BLACKLIST_LOAN_FAILURE = 'BLACKLIST_LOAN_FAILURE'

export const UPDATE_LOANS_ITEMS_PER_PAGE_REQUEST = 'UPDATE_LOANS_ITEMS_PER_PAGE_REQUEST'
export const UPDATE_LOANS_ITEMS_PER_PAGE_SUCCESS = 'UPDATE_LOANS_ITEMS_PER_PAGE_SUCCESS'

export const UPDATE_LOAN_PLEDGES_REQUEST = 'UPDATE_LOAN_PLEDGES_REQUEST'
export const UPDATE_LOAN_PLEDGES_SUCCESS = 'UPDATE_LOAN_PLEDGES_SUCCESS'
export const UPDATE_LOAN_PLEDGES_FAILURE = 'UPDATE_LOAN_PLEDGES_FAILURE'

export const CALCULATE_LOAN_REQUEST = 'CALCULATE_LOAN_REQUEST'
export const CALCULATE_LOAN_SUCCESS = 'CALCULATE_LOAN_SUCCESS'
export const CALCULATE_LOAN_FAILURE = 'CALCULATE_LOAN_FAILURE'
