import * as t from './actionTypes'
import moment from 'moment'

const initialState = {
  alarm: null,
  alarms: [],
  task: null,
  tasks: [],
  taskComments: [],
  taskComment: null,
  isFetched: false,
  isFetching: false,
  itemsPerPage: 20,
  totalPages: 0,
  isRedirect: false,
  isDeleted: false,
}

const shortDateFormat = 'DD-MM-yyyy'

const convertTasks = (tasks) => {
  tasks.forEach((t) => {
    if (t.createdDate) {
      t.createdDate = moment(t.createdDate).format(shortDateFormat)
    } else {
      t.createdDate = ''
    }

    if (t.dueDate) {
      t.alarmDueDate = t.dueDate
      t.dueDate = moment(t.dueDate).format(shortDateFormat)
    } else {
      t.dueDate = ''
    }

    if (t.readDate) {
      t.readDate = t.readDate
      t.readDate = moment(t.readDate).format(shortDateFormat)
    } else {
      t.readDate = ''
    }

    if (t.endDate) {
      t.endDate = moment(t.endDate).format(shortDateFormat)
    } else {
      t.endDate = ''
    }

    if (t.startDate) {
      t.startDate = moment(t.startDate).format(shortDateFormat)
    } else {
      t.startDate = ''
    }
  })
  return tasks
}

const taskReducer = (state = initialState, action) => {
  switch (action.type) {
    case t.GET_ALL_ALARMS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
        isRedirect: false,
        isDeleted: false,
      })
    case t.GET_ALL_ALARMS_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: true,
        alarms: convertTasks(action.response.data),
        totalPages: Math.ceil(action.response.headers['x-total-count'] / state.itemsPerPage),
      })
    case t.GET_ALL_ALARMS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorFetch: action.error,
      })
    case t.CREATE_ALARM_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
        isRedirect: false,
        isDeleted: false,
      })
    case t.CREATE_ALARM_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: true,
        isRedirect: true,
        alarm: action.alarm,
      })
    case t.CREATE_ALARM_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorFetch: action.error,
      })

    case t.UPDATE_ALARM_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
        isRedirect: false,
        isDeleted: false,
      })
    case t.UPDATE_ALARM_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: true,
        isRedirect: true,
        alarm: action.alarm,
      })
    case t.UPDATE_ALARM_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorFetch: action.error,
      })
    case t.GET_ALARM_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
        isRedirect: false,
        isDeleted: false,
      })
    case t.GET_ALARM_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: true,
        isRedirect: true,
        alarm: action.alarm,
      })
    case t.GET_ALARM_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorFetch: action.error,
      })
    case t.RESET_ALARM_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
        isRedirect: false,
        isDeleted: false,
      })
    case t.RESET_ALARM_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: false,
        isRedirect: false,
        isDeleted: false,
        alarm: null,
        alarms: [],
      })
    case t.DELETE_ALARM_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
        isRedirect: false,
        isDeleted: false,
      })
    case t.DELETE_ALARM_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: true,
        isDeleted: true,
        isRedirect: true,
        alarm: null,
      })
    case t.DELETE_ALARM_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorFetch: action.error,
      })
    case t.GET_ALL_TASKS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
        isRedirect: false,
        isDeleted: false,
      })
    case t.GET_ALL_TASKS_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: true,
        tasks: convertTasks(action.response.data),
        totalPages: Math.ceil(action.response.headers['x-total-count'] / state.itemsPerPage),
      })
    case t.GET_ALL_TASKS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorFetch: action.error,
      })
    case t.CREATE_TASK_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
        isRedirect: false,
        isDeleted: false,
      })
    case t.CREATE_TASK_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: true,
        isRedirect: true,
        task: action.task,
      })
    case t.CREATE_TASK_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorFetch: action.error,
      })

    case t.UPDATE_TASK_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
        isRedirect: false,
        isDeleted: false,
      })
    case t.UPDATE_TASK_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: true,
        isRedirect: true,
        task: action.task,
      })
    case t.UPDATE_TASK_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorFetch: action.error,
      })
    case t.GET_TASK_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
        isRedirect: false,
        isDeleted: false,
      })
    case t.GET_TASK_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: true,
        isRedirect: true,
        task: action.task,
        taskComments: action.task.comments || [],
      })
    case t.GET_TASK_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorFetch: action.error,
      })
    case t.ASSIGNED_MEMBERS_TASK_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
        isRedirect: false,
        isDeleted: false,
      })
    case t.ASSIGNED_MEMBERS_TASK_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: true,
        isRedirect: true,
        task: action.task,
      })
    case t.GET_TASK_COMMENTS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
        isRedirect: false,
        isDeleted: false,
      })
    case t.GET_TASK_COMMENTS_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: true,
        isRedirect: true,
        taskComments: action.task.comments,
      })
    case t.GET_TASK_COMMENTS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorFetch: action.error,
      })
    case t.GET_TASK_COMMENT_DETAIL_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
        isRedirect: false,
        isDeleted: false,
      })
    case t.GET_TASK_COMMENT_DETAIL_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: true,
        isRedirect: true,
        taskComment: action.comment,
      })
    case t.GET_TASK_COMMENT_DETAIL_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorFetch: action.error,
      })
    case t.CREATE_TASK_COMMENT_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
        isRedirect: false,
      })
    case t.CREATE_TASK_COMMENT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: true,
        isRedirect: true,
        taskComments: action.task.comments,
      })
    case t.CREATE_TASK_COMMENT_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorFetch: action.error,
      })
    case t.UPDATE_TASK_COMMENT_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
        isRedirect: false,
        isDeleted: false,
      })
    case t.UPDATE_TASK_COMMENT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: true,
        isRedirect: true,
        taskComments: action.task.comments,
      })
    case t.UPDATE_TASK_COMMENT_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorFetch: action.error,
      })
    case t.DELETE_TASK_COMMENT_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
        isRedirect: false,
        isDeleted: false,
      })
    case t.DELETE_TASK_COMMENT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: true,
        isRedirect: true,
        isDeleted: true,
        taskComments: action.task.comments,
      })
    case t.DELETE_TASK_COMMENT_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorFetch: action.error,
      })

    case t.RESET_TASK_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
        isRedirect: false,
        isDeleted: false,
      })
    case t.RESET_TASK_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: true,
        isRedirect: true,
        isDeleted: false,
        task: null,
        taskComment: null,
        taskComments: [],
      })
    case t.DELETE_TASK_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
        isRedirect: false,
        isDeleted: false,
      })
    case t.DELETE_TASK_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: true,
        isDeleted: true,
        isRedirect: true,
        task: null,
      })
    case t.DELETE_TASK_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorFetch: action.error,
      })
    default:
      return state
  }
}

export default taskReducer
