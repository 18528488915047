import * as t from './actionTypes'

const initialState = {
  isLoading: false,
  hasError: false,
  resetPasswordSuccess: false,
}

const authenticationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case t.RESET_PASSWORD_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        hasError: false,
        resetPasswordSuccess: false,
      })
    case t.RESET_PASSWORD_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        hasError: false,
        resetPasswordSuccess: true,
      })
    case t.RESET_PASSWORD_FAILURE:
      return Object.assign({}, state, {
        isLoading: false,
        hasError: true,
        resetPasswordSuccess: false,
      })
    default:
      return state
  }
}

export default authenticationsReducer
