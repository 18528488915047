export const CREATE_BORROWER_REQUEST = 'CREATE_BORROWER_REQUEST'
export const CREATE_BORROWER_SUCCESS = 'CREATE_BORROWER_SUCCESS'
export const CREATE_BORROWER_FAILURE = 'CREATE_BORROWER_FAILURE'

export const UPDATE_BORROWER_REQUEST = 'UPDATE_BORROWER_REQUEST'
export const UPDATE_BORROWER_SUCCESS = 'UPDATE_BORROWER_SUCCESS'
export const UPDATE_BORROWER_FAILURE = 'UPDATE_BORROWER_FAILURE'

export const GET_ALL_BORROWERS_REQUEST = 'GET_ALL_BORROWERS_REQUEST'
export const GET_ALL_BORROWERS_SUCCESS = 'GET_ALL_BORROWERS_SUCCESS'
export const GET_ALL_BORROWERS_FAILURE = 'GET_ALL_BORROWERS_FAILURE'

export const GET_BORROWER_REQUEST = 'GET_BORROWER_REQUEST'
export const GET_BORROWER_SUCCESS = 'GET_BORROWER_SUCCESS'
export const GET_BORROWER_FAILURE = 'GET_BORROWER_FAILURE'

export const DELETE_BORROWER_REQUEST = 'DELETE_BORROWER_REQUEST'
export const DELETE_BORROWER_SUCCESS = 'DELETE_BORROWER_SUCCESS'
export const DELETE_BORROWER_FAILURE = 'DELETE_BORROWER_FAILURE'

export const RESET_BORROWER_REQUEST = 'RESET_BORROWER_REQUEST'
export const RESET_BORROWER_SUCCESS = 'RESET_BORROWER_SUCCESS'
export const RESET_BORROWER_FAILURE = 'RESET_BORROWER_FAILURE'

export const UPDATE_ITEMS_PER_PAGE_REQUEST = 'UPDATE_ITEMS_PER_PAGE_REQUEST'
export const UPDATE_ITEMS_PER_PAGE_SUCCESS = 'UPDATE_ITEMS_PER_PAGE_SUCCESS'

export const UPDATE_BORROWER_RATING_REQUEST = 'UPDATE_BORROWER_RATING_REQUEST'
export const UPDATE_BORROWER_RATING_SUCCESS = 'UPDATE_BORROWER_RATING_SUCCESS'
export const UPDATE_BORROWER_RATING_FAILURE = 'UPDATE_BORROWER_RATING_FAILURE'

export const REPLY_BORROWER_RATING_REQUEST = 'REPLY_BORROWER_RATING_REQUEST'
export const REPLY_BORROWER_RATING_SUCCESS = 'REPLY_BORROWER_RATING_SUCCESS'
export const REPLY_BORROWER_RATING_FAILURE = 'REPLY_BORROWER_RATING_FAILURE'

export const GET_ALL_BORROWER_RATINGS_REQUEST = 'GET_ALL_BORROWER_RATINGS_REQUEST'
export const GET_ALL_BORROWER_RATINGS_SUCCESS = 'GET_ALL_BORROWER_RATINGS_SUCCESS'
export const GET_ALL_BORROWER_RATINGS_FAILURE = 'GET_ALL_BORROWER_RATINGS_FAILURE'
