import * as t from './actionTypes'
import moment from 'moment'
import { loanHelpers } from '../../utils/loan-helper'
import { accountHelpers } from '../../utils/account-helper'

const loanStatus = {
  LOAN_CREATED: 'LOAN_CREATED',
  LOAN_UPDATED: 'LOAN_UPDATED',
}

const initialState = {
  isFetched: false,
  isFetching: false,
  loans: [],
  loan: null,
  loanNotes: [],
  loanNote: null,
  repayment: null,
  itemsPerPage: 10,
  totalPages: 0,
  isRedirect: false,
  isDeleted: false,
  loanCalculator: null,
  paymentTypes: [],
  methodRepayments: [],
  loanStatus: '',
  loanPledges: [],
  csvLoans: null,
}

const sumBy = (items, prop) => items.reduce((a, b) => +a + +b[prop], 0)
const roundNearestThousand = (number) => (number > 1000 ? Math.ceil(number / 1000) * 1000 : number)

const _generateRepaymentSchedules = (loan) => {
  if (!loan.loanRepaymentSchedules || loan.loanRepaymentSchedules.length === 0) return []
  let balance = loan.loansDTO.approvedAmount
  const today = moment()
  const yesterday = today.subtract(1, 'days')
  const repaymentSchedule = loan.loanRepaymentSchedules
  repaymentSchedule.forEach((item) => {
    const date = item.date ? moment(item.date) : ''
    const paidByDate = item.paidByDate ? moment(item.paidByDate) : ''
    const remainAmount = balance - item.principal
    balance = remainAmount > 0 ? remainAmount : 0
    item.id = item.id || ''
    item.installment = item.installment || ''
    item.date = date ? date.format('DD-MM-yyyy') : ''
    item.transaction =
      item.transaction && item.transaction.length > 0 ? item.transaction : 'common.Repayment'
    item.capital = item.principal
    // if (item.id && isPayOffDebt && item.penalties > 0) {
    //   item.interest = 0
    // }
    item.pending = item.id
      ? roundNearestThousand(item.totalDue)
      : roundNearestThousand(item.pending)
    item.balance = balance > 0 ? balance : 0
    item.total = item.principal + item.interest + item.fees + item.penalties
    item.received = paidByDate ? paidByDate.format('DD-MM-yyyy') : ''

    if (item.id && item.paidByDate) {
      if (paidByDate.isAfter(date)) {
        item._props = { color: 'danger' }
      } else {
        item._props = { color: 'success' }
      }
    } else if (item.id && yesterday.isAfter(date)) {
      item._props = { color: 'danger' }
    }
    item._cellProps = { total: { className: 'fw-bold' } }
  })

  return repaymentSchedule
}

const calculatePayDebtOff = (loanRepaymentSchedules) => {
  const pendingLoanRepaymentSchedules = loanRepaymentSchedules.filter((r) => r.id && r.totalDue > 0)
  return {
    nextInstallment:
      pendingLoanRepaymentSchedules && pendingLoanRepaymentSchedules.length > 0
        ? pendingLoanRepaymentSchedules[0].installment
        : 0,
    totalInstalments: loanRepaymentSchedules.filter((r) => r.id).length,
    remainingInstallments: pendingLoanRepaymentSchedules.length,
    principal:
      pendingLoanRepaymentSchedules && pendingLoanRepaymentSchedules.length > 0
        ? pendingLoanRepaymentSchedules[0].principal
        : 0,
    penalty:
      pendingLoanRepaymentSchedules && pendingLoanRepaymentSchedules.length > 0
        ? pendingLoanRepaymentSchedules[0].penalties
        : 0,
    interest:
      pendingLoanRepaymentSchedules && pendingLoanRepaymentSchedules.length > 0
        ? pendingLoanRepaymentSchedules[0].interest
        : 0,
    totalPayoffDebt: roundNearestThousand(sumBy(pendingLoanRepaymentSchedules, 'totalDue')),
  }
}

const calculatePayDebtOffEarly = (loanRepaymentSchedules) => {
  const today = moment()
  const payoffItem = {
    nextInstallment: 0,
    totalInstalments: 0,
    remainingInstallments: 0,
    principal: 0,
    penalty: 0,
    interest: 0,
    totalDue: 0,
    totalPrincipal: 0,
    totalPayoffDebt: 0,
  }
  let firstRepayment
  let remainingInstallments = 0
  loanRepaymentSchedules.forEach((repayment) => {
    if (!repayment.id || !repayment.dueDate || repayment.dueDate.length === 0) {
      return
    }
    const remainPrincipal =
      repayment.principal - repayment.principalRepaidDerived - repayment.principalRepaidAfterBanned
    if (remainPrincipal < 0) {
      console.error('Invalid remain principal during pay deb off early!')
      return
    }
    const mDueDate = moment(repayment.dueDate)
    const duration = mDueDate.diff(today, 'days')
    if (duration >= 0) {
      if (firstRepayment && firstRepayment.id !== repayment.id) {
        payoffItem.totalPrincipal += remainPrincipal + remainPrincipal * 0.08
        remainingInstallments++
      } else {
        firstRepayment = repayment
        payoffItem.totalPayoffDebt += firstRepayment.totalDue
      }
    } else {
      payoffItem.totalDue += repayment.totalDue
    }
  })
  if (firstRepayment) {
    payoffItem.nextInstallment = firstRepayment.installment
    payoffItem.principal = firstRepayment.principal
    payoffItem.interest = firstRepayment.interest
    payoffItem.penalty = firstRepayment.penalties
  }
  payoffItem.totalInstalments = loanRepaymentSchedules.filter((r) => r.id).length
  payoffItem.remainingInstallments = remainingInstallments
  payoffItem.totalPayoffDebt = roundNearestThousand(
    payoffItem.totalPrincipal + payoffItem.totalPayoffDebt + payoffItem.totalDue,
  )
  return payoffItem
}

const convertLoan = (loan) => {
  if (loan.enableAutoPenalty === null) {
    loan.enableAutoPenalty = false
  }
  const transactions = loan.loanTransactions
  if (!transactions || transactions.length === 0) return loan

  transactions.forEach((item) => {
    if (item.createdDate) {
      item.createdDate = moment(item.createdDate).format('DD-MM-yyyy')
    }
    if (item.submittedOn) {
      item.submittedOn = moment(item.submittedOn).format('DD-MM-yyyy')
    }
    if (item.completedDate) {
      item.completedDate = moment(item.completedDate).format('DD-MM-yyyy')
    }
    if (item.status === 'BANNED') {
      item._props = { color: 'danger' }
    }
  })
  const lastPayment =
    transactions && transactions.length > 2 ? transactions[transactions.length - 1] : 0
  const nextPayment = loan.loanRepaymentSchedules.filter(
    (i) =>
      (!i.paid || i.paid.length === 0 || i.paid === '0') && i.principal + i.interest === i.totalDue,
  )[0]

  const today = moment()
  const clonedRepaymentSchedules = JSON.parse(JSON.stringify(loan.loanRepaymentSchedules))
  const sortLoanRepaymentSchedules = clonedRepaymentSchedules.sort((a, b) => {
    return new Date(b.dueDate) - new Date(a.dueDate)
  })
  let arrearsDays = 0
  let arrearsAmount = 0
  let principalOverdue = 0
  let interestOverdue = 0
  let feesOverdue = 0
  let penaltiesOverdue = 0
  let arrearsLastSchedule = sortLoanRepaymentSchedules.filter(
    (r) => moment(r.dueDate).isBefore(today) && r.totalDue > 0,
  )[0]
  if (arrearsLastSchedule) {
    const overdueSchedules = sortLoanRepaymentSchedules.filter((r) =>
      moment(r.dueDate).isSameOrBefore(moment(arrearsLastSchedule.dueDate)),
    )
    const sumPrincipal = sumBy(overdueSchedules, 'principal')
    const sumPrincipalWrittenOffDerived = sumBy(overdueSchedules, 'principalWrittenOffDerived')
    const sumPrincipalRepaidDerived = sumBy(overdueSchedules, 'principalRepaidDerived')
    principalOverdue = sumPrincipal - sumPrincipalWrittenOffDerived - sumPrincipalRepaidDerived

    const sumInterest = sumBy(overdueSchedules, 'interest')
    const sumInterestWrittenOffDerived = sumBy(overdueSchedules, 'interestWrittenOffDerived')
    const sumInterestRepaidDerived = sumBy(overdueSchedules, 'interestRepaidDerived')
    const sumInterestWaivedDerived = sumBy(overdueSchedules, 'interestWaivedDerived')
    interestOverdue =
      sumInterest -
      sumInterestWrittenOffDerived -
      sumInterestRepaidDerived -
      sumInterestWaivedDerived

    const sumFees = sumBy(overdueSchedules, 'fees')
    const sumFeesWrittenOffDerived = sumBy(overdueSchedules, 'feesWrittenOffDerived')
    const sumFeesRepaidDerived = sumBy(overdueSchedules, 'feesRepaidDerived')
    const sumFeesWaivedDerived = sumBy(overdueSchedules, 'feesWaivedDerived')
    feesOverdue = sumFees - sumFeesWrittenOffDerived - sumFeesRepaidDerived - sumFeesWaivedDerived

    const sumPenalties = sumBy(overdueSchedules, 'penalties')
    const sumPenaltiesWrittenOffDerived = sumBy(overdueSchedules, 'penaltiesWrittenOffDerived')
    const sumPenaltiesRepaidDerived = sumBy(overdueSchedules, 'penaltiesRepaidDerived')
    const sumPenaltiesWaivedDerived = sumBy(overdueSchedules, 'penaltiesWaivedDerived')
    penaltiesOverdue =
      sumPenalties -
      sumPenaltiesWrittenOffDerived -
      sumPenaltiesRepaidDerived -
      sumPenaltiesWaivedDerived
    const sortedOverdueSchedules = overdueSchedules.sort((a, b) => {
      return new Date(a.dueDate) - new Date(b.dueDate)
    })
    const mOverdueDate = moment(sortedOverdueSchedules.filter((s) => s.totalDue > 0)[0].dueDate)
    arrearsDays = arrearsDays + today.diff(mOverdueDate, 'days')
  }
  arrearsAmount =
    arrearsDays > 0 ? principalOverdue + interestOverdue + feesOverdue + penaltiesOverdue : 0
  const summary = {
    hasArrearsAmount: arrearsAmount > 0,
    arrearsAmount: arrearsAmount,
    arrearsDays: arrearsDays,
    hasArrearsDays: arrearsDays > 0,
    lastPayment: lastPayment
      ? `${loanHelpers.currencyFormatter(lastPayment.amount)} on ${moment(
          lastPayment.createdOn,
        ).format('DD-MM-yyyy')}`
      : '---',
    nextPayment: nextPayment
      ? `${loanHelpers.currencyFormatter(nextPayment.totalDue)} on ${moment(
          nextPayment.dueDate,
        ).format('DD-MM-yyyy')}`
      : '',
    payoffDebt: calculatePayDebtOff(loan.loanRepaymentSchedules),
    payoffDebtEarly: calculatePayDebtOffEarly(loan.loanRepaymentSchedules),
  }

  loan.loansDTO.summary = { ...summary }
  loan.loanRepaymentSchedules = _generateRepaymentSchedules(loan)
  loan.loansDTO.summary.pendings = sumBy(loan.loanRepaymentSchedules, 'totalDue')
  loan.loansDTO.summary.totalPrincipalRepaidDerived = sumBy(
    loan.loanRepaymentSchedules,
    'principalRepaidDerived',
  )
  loan.loansDTO.summary.totalInterestRepaidDerived = sumBy(
    loan.loanRepaymentSchedules,
    'interestRepaidDerived',
  )
  loan.loansDTO.summary.totalPenaltyRepaidDerived = sumBy(
    loan.loanRepaymentSchedules,
    'penaltiesRepaidDerived',
  )
  loan.loansDTO.summary.totalFeeRepaidDerived = sumBy(
    loan.loanRepaymentSchedules,
    'feesRepaidDerived',
  )
  loan.loansDTO.summary.totalPaid =
    loan.loansDTO.summary.totalPrincipalRepaidDerived +
    loan.loansDTO.summary.totalInterestRepaidDerived +
    loan.loansDTO.summary.totalPenaltyRepaidDerived +
    loan.loansDTO.summary.totalFeeRepaidDerived
  return loan
}

const loanReducer = (state = initialState, action) => {
  switch (action.type) {
    case t.CREATE_LOAN_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
        isRedirect: false,
        isDeleted: false,
        loanStatus: '',
      })
    case t.CREATE_LOAN_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: true,
        isRedirect: true,
        loan: action.loan,
        loanStatus: loanStatus.LOAN_CREATED,
      })
    case t.CREATE_LOAN_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorFetch: action.error,
      })
    case t.UPDATE_LOAN_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
        isRedirect: false,
        isDeleted: false,
        loanStatus: '',
      })
    case t.UPDATE_LOAN_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: true,
        isRedirect: true,
        loan: convertLoan(action.loan),
        loanStatus: loanStatus.LOAN_UPDATED,
      })
    case t.UPDATE_LOAN_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorFetch: action.error,
      })
    case t.GET_ALL_LOANS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
        isRedirect: false,
        isDeleted: false,
        loanPledges: [],
      })
    case t.GET_ALL_LOANS_SUCCESS:
      const isExportCSV = action.response.headers['content-type'] === 'application/csv'
      if (isExportCSV) {
        return Object.assign({}, state, {
          isFetching: true,
          isFetched: false,
          csvLoans: action.response.data,
        })
      }
      action.response.data.forEach((loan) => {
        if (!loan.approvedAmount) {
          loan.approvedAmount = 0
        }
        if (loan.expectedDisbursementDate) {
          loan.expectedDisbursementDate = moment(loan.expectedDisbursementDate).format('YYYY-MM-DD')
        }
        if (!loan.notes) {
          loan.notes = ''
        }
      })
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: true,
        loans: action.response.data,
        totalPages: Math.ceil(action.response.headers['x-total-count'] / state.itemsPerPage),
      })
    case t.GET_ALL_LOANS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorFetch: action.error,
      })
    case t.GET_LOAN_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
        isRedirect: false,
        isDeleted: false,
        loanPledges: [],
      })
    case t.GET_LOAN_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: true,
        isRedirect: true,
        loan: convertLoan(action.loan),
        loanPledges: action.loan.loanPledges || [],
      })
    case t.GET_LOAN_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorFetch: action.error,
      })

    case t.APPROVE_LOAN_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
        isRedirect: false,
        isDeleted: false,
      })
    case t.APPROVE_LOAN_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: true,
        isRedirect: true,
        loan: convertLoan(action.loan),
      })
    case t.APPROVE_LOAN_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorFetch: action.error,
      })

    case t.UNDO_APPROVAL_LOAN_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
        isRedirect: false,
      })
    case t.UNDO_APPROVAL_LOAN_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: true,
        isRedirect: true,
        loan: convertLoan(action.loan),
      })
    case t.UNDO_APPROVAL_LOAN_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorFetch: action.error,
      })

    case t.REJECT_LOAN_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
        isRedirect: false,
      })
    case t.REJECT_LOAN_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: true,
        isRedirect: true,
        loan: convertLoan(action.loan),
      })
    case t.REJECT_LOAN_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorFetch: action.error,
      })

    case t.BLACKLIST_LOAN_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
        isRedirect: false,
        isDeleted: false,
      })
    case t.BLACKLIST_LOAN_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: true,
        isRedirect: true,
        loan: convertLoan(action.loan),
      })
    case t.BLACKLIST_LOAN_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorFetch: action.error,
      })

    case t.UNDO_REJECT_LOAN_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
        isRedirect: false,
        isDeleted: false,
      })
    case t.UNDO_REJECT_LOAN_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: true,
        isRedirect: true,
        loan: convertLoan(action.loan),
      })
    case t.UNDO_REJECT_LOAN_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorFetch: action.error,
      })

    case t.DISBURSE_LOAN_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
        isRedirect: false,
        isDeleted: false,
      })
    case t.DISBURSE_LOAN_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: true,
        isRedirect: true,
        loan: convertLoan(action.loan),
      })
    case t.DISBURSE_LOAN_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorFetch: action.error,
      })

    case t.UNDO_DISBURSE_LOAN_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
        isRedirect: false,
        isDeleted: false,
      })
    case t.UNDO_DISBURSE_LOAN_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: true,
        isRedirect: true,
        loan: convertLoan(action.loan),
      })
    case t.UNDO_DISBURSE_LOAN_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorFetch: action.error,
      })

    case t.CREATE_PAYMENT_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
        isRedirect: false,
        isDeleted: false,
      })
    case t.CREATE_PAYMENT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: true,
        isRedirect: true,
        loan: convertLoan(action.loan),
      })
    case t.CREATE_PAYMENT_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorFetch: action.error,
      })

    case t.GET_PAYMENT_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
        isRedirect: false,
        isDeleted: false,
      })
    case t.GET_PAYMENT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: true,
        isRedirect: true,
        loan: convertLoan(action.loan),
      })
    case t.GET_PAYMENT_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorFetch: action.error,
      })

    case t.REVERSE_PAYMENT_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
        isRedirect: false,
        isDeleted: false,
      })
    case t.REVERSE_PAYMENT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: true,
        isRedirect: true,
        loan: convertLoan(action.loan),
      })
    case t.REVERSE_PAYMENT_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorFetch: action.error,
      })

    case t.UPDATE_PAYMENT_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
        isRedirect: false,
        isDeleted: false,
      })
    case t.UPDATE_PAYMENT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: true,
        isRedirect: true,
        loan: convertLoan(action.loan),
      })
    case t.UPDATE_PAYMENT_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorFetch: action.error,
      })

    case t.PROCESS_LOAN_CALCULATOR_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
        isRedirect: false,
      })
    case t.PROCESS_LOAN_CALCULATOR_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: true,
        isRedirect: true,
        loanCalculator: action.loan,
      })
    case t.PROCESS_LOAN_CALCULATOR_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorFetch: action.error,
      })

    case t.GET_PAYMENT_TYPES_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
        isRedirect: false,
      })
    case t.GET_PAYMENT_TYPES_SUCCESS:
      let paymentTypes = [...action.paymentTypes]
      paymentTypes.forEach((item) => {
        switch (item.systemName) {
          case 'pay_fee':
            item.translateName = 'view.Loan.PayFee'
            break
          case 'pay_off_debt_by_admin':
            item.translateName = 'view.Loan.PayOffDebtByAdmin'
            break
          case 'capital_subscription':
            item.translateName = 'view.Loan.CapitalSubscription'
            break
          case 'interest_credit':
            item.translateName = 'view.Loan.InterestCredit'
            break
          case 'payment_to_arrears':
            item.translateName = 'view.Loan.PaymentToArrears'
            break
        }
      })

      return Object.assign({}, state, {
        isFetching: false,
        isFetched: true,
        isRedirect: true,
        paymentTypes: accountHelpers.hasAccessPermission()
          ? action.paymentTypes
          : action.paymentTypes.filter((p) => p.systemName !== 'pay_off_debt_by_admin'),
      })
    case t.GET_PAYMENT_TYPES_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorFetch: action.error,
      })

    case t.GET_METHOD_REPAYMENTS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
        isRedirect: false,
      })
    case t.GET_METHOD_REPAYMENTS_SUCCESS:
      let methodRepayments = [
        {
          id: 0,
          name: 'Select',
          description: 'please_select',
          active: true,
        },
      ]
      methodRepayments = methodRepayments.concat(action.methodRepayments)
      methodRepayments.forEach((item) => {
        switch (item.description) {
          case 'cash_deposit':
            item.translateName = 'view.Loan.Cash'
            break
          case 'transfer_deposit':
            item.translateName = 'view.Loan.TransferDeposit'
            break
          default:
            item.translateName = 'common.PleaseSelect'
            break
        }
      })
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: true,
        isRedirect: true,
        methodRepayments: methodRepayments,
      })
    case t.GET_METHOD_REPAYMENTS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorFetch: action.error,
      })
    case t.RESET_LOAN_REQUEST:
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: false,
        isRedirect: false,
        isDeleted: false,
        loanStatus: '',
        loanPledges: [],
      })
    case t.RESET_LOAN_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: false,
        isRedirect: true,
        loan: null,
        loans: [],
        loanStatus: '',
        loanPledges: [],
        csvLoans: null,
      })
    case t.DELETE_LOAN_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
        isRedirect: false,
        isDeleted: false,
        loanPledges: [],
      })
    case t.DELETE_LOAN_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: true,
        isDeleted: true,
        isRedirect: true,
        loanPledges: [],
      })
    case t.DELETE_LOAN_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorFetch: action.error,
      })
    case t.CONFIRM_REPAYMENT_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
        isRedirect: false,
      })
    case t.CONFIRM_REPAYMENT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: true,
        isRedirect: true,
        loan: convertLoan(action.loan),
      })
    case t.CONFIRM_REPAYMENT_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorFetch: action.error,
      })
    case t.GET_LOAN_NOTES_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
        isRedirect: false,
      })
    case t.GET_LOAN_NOTES_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: true,
        isRedirect: true,
        loanNotes: action.data.loanNotes,
      })
    case t.GET_LOAN_NOTES_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorFetch: action.error,
      })
    case t.GET_LOAN_NOTE_DETAIL_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
        isRedirect: false,
      })
    case t.GET_LOAN_NOTE_DETAIL_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: true,
        isRedirect: true,
        loanNote: action.loanNote,
      })
    case t.GET_LOAN_NOTE_DETAIL_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorFetch: action.error,
      })
    case t.CREATE_LOAN_NOTE_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
        isRedirect: false,
      })
    case t.CREATE_LOAN_NOTE_SUCCESS:
      const loansDTO = action.data.loansDTO
      const newLoans = JSON.parse(JSON.stringify(state.loans))
      if (newLoans && newLoans.length > 0) {
        const foundIndex = newLoans.findIndex((i) => i.id === loansDTO.id)
        if (foundIndex > -1) {
          newLoans[foundIndex].notes = loansDTO.notes
        }
      }

      return Object.assign({}, state, {
        isFetching: false,
        isFetched: true,
        isRedirect: true,
        loanNotes: action.data.loanNotes,
        loans: newLoans,
      })
    case t.CREATE_LOAN_NOTE_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorFetch: action.error,
      })
    case t.UPDATE_LOAN_NOTE_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
        isRedirect: false,
      })
    case t.UPDATE_LOAN_NOTE_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: true,
        isRedirect: true,
        loanNotes: action.data.loanNotes,
      })
    case t.UPDATE_LOAN_NOTE_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorFetch: action.error,
      })
    case t.DELETE_LOAN_NOTE_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
        isRedirect: false,
      })
    case t.DELETE_LOAN_NOTE_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: true,
        isRedirect: true,
        loanNotes: action.data.loanNotes,
      })
    case t.DELETE_LOAN_NOTE_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorFetch: action.error,
      })
    case t.UPDATE_LOANS_ITEMS_PER_PAGE_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
        isRedirect: false,
      })
    case t.UPDATE_LOANS_ITEMS_PER_PAGE_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: true,
        isRedirect: true,
        itemsPerPage: action.itemsPerPage,
      })
    case t.PAY_OFF_DEBT_REPAYMENT_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
        isRedirect: false,
      })
    case t.PAY_OFF_DEBT_REPAYMENT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: true,
        isRedirect: true,
        loan: convertLoan(action.loan),
      })
    case t.PAY_OFF_DEBT_REPAYMENT_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorFetch: action.error,
      })

    case t.UPDATE_LOAN_PLEDGES_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
        isRedirect: false,
      })
    case t.UPDATE_LOAN_PLEDGES_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: true,
        isRedirect: true,
        loanPledges: action.pledges,
      })
    case t.CALCULATE_LOAN_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
        isRedirect: false,
        isDeleted: false,
        loanPledges: [],
      })
    case t.CALCULATE_LOAN_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: true,
        isRedirect: true,
        loan: convertLoan(action.loan),
        loanPledges: [],
      })
    case t.CALCULATE_LOAN_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorFetch: action.error,
      })
    default:
      return state
  }
}
export default loanReducer
