import Keycloak from 'keycloak-js'
import api from '../utils/api'
import { APP_TOKEN } from 'src/constants/constants'

const _kc = new Keycloak('/keycloak.json')

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = (onAuthenticatedCallback) => {
  _kc
    .init({
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
      pkceMethod: 'S256',
    })
    .then((authenticated) => {
      if (!authenticated) {
        console.log('user is not authenticated..!')
      } else {
        localStorage.setItem(APP_TOKEN, getToken())

        const account = localStorage.getItem('account')
        if (!account) {
          api.userService.syncAccount().then((result) => {
            if (result && result.data) {
              localStorage.setItem('account', JSON.stringify(result.data))
            }
          })
        }
      }
      if (onAuthenticatedCallback) {
        onAuthenticatedCallback()
      }
    })
    .catch(console.error)
}

const doLogin = _kc.login

const doLogout = _kc.logout

const getToken = () => _kc.token

const getTokenParsed = () => _kc.tokenParsed

const isLoggedIn = () => !!_kc.token

const updateToken = (successCallback) => _kc.updateToken(5).then(successCallback).catch(doLogin)

const getUsername = () => _kc.tokenParsed?.preferred_username

const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role))

const KeycloakService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  getTokenParsed,
  updateToken,
  getUsername,
  hasRole,
}

export default KeycloakService
