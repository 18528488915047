import React, { Component } from 'react'
import { HashRouter } from 'react-router-dom'

import './scss/style.scss'
import AppWithRouterAccess from './AppWithRouterAccess'

class App extends Component {
  render() {
    return (
      <HashRouter>
        <AppWithRouterAccess />
      </HashRouter>
    )
  }
}

export default App
