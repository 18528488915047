import React from 'react'
import PropTypes from 'prop-types'
import { Navigate, Outlet } from 'react-router-dom'
import keycloakService from '../services/keycloak.service'

const PrivateRoute = ({ ...rest }) => {
  return (
    <>{keycloakService.isLoggedIn() ? <Outlet {...rest} /> : <Navigate to="/login" replace />}</>
  )
}
PrivateRoute.propTypes = {
  component: PropTypes.object,
}
export default PrivateRoute
