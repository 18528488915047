export const authConstants = {
  ACCOUNT_SYNC_REQUEST: 'ACCOUNT_SYNC_REQUEST',
  ACCOUNT_SYNC_SUCCESS: 'ACCOUNT_SYNC_SUCCESS',
  ACCOUNT_SYNC_FAILURE: 'ACCOUNT_SYNC_FAILURE',
  RESEND_VERIFY_EMAIL_REQUEST: 'RESEND_VERIFY_EMAIL_REQUEST',
  RESEND_VERIFY_EMAIL_SUCCESS: 'RESEND_VERIFY_EMAIL_SUCCESS',
  RESEND_VERIFY_EMAIL_FAILURE: 'RESEND_VERIFY_EMAIL_FAILURE',
  RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',
  CHANGE_PASSWORD_REQUEST: 'CHANGE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILURE: 'CHANGE_PASSWORD_FAILURE',
}
