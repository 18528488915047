import { toast } from 'react-toastify'
import * as t from './actionTypes'
import api from '../../utils/api'

function createTask(data) {
  return (dispatch) => {
    dispatch(request(data))
    return api.taskService
      .createTask(data)
      .then((response) => {
        toast.success('Tạo giao việc thành công.')
        dispatch(success(response.data))
        window.location.href = '#/tasks'
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }

  function request() {
    return { type: t.CREATE_TASK_REQUEST }
  }

  function success(task) {
    return { type: t.CREATE_TASK_SUCCESS, task }
  }

  function failure(error) {
    return { type: t.CREATE_TASK_FAILURE, error }
  }
}

function updateTask(data) {
  return (dispatch) => {
    dispatch(request(data))

    const promiseUpdateTask = data.isProcessStatus
      ? api.taskService.updateTaskStatus(data)
      : api.taskService.updateTask(data)

    return promiseUpdateTask
      .then((response) => {
        toast.success('Cập nhật giao việc thành công.')
        dispatch(success(response.data))
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }

  function request() {
    return { type: t.UPDATE_TASK_REQUEST }
  }

  function success(task) {
    return { type: t.UPDATE_TASK_SUCCESS, task }
  }

  function failure(error) {
    return { type: t.UPDATE_TASK_FAILURE, error }
  }
}

function getTasks(data) {
  return (dispatch) => {
    dispatch(request(data))
    return api.taskService
      .getTasks(data)
      .then((response) => {
        dispatch(success(response))
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }

  function request() {
    return { type: t.GET_ALL_TASKS_REQUEST }
  }

  function success(response) {
    return { type: t.GET_ALL_TASKS_SUCCESS, response }
  }

  function failure(error) {
    return { type: t.GET_ALL_TASKS_FAILURE, error }
  }
}

function getTask(taskId) {
  return (dispatch) => {
    dispatch(request())
    return api.taskService
      .getTask(taskId)
      .then((response) => {
        dispatch(success(response.data))
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }

  function request() {
    return { type: t.GET_TASK_REQUEST }
  }

  function success(task) {
    return { type: t.GET_TASK_SUCCESS, task }
  }

  function failure(error) {
    return { type: t.GET_TASK_FAILURE, error }
  }
}

function assignedMembersToTask(task) {
  return (dispatch) => {
    dispatch(request(task))
    dispatch(success(task))
  }

  function request(task) {
    return { type: t.ASSIGNED_MEMBERS_TASK_REQUEST, task }
  }

  function success(task) {
    return { type: t.ASSIGNED_MEMBERS_TASK_SUCCESS, task }
  }
}

function createTaskComment(data) {
  return (dispatch) => {
    dispatch(request())
    return api.taskService
      .createTaskNote(data)
      .then((response) => {
        toast.success('Tạo ghi chú thành công!')
        dispatch(success(response.data))
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }

  function request() {
    return { type: t.CREATE_TASK_COMMENT_REQUEST }
  }

  function success(task) {
    return { type: t.CREATE_TASK_COMMENT_SUCCESS, task }
  }

  function failure(error) {
    return { type: t.CREATE_TASK_COMMENT_FAILURE, error }
  }
}

function updateTaskComment(data) {
  return (dispatch) => {
    dispatch(request())
    return api.taskService
      .updateTaskNote(data)
      .then((response) => {
        toast.success('Cập nhật ghi chú thành công.')
        dispatch(success(response.data))
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }

  function request() {
    return { type: t.UPDATE_TASK_COMMENT_REQUEST }
  }

  function success(task) {
    return { type: t.UPDATE_TASK_COMMENT_SUCCESS, task }
  }

  function failure(error) {
    return { type: t.UPDATE_TASK_COMMENT_FAILURE, error }
  }
}

function deleteTaskComment(data) {
  return (dispatch) => {
    dispatch(request())
    return api.taskService
      .deleteTaskNote(data)
      .then((response) => {
        toast.warning('Xóa ghi chú thành công!')
        dispatch(success(response.data))
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }

  function request() {
    return { type: t.DELETE_TASK_COMMENT_REQUEST }
  }

  function success(task) {
    return { type: t.DELETE_TASK_COMMENT_SUCCESS, task }
  }

  function failure(error) {
    return { type: t.DELETE_TASK_COMMENT_FAILURE, error }
  }
}

function deleteTask(taskId) {
  return (dispatch) => {
    dispatch(request())
    return api.taskService
      .deleteTask(taskId)
      .then((response) => {
        dispatch(success(response))
        toast.warning('Đã xóa giao việc!')
        window.location.href = `${window.location.origin}/#/tasks`
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }

  function request() {
    return { type: t.DELETE_TASK_REQUEST }
  }

  function success() {
    return { type: t.DELETE_TASK_SUCCESS }
  }

  function failure(error) {
    return { type: t.DELETE_TASK_FAILURE, error }
  }
}

function resetTask() {
  return (dispatch) => {
    dispatch(request())
    dispatch(success())
  }

  function request() {
    return { type: t.RESET_TASK_REQUEST }
  }

  function success() {
    return { type: t.RESET_TASK_SUCCESS }
  }
}

function createAlarm(data) {
  return (dispatch) => {
    dispatch(request(data))
    return api.alarmService
      .createAlarm(data)
      .then((response) => {
        toast.success('Đặt hẹn thành công.')
        dispatch(success(response.data))
        window.location.href = '#/tasks'
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }

  function request() {
    return { type: t.CREATE_ALARM_REQUEST }
  }

  function success(alarm) {
    return { type: t.CREATE_ALARM_SUCCESS, alarm }
  }

  function failure(error) {
    return { type: t.CREATE_ALARM_FAILURE, error }
  }
}

function updateAlarm(data) {
  return (dispatch) => {
    dispatch(request(data))

    return api.alarmService
      .updateAlarm(data)
      .then((response) => {
        toast.success('Cập nhật đặt hẹn thành công.')
        dispatch(success(response.data))
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }

  function request() {
    return { type: t.UPDATE_ALARM_REQUEST }
  }

  function success(alarm) {
    return { type: t.UPDATE_ALARM_SUCCESS, alarm }
  }

  function failure(error) {
    return { type: t.UPDATE_ALARM_FAILURE, error }
  }
}

function getAlarms(data) {
  return (dispatch) => {
    dispatch(request(data))
    return api.alarmService
      .getAlarms(data)
      .then((response) => {
        dispatch(success(response))
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }

  function request() {
    return { type: t.GET_ALL_ALARMS_REQUEST }
  }

  function success(response) {
    return { type: t.GET_ALL_ALARMS_SUCCESS, response }
  }

  function failure(error) {
    return { type: t.GET_ALL_ALARMS_FAILURE, error }
  }
}

function getAlarm(alarmId) {
  return (dispatch) => {
    dispatch(request())
    return api.alarmService
      .getAlarm(alarmId)
      .then((response) => {
        dispatch(success(response.data))
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }

  function request() {
    return { type: t.GET_ALARM_REQUEST }
  }

  function success(task) {
    return { type: t.GET_ALARM_SUCCESS, task }
  }

  function failure(error) {
    return { type: t.GET_ALARM_FAILURE, error }
  }
}

function deleteAlarm(alarmId) {
  return (dispatch) => {
    dispatch(request())
    return api.alarmService
      .deleteAlarm(alarmId)
      .then((response) => {
        dispatch(success(response))
        toast.warning('Đã xóa đặt hẹn!')
        window.location.href = `${window.location.origin}/#/tasks`
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }

  function request() {
    return { type: t.DELETE_ALARM_REQUEST }
  }

  function success() {
    return { type: t.DELETE_ALARM_SUCCESS }
  }

  function failure(error) {
    return { type: t.DELETE_ALARM_FAILURE, error }
  }
}

function resetAlarm() {
  return (dispatch) => {
    dispatch(request())
    dispatch(success())
  }

  function request() {
    return { type: t.RESET_ALARM_REQUEST }
  }

  function success() {
    return { type: t.RESET_ALARM_SUCCESS }
  }
}

export const taskActions = {
  createTask,
  updateTask,
  getTasks,
  getTask,
  assignedMembersToTask,
  createTaskComment,
  updateTaskComment,
  deleteTaskComment,
  resetTask,
  deleteTask,
  createAlarm,
  updateAlarm,
  getAlarms,
  getAlarm,
  deleteAlarm,
  resetAlarm,
}
