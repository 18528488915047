const uploadImage = (data) => {
  const formData = new FormData()
  formData.append('key', process.env.REACT_APP_CHEVERETO_API_KEY)
  formData.append('source', data)
  return fetch(process.env.REACT_APP_CHEVERETO_ENDPOINT + '/api/1/upload', {
    method: 'POST',
    body: formData,
  })
}

export const cheveretoService = {
  uploadImage,
}
