import axios from 'axios'
import { SERVICE_ELEND_TASK_DOMAIN } from 'src/constants/constants'
import { accountHelpers } from '../utils/account-helper'

const createAlarm = (data) => {
  return axios.post(SERVICE_ELEND_TASK_DOMAIN + '/api/alarms/create', data)
}

const updateAlarm = (data) => {
  return axios.put(SERVICE_ELEND_TASK_DOMAIN + '/api/alarms/update', data)
}

const getAlarms = (data) => {
  let url = SERVICE_ELEND_TASK_DOMAIN + `/api/alarms?sort=dueDate,asc`

  if (
    !accountHelpers.hasAccessPermission() &&
    data.status &&
    data.status.toLowerCase() === 'delay'
  ) {
    data.assignTo = accountHelpers.getAccount().id
  }

  if (data.assignTo && data.assignTo.length > 0) {
    url += `&assignTo.contains=${data.assignTo}`
  }
  if (data.dueDate) {
    url += `&dueDate.equals=${data.dueDate}`
  }
  if (data.isOverDue) {
    url += `&isOverDue.equals=${data.isOverDue}`
  }
  if (data.readDate) {
    url += `&readDate.equals=${data.readDate}`
  }
  if (data.notRead) {
    url += `&notRead.equals=${data.notRead}`
  }
  if (data.status) {
    url += `&status.equals=${data.status}`
  }

  if (data && data.all) {
    return axios.get(url)
  } else {
    url += `&page=${data.page}&size=${data.size}`
  }
  return axios.get(url)
}

const getAlarm = (alarmId) => {
  return axios.get(SERVICE_ELEND_TASK_DOMAIN + `/api/alarms/${alarmId}`)
}

const deleteAlarm = (alarmId) => {
  return axios.delete(SERVICE_ELEND_TASK_DOMAIN + `/api/alarms/${alarmId}`)
}

export const alarmService = {
  createAlarm,
  updateAlarm,
  getAlarms,
  getAlarm,
  deleteAlarm,
}
