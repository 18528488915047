import { toast } from 'react-toastify'
import * as t from './actionTypes'
import api from '../../utils/api'
import { UPDATE_BORROWER_RATING_REQUEST } from './actionTypes'

const addSuccessAlert = (message, key, data) => {
  toast.success(message)
}

const addWarninglert = (message, key, data) => {
  toast.warn(message)
}

function createBorrower(data) {
  return (dispatch) => {
    dispatch(request(data))
    return api.borrowerService
      .createBorrower(data)
      .then((response) => {
        addSuccessAlert(`Account created successfully.`)
        dispatch(success(response.data))
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }

  function request() {
    return { type: t.CREATE_BORROWER_REQUEST }
  }

  function success(borrower) {
    return { type: t.CREATE_BORROWER_SUCCESS, borrower }
  }

  function failure(error) {
    return { type: t.CREATE_BORROWER_FAILURE, error }
  }
}

function updateBorrower(data) {
  return (dispatch) => {
    dispatch(request(data))
    return api.borrowerService
      .updateBorrowerById(data)
      .then((response) => {
        addSuccessAlert(`Account updated successfully.`)
        dispatch(success(response.data))
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }

  function request() {
    return { type: t.UPDATE_BORROWER_REQUEST }
  }

  function success(borrower) {
    return { type: t.UPDATE_BORROWER_SUCCESS, borrower }
  }

  function failure(error) {
    return { type: t.UPDATE_BORROWER_FAILURE, error }
  }
}

function moveBorrowerToBlacklist(data) {
  return (dispatch) => {
    dispatch(request(data))
    return api.borrowerService
      .moveBorrowerToBlacklist(data)
      .then((response) => {
        addWarninglert(`Account moved to blacklist successfully.`)
        dispatch(success(response.data))
        window.location.reload()
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }

  function request() {
    return { type: t.UPDATE_BORROWER_REQUEST }
  }

  function success(borrower) {
    return { type: t.UPDATE_BORROWER_SUCCESS, borrower }
  }

  function failure(error) {
    return { type: t.UPDATE_BORROWER_FAILURE, error }
  }
}

function moveBorrowerOutBlacklist(data) {
  return (dispatch) => {
    dispatch(request(data))
    return api.borrowerService
      .moveBorrowerOutBlacklist(data)
      .then((response) => {
        addSuccessAlert(`Account moved out blacklist successfully.`)
        dispatch(success(response.data))
        window.location.reload()
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }

  function request() {
    return { type: t.UPDATE_BORROWER_REQUEST }
  }

  function success(borrower) {
    return { type: t.UPDATE_BORROWER_SUCCESS, borrower }
  }

  function failure(error) {
    return { type: t.UPDATE_BORROWER_FAILURE, error }
  }
}

function getBorrowerById(borrowerId) {
  return (dispatch) => {
    dispatch(request())
    return api.borrowerService
      .getBorrowerById(borrowerId)
      .then((response) => {
        dispatch(success(response.data))
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }

  function request() {
    return { type: t.GET_BORROWER_REQUEST }
  }

  function success(borrower) {
    return { type: t.GET_BORROWER_SUCCESS, borrower }
  }

  function failure(error) {
    return { type: t.GET_BORROWER_FAILURE, error }
  }
}

function getAllBorrowers(data) {
  return (dispatch) => {
    dispatch(request(data))
    return api.borrowerService
      .getAllBorrowers(data)
      .then((response) => {
        dispatch(success(response))
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }

  function request() {
    return { type: t.GET_ALL_BORROWERS_REQUEST }
  }

  function success(response) {
    return { type: t.GET_ALL_BORROWERS_SUCCESS, response }
  }

  function failure(error) {
    return { type: t.GET_ALL_BORROWERS_FAILURE, error }
  }
}

function deleteBorrower(data, redirect = 'borrowers') {
  return (dispatch) => {
    dispatch(request(data))
    return api.borrowerService
      .deleteBorrowerById(data)
      .then((response) => {
        dispatch(success(response))
        if (redirect === 'borrowers') {
          toast.warning('Đã xóa người vay!')
          window.location.href = `${window.location.origin}/#/${redirect}`
        } else {
          toast.warning('Đã xóa!')
        }
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }

  function request() {
    return { type: t.DELETE_BORROWER_REQUEST }
  }

  function success() {
    return { type: t.DELETE_BORROWER_SUCCESS }
  }

  function failure(error) {
    return { type: t.DELETE_BORROWER_FAILURE, error }
  }
}

function resetBorrower() {
  return (dispatch) => {
    dispatch(request())
    dispatch(success())
  }

  function request() {
    return { type: t.RESET_BORROWER_REQUEST }
  }

  function success() {
    return { type: t.RESET_BORROWER_SUCCESS }
  }
}

function updateItemsPerPage(pages) {
  return (dispatch) => {
    dispatch(request(pages))
    dispatch(success(pages))
  }

  function request(pages) {
    return { type: t.UPDATE_ITEMS_PER_PAGE_REQUEST, pages }
  }

  function success(itemsPerPage) {
    return { type: t.UPDATE_ITEMS_PER_PAGE_SUCCESS, itemsPerPage }
  }
}
function updateBorrowerRating(data) {
  return (dispatch) => {
    dispatch(request(data))
    return api.borrowerService
      .updateBorrowerRating(data)
      .then((response) => {
        addSuccessAlert(`Account updated successfully.`)
        dispatch(success(response.data))
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }

  function request() {
    return { type: t.UPDATE_BORROWER_RATING_REQUEST }
  }

  function success(borrower) {
    return { type: t.UPDATE_BORROWER_RATING_SUCCESS, borrower }
  }

  function failure(error) {
    return { type: t.UPDATE_BORROWER_RATING_FAILURE, error }
  }
}

function replyBorrowerRating(data) {
  return (dispatch) => {
    dispatch(request(data))
    return api.borrowerService
      .replyBorrowerRating(data)
      .then((response) => {
        addSuccessAlert(`Account updated successfully.`)
        dispatch(success(response.data))
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }

  function request() {
    return { type: t.REPLY_BORROWER_RATING_REQUEST }
  }

  function success(borrower) {
    return { type: t.REPLY_BORROWER_RATING_SUCCESS, borrower }
  }

  function failure(error) {
    return { type: t.REPLY_BORROWER_RATING_FAILURE, error }
  }
}

function getBorrowerRatings(data) {
  return (dispatch) => {
    dispatch(request(data))
    return api.borrowerService
      .getBorrowerRatings(data)
      .then((response) => {
        dispatch(success(response))
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }

  function request() {
    return { type: t.GET_ALL_BORROWER_RATINGS_REQUEST }
  }

  function success(response) {
    return { type: t.GET_ALL_BORROWER_RATINGS_SUCCESS, response }
  }

  function failure(error) {
    return { type: t.GET_ALL_BORROWER_RATINGS_FAILURE, error }
  }
}

export const borrowerActions = {
  createBorrower,
  updateBorrower,
  resetBorrower,
  deleteBorrower,
  getAllBorrowers,
  getBorrowerById,
  moveBorrowerToBlacklist,
  moveBorrowerOutBlacklist,
  updateItemsPerPage,
  updateBorrowerRating,
  replyBorrowerRating,
  getBorrowerRatings,
}
