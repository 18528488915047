import axios from 'axios'
import { SERVICE_DOMAIN, SERVICE_ELEND_DOMAIN } from 'src/constants/constants'
import { organizationService } from './organization.service'
import { accountHelpers } from '../utils/account-helper'

const syncAccount = () => {
  let url = '/api/cms/users/getUser'
  let subdomain = accountHelpers.getSubdomain()
  if (subdomain && subdomain.length > 0) {
    url = `/api/cms/users/getUser?subdomain=${subdomain}`
  }
  return axios.get(SERVICE_DOMAIN + url)
}

const getAllUsers = (data) => {
  const myOrg = accountHelpers.getMyOrganization()
  if (data.searchUserInOrganization && myOrg && myOrg.organizationId) {
    return organizationService.searchUserInOrganization(myOrg.organizationId, true)
  }

  let url = SERVICE_DOMAIN + `/api/cms/account/get?sort=createdDate,desc`

  if (accountHelpers.hasContractorPermission()) {
    data.createdBy = accountHelpers.getAccount().email
  }

  if (data.name && data.name.length > 0) {
    url += `&name.equals=${data.name}`
  }

  const accessedOrganizationId = accountHelpers.getMyAccessOrganizationId()
  if (accessedOrganizationId) {
    url += `&organizationId.equals=${accessedOrganizationId}`
  }

  if (data.createdBy) {
    url += `&createdBy.equals=${data.createdBy}`
  }

  if (data.authorities && data.authorities.length > 0) {
    url += `&authorities.equals=${data.authorities}`
  }

  if (data && data.all) {
    return axios.get(url)
  } else {
    url += `&page=${data.page}&size=${data.size}`
  }

  return axios.get(url)
}

const createAccount = (data) => {
  return axios.post(SERVICE_DOMAIN + '/api/cms/account/create', data)
}

const updateAccount = (data) => {
  return axios.put(SERVICE_DOMAIN + '/api/cms/account/update', data)
}

const getUserById = (userId) => {
  return axios.get(SERVICE_DOMAIN + `/api/cms/account?userId=${userId}`)
}

const getUserAddressBooks = (data) => {
  if (data && data.all) {
    return axios.get(
      SERVICE_DOMAIN + `/api/cms/account/${data.userId}/address?sort=createdDate,desc`,
    )
  }
  return axios.get(
    SERVICE_DOMAIN +
      `/api/cms/account/${data.userId}/address?page=${data.page}&size=${data.size}&sort=createdDate,desc`,
  )
}

const getUserAddressBookById = (data) => {
  return axios.get(SERVICE_DOMAIN + `/api/cms/account/${data.userId}/address/get/${data.addressId}`)
}

const createUserAddressBook = (data) => {
  return axios.post(SERVICE_DOMAIN + `/api/cms/account/${data.userId}/address/create`, data)
}

const updateUserAddressBook = (data) => {
  return axios.put(SERVICE_DOMAIN + `/api/cms/account/${data.userId}/address/update`, data)
}

const deleteUserAddressBook = (data) => {
  return axios.delete(SERVICE_DOMAIN + `/api/cms/account/${data.userId}/address/delete/${data.id}`)
}

const changePasswordAccount = (data) => {
  return axios.post(SERVICE_DOMAIN + `/api/cms/account/change-password`, data)
}

const uploadImage = (file) => {
  const formData = new FormData()
  formData.append('file', file)
  return axios.post(SERVICE_DOMAIN + `/api/public/eligibility/upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

const signupEligibility = (data) => {
  return axios.post(SERVICE_DOMAIN + `/api/public/eligibility/createAccount`, data)
}

const receivedPresent = (data) => {
  return axios.post(SERVICE_DOMAIN + `/api/public/eligibility/receivedPresent`, data)
}

const getAllPublishEligibility = (data) => {
  if (data && data.all) {
    return axios.get(SERVICE_DOMAIN + `/api/public/eligibility/list?sort=createdDate,desc`)
  }
  return axios.get(
    SERVICE_DOMAIN +
      `/api/public/eligibility/list?page=${data.page}&size=${data.size}&sort=createdDate,desc`,
  )
}

const getPublishEligibilityDetail = (eligibityId) => {
  return axios.get(SERVICE_DOMAIN + `/api/public/eligibility/get/${eligibityId}`)
}

const getAllEligibility = (data) => {
  if (data && data.all) {
    return axios.get(SERVICE_DOMAIN + `/api/cms/eligibility?sort=createdDate,desc`)
  }
  return axios.get(
    SERVICE_DOMAIN +
      `/api/cms/eligibility?page=${data.page}&size=${data.size}&sort=createdDate,desc`,
  )
}

export const userService = {
  createAccount,
  updateAccount,
  getAllUsers,
  getUserById,
  createUserAddressBook,
  updateUserAddressBook,
  deleteUserAddressBook,
  getUserAddressBooks,
  getUserAddressBookById,
  syncAccount,
  uploadImage,
  signupEligibility,
  receivedPresent,
  getAllEligibility,
  getAllPublishEligibility,
  getPublishEligibilityDetail,
  changePasswordAccount,
}
