export const reportConstants = {
  GET_REPORT_BORROWERS_REQUEST: 'GET_REPORT_BORROWERS_REQUEST',
  GET_REPORT_BORROWERS_SUCCESS: 'GET_REPORT_BORROWERS_SUCCESS',
  GET_REPORT_BORROWERS_FAILURE: 'GET_REPORT_BORROWERS_FAILURE',
  GET_REPORT_LOANS_REQUEST: 'GET_REPORT_LOANS_REQUEST',
  GET_REPORT_LOANS_SUCCESS: 'GET_REPORT_LOANS_SUCCESS',
  GET_REPORT_LOANS_FAILURE: 'GET_REPORT_LOANS_FAILURE',
  GET_REPORT_LOANS_ANALYSIS_REQUEST: 'GET_REPORT_LOANS_ANALYSIS_REQUEST',
  GET_REPORT_LOANS_ANALYSIS_SUCCESS: 'GET_REPORT_LOANS_ANALYSIS_SUCCESS',
  GET_REPORT_LOANS_ANALYSIS_FAILURE: 'GET_REPORT_LOANS_ANALYSIS_FAILURE',

  GET_REPORT_ACTIVE_LOANS_ANALYSIS_REQUEST: 'GET_REPORT_ACTIVE_LOANS_ANALYSIS_REQUEST',
  GET_REPORT_ACTIVE_LOANS_ANALYSIS_SUCCESS: 'GET_REPORT_ACTIVE_LOANS_ANALYSIS_SUCCESS',
  GET_REPORT_ACTIVE_LOANS_ANALYSIS_FAILURE: 'GET_REPORT_ACTIVE_LOANS_ANALYSIS_FAILURE',

  GET_REPORT_BANNED_LOANS_ANALYSIS_REQUEST: 'GET_REPORT_BANNED_LOANS_ANALYSIS_REQUEST',
  GET_REPORT_BANNED_LOANS_ANALYSIS_SUCCESS: 'GET_REPORT_BANNED_LOANS_ANALYSIS_SUCCESS',
  GET_REPORT_BANNED_LOANS_ANALYSIS_FAILURE: 'GET_REPORT_BANNED_LOANS_ANALYSIS_FAILURE',

  GET_REPORT_LOANS_ANALYSIS_MONTHLY_REQUEST: 'GET_REPORT_LOANS_ANALYSIS_MONTHLY_REQUEST',
  GET_REPORT_LOANS_ANALYSIS_MONTHLY_SUCCESS: 'GET_REPORT_LOANS_ANALYSIS_MONTHLY_SUCCESS',
  GET_REPORT_LOANS_ANALYSIS_MONTHLY_FAILURE: 'GET_REPORT_LOANS_ANALYSIS_MONTHLY_FAILURE',

  GET_REPORT_LOANS_DISBURSEMENT_REQUEST: 'GET_REPORT_LOANS_DISBURSEMENT_REQUEST',
  GET_REPORT_LOANS_DISBURSEMENT_SUCCESS: 'GET_REPORT_LOANS_DISBURSEMENT_SUCCESS',
  GET_REPORT_LOANS_DISBURSEMENT_FAILURE: 'GET_REPORT_LOANS_DISBURSEMENT_FAILURE',

  GET_REPORT_LOANS_TRANSACTIONS_REQUEST: 'GET_REPORT_LOANS_TRANSACTIONS_REQUEST',
  GET_REPORT_LOANS_TRANSACTIONS_SUCCESS: 'GET_REPORT_LOANS_TRANSACTIONS_SUCCESS',
  GET_REPORT_LOANS_TRANSACTIONS_FAILURE: 'GET_REPORT_LOANS_TRANSACTIONS_FAILURE',

  GET_REPORT_USERS_ALL_REQUEST: 'GET_REPORT_USERS_ALL_REQUEST',
  GET_REPORT_USERS_ALL_SUCCESS: 'GET_REPORT_USERS_ALL_SUCCESS',
  GET_REPORT_USERS_ALL_FAILURE: 'GET_REPORT_USERS_ALL_FAILURE',

  GET_DOWNLOAD_REPORT_LOANS_DISBURSEMENT_REQUEST: 'GET_DOWNLOAD_REPORT_LOANS_DISBURSEMENT_REQUEST',
  GET_DOWNLOAD_REPORT_LOANS_DISBURSEMENT_SUCCESS: 'GET_DOWNLOAD_REPORT_LOANS_DISBURSEMENT_SUCCESS',
  GET_DOWNLOAD_REPORT_LOANS_DISBURSEMENT_FAILURE: 'GET_DOWNLOAD_REPORT_LOANS_DISBURSEMENT_FAILURE',

  GET_DOWNLOAD_REPORT_LOANS_TRANSACTIONS_REQUEST: 'GET_DOWNLOAD_REPORT_LOANS_TRANSACTIONS_REQUEST',
  GET_DOWNLOAD_REPORT_LOANS_TRANSACTIONS_SUCCESS: 'GET_DOWNLOAD_REPORT_LOANS_TRANSACTIONS_SUCCESS',
  GET_DOWNLOAD_REPORT_LOANS_TRANSACTIONS_FAILURE: 'GET_DOWNLOAD_REPORT_LOANS_TRANSACTIONS_FAILURE',

  RESET_REPORT_ALL_LOANS_REQUEST: 'RESET_REPORT_ALL_LOANS_REQUEST',
  RESET_REPORT_ALL_LOANS_SUCCESS: 'RESET_REPORT_ALL_LOANS_SUCCESS',

  GET_REPORT_LOANS_OVERDUE_REQUEST: 'GET_REPORT_LOANS_OVERDUE_REQUEST',
  GET_REPORT_LOANS_OVERDUE_SUCCESS: 'GET_REPORT_LOANS_OVERDUE_SUCCESS',
  GET_REPORT_LOANS_OVERDUE_FAILURE: 'GET_REPORT_LOANS_OVERDUE_FAILURE',

  GET_DOWNLOAD_REPORT_LOANS_OVERDUE_REQUEST: 'GET_DOWNLOAD_REPORT_LOANS_OVERDUE_REQUEST',
  GET_DOWNLOAD_REPORT_LOANS_OVERDUE_SUCCESS: 'GET_DOWNLOAD_REPORT_LOANS_OVERDUE_SUCCESS',
  GET_DOWNLOAD_REPORT_LOANS_OVERDUE_FAILURE: 'GET_DOWNLOAD_REPORT_LOANS_OVERDUE_FAILURE',

  GET_REPORT_LOANS_SUMMARY_REQUEST: 'GET_REPORT_LOANS_SUMMARY_REQUEST',
  GET_REPORT_LOANS_SUMMARY_SUCCESS: 'GET_REPORT_LOANS_SUMMARY_SUCCESS',
  GET_REPORT_LOANS_SUMMARY_FAILURE: 'GET_REPORT_LOANS_SUMMARY_FAILURE',

  GET_REPORT_LOANS_REPAYMENT_SCHEDULES_REQUEST: 'GET_REPORT_LOANS_REPAYMENT_SCHEDULES_REQUEST',
  GET_REPORT_LOANS_REPAYMENT_SCHEDULES_SUCCESS: 'GET_REPORT_LOANS_REPAYMENT_SCHEDULES_SUCCESS',
  GET_REPORT_LOANS_REPAYMENT_SCHEDULES_FAILURE: 'GET_REPORT_LOANS_REPAYMENT_SCHEDULES_FAILURE',

  GET_REPORT_LOANS_TRANSACTIONS_BY_YEAR_REQUEST: 'GET_REPORT_LOANS_TRANSACTIONS_BY_YEAR_REQUEST',
  GET_REPORT_LOANS_TRANSACTIONS_BY_YEAR_SUCCESS: 'GET_REPORT_LOANS_TRANSACTIONS_BY_YEAR_SUCCESS',
  GET_REPORT_LOANS_TRANSACTIONS_BY_YEAR_FAILURE: 'GET_REPORT_LOANS_TRANSACTIONS_BY_YEAR_FAILURE',
}
