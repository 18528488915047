import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import { icons } from './assets/icons'
import store from './store'
import keycloakService from './services/keycloak.service'

import { bindActionCreators } from 'redux'

import setupAxiosInterceptors from './shared/interceptors/axios-interceptor'

import { clearAuthToken } from './actions/index'

// import i18n (needs to be bundled ;))
import './i18n'

const actions = bindActionCreators({ clearAuthToken }, store.dispatch)

setupAxiosInterceptors(() => actions.clearAuthToken())

React.icons = icons

const container = document.getElementById('root')
const root = createRoot(container)

const renderApp = () =>
  root.render(
    <Provider store={store}>
      <App />
    </Provider>,
  )

keycloakService.initKeycloak(renderApp)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
