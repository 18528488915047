import axios from 'axios'
import { APP_TOKEN, BASE_URL } from 'src/constants/constants'
import { toast } from 'react-toastify'

const TIMEOUT = 60 * 1000
axios.defaults.timeout = TIMEOUT
axios.defaults.baseURL = BASE_URL
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'
axios.defaults.headers.post['Access-Control-Allow-Methods'] = 'DELETE, POST, GET, OPTIONS'
axios.defaults.headers.post['Access-Control-Allow-Headers'] =
  'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With'

const addErrorAlert = (message, key, data) => {
  toast.error(message)
}

const setupAxiosInterceptors = (onUnauthenticated) => {
  const onRequestSuccess = (config) => {
    if (config.url.indexOf('api/public') > -1) return config
    const token = localStorage.getItem(APP_TOKEN)
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  }
  const onResponseSuccess = (response) => response

  const onResponseError = (err) => {
    const response = err.response
    const status = err.status || (response ? response.status : 0)
    const data = response != null ? response.data : {}

    const handleCommonErrors = () => {
      if (data !== '' && data.message) {
        addErrorAlert(data.message, data.message, data.params)
      } else {
        addErrorAlert(data)
      }
    }

    switch (status) {
      // connection refused, server not reachable
      case 0:
        addErrorAlert('Server not reachable', 'error.server.not.reachable')
        break
      case 400: {
        const subdomain = window.location.hostname.split('.')[0]
        if (subdomain === 'quatang') {
          return Promise.reject({ errorCode: data.errorKey })
        }
        const headers = Object.entries(response.headers)
        let errorHeader = null
        let entityKey = null
        headers.forEach(([k, v]) => {
          if (k.toLowerCase().endsWith('app-error')) {
            errorHeader = v
          } else if (k.toLowerCase().endsWith('app-params')) {
            entityKey = v
          }
        })
        if (errorHeader) {
          const entityName = entityKey
          addErrorAlert(errorHeader, errorHeader, { entityName })
        } else if (data !== '' && data.message) {
          switch (data.message) {
            case 'CREATE_LOAN_BORROWER_BANNED':
              addErrorAlert('Không thể tạo khoản vay mới cho người vay nợ xấu!')
              break
            case 'CANNOT_CREATE_LOAN_BY_LOWER_RATING':
              addErrorAlert('Không thể tạo khoản vay mới cho người vay có hạng tín dụng thấp!')
              break
          }
        } else if (data !== '' && data.title) {
          switch (data.title) {
            case 'REPAYMENT_ERROR_LOAN_COMPLETED_PAY_OFF_DEBT':
              addErrorAlert('Thanh toán không thể tạo vì khoản vay đã được tất toán!')
              break
            case 'AMOUNT_LESS_THAN_PRINCIPAL':
              addErrorAlert('Số tiền trả nợ không đủ thực hiện tất toán!')
              break
          }
        } else if (data !== '' && data.fieldErrors) {
          const fieldErrors = data.fieldErrors
          for (var i = 0; i < fieldErrors.length; i++) {
            const fieldError = fieldErrors[i]
            if (['Min', 'Max', 'DecimalMin', 'DecimalMax'].includes(fieldError.message)) {
              fieldError.message = 'Size'
            }
            // convert 'something[14].other[4].id' to 'something[].other[].id' so translations can be written to it
            const convertedField = fieldError.field.replace(/\[\d*\]/g, '[]')
            const fieldName = `${fieldError.objectName}.${convertedField}`
            addErrorAlert(`Error on field "${fieldName}"`, `error.${fieldError.message}`, {
              fieldName,
            })
          }
        } else if (data !== '' && data.message) {
          addErrorAlert(data.message, data.message, data.params)
        } else {
          addErrorAlert(data)
        }
        break
      }
      case 404:
        addErrorAlert('Not found', 'error.url.not.found')
        break

      case 403:
        window.location.href = `${window.location.origin}/#/403`
        break
      case 401:
        window.location.href = `${window.location.origin}/#/login`
        onUnauthenticated()
        addErrorAlert('UserName Or Password not correct.', 'error.not.authorize')
        break
      default:
        handleCommonErrors()
    }
    return Promise.reject(err)
  }

  axios.interceptors.request.use(onRequestSuccess)
  axios.interceptors.response.use(onResponseSuccess, onResponseError)
}

export default setupAxiosInterceptors
