import { APP_TOKEN, APP_REFRESH_TOKEN } from 'src/constants/constants'

export const authHelpers = {
  setAccessToken,
  setFreshToken,
  getAccessToken,
  clearToken,
  isAuthenticated,
  findMatchRolePermissions,
}

function findMatchRolePermissions(array_1_small, array2_large) {
  if (!Array.isArray(array2_large)) {
    // Handle the case where array2_large is not an array.
    return []
  }

  let positions = array2_large.reduce((acc, element, index) => {
    if (!acc.hasOwnProperty(element)) {
      acc[element] = []
    }
    acc[element].push(index)
    return acc
  }, {})

  let result = new Set()

  array_1_small.forEach((x) => {
    if (positions[x] === undefined) {
      return
    }
    positions[x].forEach((index) => result.add(index))
  })

  return Array.from(result)
}

function isAuthenticated() {
  const token = localStorage.getItem(APP_TOKEN)
  if (token === null) return false
  return token && token.length > 0
}

function clearToken() {
  localStorage.removeItem(APP_TOKEN)
  localStorage.removeItem(APP_REFRESH_TOKEN)
}

function setAccessToken(token) {
  localStorage.setItem(APP_TOKEN, token)
}

function setFreshToken(refreshToken) {
  localStorage.setItem(APP_REFRESH_TOKEN, refreshToken)
}

function getAccessToken() {
  localStorage.getItem(APP_TOKEN)
}
