import axios from 'axios'
import { SERVICE_ELEND_DOMAIN } from 'src/constants/constants'
import { accountHelpers } from '../utils/account-helper'

const createBorrower = (data) => {
  return axios.post(SERVICE_ELEND_DOMAIN + '/api/borrowers/create', data)
}

const getBorrowerById = (borrowerId) => {
  return axios.get(SERVICE_ELEND_DOMAIN + `/api/borrowers/getInfo/${borrowerId}`)
}

const updateBorrowerById = (data) => {
  return axios.put(SERVICE_ELEND_DOMAIN + `/api/borrowers/${data.id}`, data)
}

const getAllBorrowers = (data) => {
  let url = SERVICE_ELEND_DOMAIN + `/api/borrowers?sort=createdDate,desc`
  url = `${url}&isKycAppraised.equals=${data.isKycAppraised}`

  if (accountHelpers.hasContractorPermission()) {
    data.createdBy = accountHelpers.getAccount().email
  }

  if (data.status) {
    url = `${url}&status.equals=${data.status}`
  }
  if (data.loanStatus) {
    url = `${url}&loanStatus.equals=${data.loanStatus}`
  }
  if (data.createdBy) {
    url += `&createdBy.equals=${data.createdBy}`
  }
  if (data.borrowerName && data.borrowerName.length > 0) {
    url += `&borrowerName.equals=${data.borrowerName}`
  }

  const accessedOrganizationId = accountHelpers.getMyAccessOrganizationId()
  if (accessedOrganizationId) {
    url += `&organizationId.equals=${accessedOrganizationId}`
  }

  if (data && data.all) {
    return axios.get(url)
  } else {
    url += `&page=${data.page}&size=${data.size}`
  }

  return axios.get(url)
}

const deleteBorrowerById = (borrowerId) => {
  return axios.delete(SERVICE_ELEND_DOMAIN + `/api/borrowers/${borrowerId}`)
}

const moveBorrowerToBlacklist = (data) => {
  return axios.post(SERVICE_ELEND_DOMAIN + `/api/borrowers/${data.id}/blacklist`)
}

const moveBorrowerOutBlacklist = (data) => {
  return axios.post(SERVICE_ELEND_DOMAIN + `/api/borrowers/${data.id}/blacklist/undo`)
}

const updateBorrowerRating = (data) => {
  return axios.put(SERVICE_ELEND_DOMAIN + `/api/borrowers/${data.id}/ratings`, data)
}

const getBorrowerRatings = (data) => {
  let url = SERVICE_ELEND_DOMAIN + `/api/borrowers/ratings?sort=createdDate,desc`

  if (data && data.all) {
    return axios.get(url)
  } else {
    url += `&page=${data.page}&size=${data.size}`
  }

  return axios.get(url)
}

const replyBorrowerRating = (data) => {
  return axios.post(SERVICE_ELEND_DOMAIN + `/api/borrowers/ratings/reply`, data)
}

export const borrowerService = {
  createBorrower,
  updateBorrowerById,
  getAllBorrowers,
  getBorrowerById,
  deleteBorrowerById,
  moveBorrowerToBlacklist,
  moveBorrowerOutBlacklist,
  updateBorrowerRating,
  getBorrowerRatings,
  replyBorrowerRating,
}
