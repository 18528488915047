import * as t from './actionTypes'
import i18next from 'i18next'
import { UPDATE_BORROWER_RATING_REQUEST } from './actionTypes'
import { colorHelpers } from '../../utils/color-helper'

const initialState = {
  isFetched: false,
  isFetching: false,
  borrowers: [],
  borrower: null,
  borrowerRating: null,
  itemsPerPage: 10,
  totalPages: 0,
  isRedirect: false,
  isCreated: false,
  isUpdated: false,
  isDeleted: false,
  isKycDeclined: false,
}

const convertDataToBorrowerProfile = (data) => {
  return {
    id: data.borrowerDto.id,
    organizationId: data.borrowerDto.organizationId,
    fullName: `${data.userDto.lastName} ${data.userDto.firstName}`,
    gender: data.userProfileDto.gender,
    idNumber: data.borrowerDto.identifyNumber,
    phoneNumber: data.userProfileDto.phone,
    status: data.borrowerDto.status,
    rating:
      data.borrowerDto && data.borrowerDto.borrowerRating
        ? data.borrowerDto.borrowerRating.rating
        : null,
    note:
      data.borrowerDto && data.borrowerDto.borrowerRating
        ? data.borrowerDto.borrowerRating.note
        : null,
    ratingStatus:
      data.borrowerDto && data.borrowerDto.borrowerRating
        ? data.borrowerDto.borrowerRating.ratingStatus
        : null,
    kycStatus: data.borrowerDto.kycStatus,
    kycNote: data.borrowerDto.kycNote,
    isKycAppraised: data.borrowerDto.isKycAppraised,
  }
}

const convertBorrower = (borrower) => {
  const convertedObject = Object.assign(
    {},
    borrower.borrowerDto,
    borrower.userDto,
    borrower.userProfileDto,
  )
  if (convertedObject.guarantors && convertedObject.guarantors.length > 0) {
    convertedObject.guarantors.forEach((g) => {
      g.title = 'GuarantorDetail'
      g.translateHeader = 'view.Borrowers.GuarantorDetail'
      g.translateName = 'view.Borrowers.FullName'
    })
    convertedObject.guarantors = convertedObject.guarantors.sort((a, b) =>
      a.fullName.toLowerCase().localeCompare(b.fullName.toLowerCase()),
    )
  }
  convertedObject.userAddressList = borrower.userAddressList
  convertedObject.userMetadataList = borrower.userMetadataList
  if (convertedObject.kycStatus?.length > 0) {
    const kycStatus = convertedObject.kycStatus.toLowerCase()
    convertedObject.kycStatus = {
      value: kycStatus,
      label: i18next.t(`common.${kycStatus}`),
      color: colorHelpers.getColorByStatus(kycStatus, true),
    }
  }
  return convertedObject
}

const borrowerReducer = (state = initialState, action) => {
  switch (action.type) {
    case t.CREATE_BORROWER_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
        isRedirect: false,
        isCreated: false,
        isUpdated: false,
        isDeleted: false,
        isKycDeclined: false,
        borrowers: [],
        borrowerRating: null,
      })
    case t.CREATE_BORROWER_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: true,
        isRedirect: true,
        isCreated: true,
        borrower: convertBorrower(action.borrower),
      })
    case t.CREATE_BORROWER_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorFetch: action.error,
      })
    case t.UPDATE_BORROWER_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
        isRedirect: false,
        isUpdated: false,
        isCreated: false,
        isDeleted: false,
        isKycDeclined: false,
        borrowers: [],
      })
    case t.UPDATE_BORROWER_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: true,
        isRedirect: true,
        isUpdated: true,
        borrower: convertBorrower(action.borrower),
      })
    case t.UPDATE_BORROWER_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorFetch: action.error,
      })
    case t.DELETE_BORROWER_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
        isRedirect: false,
        isUpdated: false,
        isCreated: false,
        isDeleted: false,
        isKycDeclined: false,
        borrowers: [],
      })
    case t.DELETE_BORROWER_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: true,
        isRedirect: false,
        isUpdated: false,
        isDeleted: true,
      })
    case t.DELETE_BORROWER_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorFetch: action.error,
      })
    case t.GET_ALL_BORROWERS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
        isRedirect: false,
        isUpdated: false,
        isCreated: false,
        isDeleted: false,
        isKycDeclined: false,
        borrowers: [],
        borrower: null,
        borrowerRating: null,
      })
    case t.GET_ALL_BORROWERS_SUCCESS:
      const borrowers = []
      action.response.data.forEach((item) => {
        const borrower = convertDataToBorrowerProfile(item)
        borrowers.push(borrower)
      })
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: true,
        borrowers: borrowers,
        totalPages: Math.ceil(action.response.headers['x-total-count'] / state.itemsPerPage),
      })
    case t.GET_ALL_BORROWERS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorFetch: action.error,
      })
    case t.GET_BORROWER_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
        isRedirect: false,
        isDeleted: false,
        isKycDeclined: false,
        borrowers: [],
        borrower: null,
        borrowerRating: null,
      })
    case t.GET_BORROWER_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: true,
        borrower: convertBorrower(action.borrower),
        isKycDeclined:
          action.borrower &&
          action.borrower.borrowerDto &&
          action.borrower.borrowerDto.kycStatus === 'DECLINED',
        borrowerRating:
          action.borrower && action.borrower.borrowerDto
            ? action.borrower.borrowerDto.borrowerRating
            : null,
      })
    case t.GET_BORROWER_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorFetch: action.error,
      })
    case t.RESET_BORROWER_REQUEST:
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: false,
        isRedirect: false,
        isCreated: false,
        isDeleted: false,
        isKycDeclined: false,
      })
    case t.RESET_BORROWER_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: false,
        isRedirect: false,
        isCreated: false,
        borrower: null,
        borrowerRating: null,
        borrowers: [],
      })
    case t.UPDATE_ITEMS_PER_PAGE_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
        isRedirect: false,
      })
    case t.UPDATE_ITEMS_PER_PAGE_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: true,
        isRedirect: true,
        itemsPerPage: action.itemsPerPage,
      })
    case t.UPDATE_BORROWER_RATING_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
        isRedirect: false,
        isUpdated: false,
        isCreated: false,
        isDeleted: false,
        isKycDeclined: false,
      })
    case t.UPDATE_BORROWER_RATING_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: true,
        isRedirect: true,
        isUpdated: true,
        borrower: convertBorrower(action.borrower),
        borrowerRating:
          action.borrower && action.borrower.borrowerDto
            ? action.borrower.borrowerDto.borrowerRating
            : null,
      })
    case t.UPDATE_BORROWER_RATING_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorFetch: action.error,
      })
    case t.REPLY_BORROWER_RATING_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
        isRedirect: false,
        isUpdated: false,
        isCreated: false,
        isDeleted: false,
        isKycDeclined: false,
      })
    case t.REPLY_BORROWER_RATING_SUCCESS:
      const reponseItem = action.borrower
      let remainBorrowers = state.borrowers.filter((item) => item.id !== reponseItem.borrowerDto.id)
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: true,
        isRedirect: true,
        isUpdated: true,
        borrowers: remainBorrowers,
        borrower: null,
        borrowerRating: null,
      })
    case t.REPLY_BORROWER_RATING_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorFetch: action.error,
      })
    case t.GET_ALL_BORROWER_RATINGS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
        isRedirect: false,
        isUpdated: false,
        isCreated: false,
        isDeleted: false,
        borrowers: [],
        borrower: null,
        borrowerRating: null,
        isKycDeclined: false,
      })
    case t.GET_ALL_BORROWER_RATINGS_SUCCESS:
      const borrowerRatings = []
      action.response.data.forEach((item) => {
        const borrower = convertDataToBorrowerProfile(item)
        borrowerRatings.push(borrower)
      })
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: true,
        borrowers: borrowerRatings,
        totalPages: Math.ceil(action.response.headers['x-total-count'] / state.itemsPerPage),
      })
    case t.GET_ALL_BORROWER_RATINGS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorFetch: action.error,
      })
    default:
      return state
  }
}

export default borrowerReducer
