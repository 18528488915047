import { reportConstants } from '../constants'
import { accountHelpers } from '../utils/account-helper'
import moment from 'moment'

const initialState = {
  isFetched: false,
  isFetching: false,
  reportBorrowers: [],
  reportLoans: [],
  reportLoanAnalysis: [],
  reportActiveLoanAnalysis: [],
  reportBannedLoanAnalysis: [],
  reportLoanAnalysisMonthly: [],
  reportLoanPayDebtOffMonthly: [],
  reportDisbursementLoans: [],
  reportLoanTransactions: [],
  reportBorrowersHaveOverdueLoans: [],
  reportLoanSummary: [],
  reportLoanRepayments: [],
  reportLoanTransactionsByYear: [],
  users: [],
  csvDisbursementLoans: null,
  csvLoanTransactions: null,
  csvOverdueLoans: null,
}

const FORMAT_DATE = 'DD-MM-yyyy'

const convertReportLoanDisbursement = (data) => {
  const totalRow = {
    id: '',
    disbursedOnDate: 'Total',
    principalDisbursedDerived: 0,
    interestDisbursedDerived: 0,
    feesDisbursedDerived: 0,
    penaltiesDisbursedDerived: 0,
    totalDisbursedDerived: 0,
    principalRepaidDerived: 0,
    interestRepaidDerived: 0,
    feesRepaidDerived: 0,
    penaltiesRepaidDerived: 0,
    totalRepaidDerived: 0,
    _props: { color: 'light', className: 'fw-semibold' },
  }
  data.forEach((item) => {
    item.createdDate = moment(item.createdDate).format(FORMAT_DATE)
    item.disbursedOnDate = moment(item.disbursedOnDate).format(FORMAT_DATE)

    totalRow.principalDisbursedDerived += item.principalDisbursedDerived
    totalRow.interestDisbursedDerived += item.interestDisbursedDerived
    totalRow.feesDisbursedDerived += item.feesDisbursedDerived
    totalRow.penaltiesDisbursedDerived += item.penaltiesDisbursedDerived
    totalRow.totalDisbursedDerived += item.totalDisbursedDerived
    totalRow.principalRepaidDerived += item.principalRepaidDerived
    totalRow.interestRepaidDerived += item.interestRepaidDerived
    totalRow.feesRepaidDerived += item.feesRepaidDerived
    totalRow.penaltiesRepaidDerived += item.penaltiesRepaidDerived
    totalRow.totalRepaidDerived += item.totalRepaidDerived
  })
  data.push(totalRow)
  return data
}

const convertBorrowersHaveLoanOverDue = (data) => {
  const mapBorrowers = {}
  data.forEach((d) => {
    if (!mapBorrowers[d.borrowerId]) {
      mapBorrowers[d.borrowerId] = {
        borrowerId: d.borrowerId,
        fullName: `${d.lastName} ${d.firstName}`,
        identifyNumber: d.identifyNumber,
        loanRepaymentSchedules: [],
        phone: d.phone,
        totalLoans: d.totalLoans,
        totalOverdueInstallments: d.totalOverdueInstallments,
        totalPrincipal: d.totalPrincipal,
        totalPrincipalRepaidDerived: d.totalPrincipalRepaidDerived,
        totalInterest: d.totalInterest,
        totalInterestRepaidDerived: d.totalInterestRepaidDerived,
        _props: d.totalOverdueInstallments >= 3 ? { color: 'danger' } : null,
      }
    }
    mapBorrowers[d.borrowerId].loanRepaymentSchedules.push(d.loanRepaymentSchedule)
  })
  const res = Object.entries(mapBorrowers).map(([name, obj]) => ({ name, ...obj }))
  return res.sort((a, b) => (b.totalOverdueInstallments > a.totalOverdueInstallments ? 1 : -1))
}

const reportReducer = (state = initialState, action) => {
  switch (action.type) {
    case reportConstants.GET_REPORT_BORROWERS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
      })
    case reportConstants.GET_REPORT_BORROWERS_SUCCESS:
      const borrowers = []
      const data = action.response.data
      for (let i in data) {
        if (
          i === 'organizationId' ||
          i === 'totalBorrowersInPendingLoan' ||
          i === 'totalBorrowersInRejectedLoan'
        )
          continue
        const item = {}
        item.value = data[i]
        item.type = i
        item.bgColor = 'bg-primary-gradient'
        item.title = 'view.Report.TotalBorrowers'
        item.status = ''
        item.order = 0
        if (i === 'totalBorrowersInActiveLoan') {
          item.bgColor = 'bg-success-gradient'
          item.title = 'view.Report.TotalBorrowersInActiveLoan'
          item.order = 2
          item.status = 'active'
        } else if (i === 'totalBorrowersInCompletedLoan') {
          item.bgColor = 'bg-dark-gradient'
          item.title = 'view.Report.TotalBorrowersInCompletedLoan'
          item.order = 3
          item.status = 'completed'
        } else if (i === 'totalBlackList') {
          item.bgColor = 'bg-danger-gradient'
          item.title = 'view.Report.TotalBlackList'
          item.order = 1
          item.status = 'blacklist'
        }

        if (accountHelpers.hasAccessPermission()) {
          borrowers.push(item)
        } else if (item.status === 'active' || item.status === 'blacklist') {
          borrowers.push(item)
        }
      }

      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
        reportBorrowers: borrowers.sort((a, b) =>
          a.order < b.order ? -1 : a.order > b.order ? 1 : 0,
        ),
      })
    case reportConstants.GET_REPORT_BORROWERS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorFetch: action.error,
      })
    case reportConstants.GET_REPORT_LOANS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
      })
    case reportConstants.GET_REPORT_LOANS_SUCCESS:
      const loans = []
      const loanData = action.response.data
      for (let i in loanData) {
        if (
          loanData[i] === 0 ||
          i === 'organizationId' ||
          i === 'totalDurationLate' ||
          i === 'totalDurationToday'
        )
          continue
        const item = {}
        item.value = loanData[i]
        item.type = i
        item.bgColor = 'bg-info-gradient'
        item.title = 'view.Report.TotalLoans'
        item.order = 0
        item.status = ''
        if (i === 'totalActive') {
          item.bgColor = 'bg-success-gradient'
          item.title = 'view.Report.TotalActive'
          item.order = 1
          item.status = 'active'
        } else if (i === 'totalCompleted') {
          item.bgColor = 'bg-dark-gradient'
          item.title = 'view.Report.TotalCompleted'
          item.order = 2
          item.status = 'completed'
        } else if (i === 'totalPending') {
          item.bgColor = 'bg-warning-gradient'
          item.title = 'view.Report.TotalPending'
          item.order = 3
          item.status = 'pending'
        } else if (i === 'totalApproval') {
          item.bgColor = 'bg-warning-gradient'
          item.title = 'view.Report.TotalLoanApproved'
          item.order = 4
          item.status = 'approved'
        } else if (i === 'totalBlacklist') {
          item.bgColor = 'bg-danger-gradient'
          item.title = 'view.Report.TotalLoanBlackList'
          item.order = 5
          item.status = 'blacklist'
        } else if (i === 'totalRejected') {
          item.bgColor = 'bg-danger-gradient'
          item.title = 'view.Report.TotalLoanRejected'
          item.order = 6
          item.status = 'rejected'
        } else if (i === 'totalDurationOverDue') {
          item.bgColor = 'bg-danger-gradient'
          item.title = 'view.Report.TotalDurationOverDue'
          item.order = 7
          item.status = 'overdue'
        }

        if (accountHelpers.hasAccessPermission()) {
          loans.push(item)
        } else if (item.status === 'active' || item.status === 'overdue') {
          loans.push(item)
        }
      }

      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
        reportLoans: loans.sort((a, b) => (a.order < b.order ? -1 : a.order > b.order ? 1 : 0)),
      })
    case reportConstants.GET_REPORT_LOANS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorFetch: action.error,
      })
    case reportConstants.GET_REPORT_LOANS_ANALYSIS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
      })
    case reportConstants.GET_REPORT_LOANS_ANALYSIS_SUCCESS:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
        reportLoanAnalysis: action.response.data.reportLoanAnalysisList,
      })
    case reportConstants.GET_REPORT_LOANS_ANALYSIS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorFetch: action.error,
      })
    case reportConstants.GET_REPORT_ACTIVE_LOANS_ANALYSIS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
      })
    case reportConstants.GET_REPORT_ACTIVE_LOANS_ANALYSIS_SUCCESS:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
        reportActiveLoanAnalysis: action.response.data.reportLoanAnalysisList,
      })
    case reportConstants.GET_REPORT_ACTIVE_LOANS_ANALYSIS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorFetch: action.error,
      })
    case reportConstants.GET_REPORT_BANNED_LOANS_ANALYSIS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
      })
    case reportConstants.GET_REPORT_BANNED_LOANS_ANALYSIS_SUCCESS:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
        reportBannedLoanAnalysis: action.response.data.reportLoanAnalysisList,
      })
    case reportConstants.GET_REPORT_BANNED_LOANS_ANALYSIS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorFetch: action.error,
      })
    case reportConstants.GET_REPORT_LOANS_ANALYSIS_MONTHLY_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
      })
    case reportConstants.GET_REPORT_LOANS_ANALYSIS_MONTHLY_SUCCESS:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
        reportLoanAnalysisMonthly: action.response.data.loanProfitByMonths,
        reportLoanPayDebtOffMonthly: action.response.data.loanPayDebtOffProfitByMonths,
      })
    case reportConstants.GET_REPORT_LOANS_ANALYSIS_MONTHLY_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorFetch: action.error,
      })
    case reportConstants.GET_REPORT_LOANS_DISBURSEMENT_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
      })
    case reportConstants.GET_REPORT_LOANS_DISBURSEMENT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
        reportDisbursementLoans: convertReportLoanDisbursement(action.response.data),
      })
    case reportConstants.GET_REPORT_LOANS_DISBURSEMENT_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorFetch: action.error,
      })
    case reportConstants.GET_DOWNLOAD_REPORT_LOANS_DISBURSEMENT_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
      })
    case reportConstants.GET_DOWNLOAD_REPORT_LOANS_DISBURSEMENT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
        csvDisbursementLoans: action.response.data,
      })
    case reportConstants.GET_DOWNLOAD_REPORT_LOANS_DISBURSEMENT_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorFetch: action.error,
      })
    case reportConstants.GET_REPORT_LOANS_TRANSACTIONS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
      })
    case reportConstants.GET_REPORT_LOANS_TRANSACTIONS_SUCCESS:
      const length = action.response.data.length
      if (length > 0) {
        const item = action.response.data[length - 1]
        item['_props'] = { color: 'light', className: 'fw-semibold' }
        action.response.data[length - 1] = item
      }
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
        reportLoanTransactions: action.response.data,
      })
    case reportConstants.GET_REPORT_LOANS_TRANSACTIONS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorFetch: action.error,
      })
    case reportConstants.GET_DOWNLOAD_REPORT_LOANS_TRANSACTIONS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
      })
    case reportConstants.GET_DOWNLOAD_REPORT_LOANS_TRANSACTIONS_SUCCESS:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
        csvLoanTransactions: action.response.data,
      })
    case reportConstants.GET_DOWNLOAD_REPORT_LOANS_TRANSACTIONS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorFetch: action.error,
      })
    case reportConstants.GET_REPORT_USERS_ALL_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      })
    case reportConstants.GET_REPORT_USERS_ALL_SUCCESS:
      const items = action.response.data || []
      const users = [
        {
          value: 'select',
          label: 'Chọn nhân viên',
        },
      ]
      if (items && items.length > 0) {
        items.forEach((i) => {
          i.value = i.id
          i.label = `${i.lastName} ${i.firstName}`
        })
      }
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: true,
        totalPages: Math.ceil(action.response.headers['x-total-count'] / state.itemsPerPage),
        itemsPerPage: action.response.size,
        users: users.concat(items),
      })
    case reportConstants.GET_REPORT_USERS_ALL_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorFetch: action.error,
      })
    case reportConstants.RESET_REPORT_ALL_LOANS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
      })
    case reportConstants.RESET_REPORT_ALL_LOANS_SUCCESS:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
        reportDisbursementLoans: [],
        reportLoanTransactions: [],
        csvLoanTransactions: null,
        csvDisbursementLoans: null,
        csvOverdueLoans: null,
        reportBorrowers: [],
        reportLoans: [],
        reportLoanAnalysis: [],
        reportActiveLoanAnalysis: [],
        reportBannedLoanAnalysis: [],
        reportLoanAnalysisMonthly: [],
        reportLoanPayDebtOffMonthly: [],
        users: [],
        reportBorrowersHaveOverdueLoans: [],
      })

    case reportConstants.GET_REPORT_LOANS_OVERDUE_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
      })
    case reportConstants.GET_REPORT_LOANS_OVERDUE_SUCCESS:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
        reportBorrowersHaveOverdueLoans: convertBorrowersHaveLoanOverDue(action.response.data),
      })
    case reportConstants.GET_REPORT_LOANS_OVERDUE_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorFetch: action.error,
      })

    case reportConstants.GET_DOWNLOAD_REPORT_LOANS_OVERDUE_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
      })
    case reportConstants.GET_DOWNLOAD_REPORT_LOANS_OVERDUE_SUCCESS:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
        csvOverdueLoans: action.response.data,
      })
    case reportConstants.GET_DOWNLOAD_REPORT_LOANS_OVERDUE_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorFetch: action.error,
      })

    case reportConstants.GET_REPORT_LOANS_SUMMARY_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
      })
    case reportConstants.GET_REPORT_LOANS_SUMMARY_SUCCESS:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
        reportLoanSummary: action.response.data,
      })
    case reportConstants.GET_REPORT_LOANS_SUMMARY_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorFetch: action.error,
      })

    case reportConstants.GET_REPORT_LOANS_REPAYMENT_SCHEDULES_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
      })
    case reportConstants.GET_REPORT_LOANS_REPAYMENT_SCHEDULES_SUCCESS:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
        reportLoanRepayments: action.response.data,
      })
    case reportConstants.GET_REPORT_LOANS_REPAYMENT_SCHEDULES_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorFetch: action.error,
      })

    case reportConstants.GET_REPORT_LOANS_TRANSACTIONS_BY_YEAR_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
      })
    case reportConstants.GET_REPORT_LOANS_TRANSACTIONS_BY_YEAR_SUCCESS:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
        reportLoanTransactionsByYear: action.response.data,
      })
    case reportConstants.GET_REPORT_LOANS_TRANSACTIONS_BY_YEAR_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorFetch: action.error,
      })

    default:
      return state
  }
}

export default reportReducer
