import axios from 'axios'
import { SERVICE_ELEND_DOMAIN } from 'src/constants/constants'
import { accountHelpers } from '../utils/account-helper'

const createLoan = (data) => {
  return axios.post(SERVICE_ELEND_DOMAIN + '/api/loans/create', data)
}

const updateLoan = (data) => {
  return axios.put(SERVICE_ELEND_DOMAIN + `/api/loans/${data.id}/update`, data)
}

const updateLoanPenalty = (data) => {
  return axios.put(SERVICE_ELEND_DOMAIN + `/api/loans/${data.id}/penalty`, data)
}

const updateLoanWarning = (data) => {
  return axios.put(SERVICE_ELEND_DOMAIN + `/api/loans/${data.id}/warning`, data)
}

const updateLoanWatching = (data) => {
  return axios.put(SERVICE_ELEND_DOMAIN + `/api/loans/${data.id}/watching`, data)
}

const getAllLoans = (data) => {
  let url = data.isExportCSV ? `/api/loans/export-csv` : `/api/loans`

  if (accountHelpers.hasContractorPermission()) {
    data.assignedTo = accountHelpers.getAccount().id
  }

  if (data.status && data.status.length > 0) {
    if (data.status.toLowerCase() === 'overdue') {
      url += '?sort=repaymentDueDate,asc&isWarning.equals=false'
    } else if (data.isReminder) {
      url += '?sort=repaymentDueDate,asc'
    } else {
      url += '?sort=createdDate,desc'
    }

    url += `&status.equals=${data.status}`
    if (data.status && data.status.toLowerCase() === 'pending') {
      url += `&statusTransaction.equals=${data.status}`
    }
  } else if (data.isReminder) {
    url += '?sort=repaymentDueDate,asc'
    url += `&status.notEquals=COMPLETED`
  } else {
    url += '?sort=createdDate,desc'
    url += `&status.notEquals=COMPLETED`
  }
  if (data.createdBy) {
    url += `&createdBy.equals=${data.createdBy}`
  }
  if (data.assignedTo) {
    url += `&assignedTo.equals=${data.assignedTo}`
  }
  if (data.borrowerId) {
    url += `&borrowerId.equals=${data.borrowerId}`
  }
  if (data.borrowerName && data.borrowerName.length > 0) {
    url += `&borrowerName.equals=${data.borrowerName}`
  }
  if (data.startDate && data.endDate) {
    url += `&startDate.equals=${data.startDate}&endDate.equals=${data.endDate}`
  }

  const accessedOrganizationId = accountHelpers.getMyAccessOrganizationId()
  if (accessedOrganizationId) {
    url += `&organizationId.equals=${accessedOrganizationId}`
  }

  if (data.isWarning) {
    url += `&isWarning.equals=${data.isWarning}`
  }

  if (data.lang) {
    url += `&lang.equals=${data.lang}`
  }

  if (data && data.all) {
    return axios.get(SERVICE_ELEND_DOMAIN + url)
  } else {
    url += `&page=${data.page}&size=${data.size}`
  }

  return axios.get(SERVICE_ELEND_DOMAIN + url)
}

const getLoanById = (loanId) => {
  return axios.get(SERVICE_ELEND_DOMAIN + `/api/loans/${loanId}`)
}

const deleteLoanById = (loanId) => {
  return axios.delete(SERVICE_ELEND_DOMAIN + `/api/loans/${loanId}`)
}

const approveLoanById = (data) => {
  return axios.post(SERVICE_ELEND_DOMAIN + `/api/loans/${data.loanId}/approve`, data)
}

const undoApproval = (data) => {
  return axios.post(SERVICE_ELEND_DOMAIN + `/api/loans/${data.loanId}/undo-approval`, data)
}

const rejectLoanById = (data) => {
  return axios.post(SERVICE_ELEND_DOMAIN + `/api/loans/${data.loanId}/reject`, data)
}

const blacklistLoanById = (data) => {
  return axios.post(SERVICE_ELEND_DOMAIN + `/api/loans/${data.loanId}/blacklist`, data)
}

const undoRejection = (data) => {
  return axios.post(SERVICE_ELEND_DOMAIN + `/api/loans/${data.loanId}/undo-rejection`, data)
}

const disburseLoan = (data) => {
  return axios.post(SERVICE_ELEND_DOMAIN + `/api/loans/${data.loanId}/disburse-loan`, data)
}

const undoDisbursement = (data) => {
  return axios.post(SERVICE_ELEND_DOMAIN + `/api/loans/${data.loanId}/undo-disbursement`, data)
}

const createRepayment = (data) => {
  return axios.post(SERVICE_ELEND_DOMAIN + `/api/loans/${data.loanId}/repayment/create`, data)
}

const getRepaymentDetail = (data) => {
  return axios.get(SERVICE_ELEND_DOMAIN + `/api/loans/${data.loanId}/repayment/${data.repaymentId}`)
}

const reverseRepayment = (data) => {
  return axios.post(
    SERVICE_ELEND_DOMAIN + `/api/loans/${data.loanId}/repayment/${data.repaymentId}/reverse`,
  )
}

const updateRepayment = (data) => {
  return axios.post(SERVICE_ELEND_DOMAIN + `/api/loans/${data.loanId}/repayment/update`, data)
}

const processLoanCalculator = (data) => {
  return axios.post(SERVICE_ELEND_DOMAIN + `/api/loans/calculator/process-loan-calculator`, data)
}

const getPaymentTypes = () => {
  return axios.get(SERVICE_ELEND_DOMAIN + `/api/loans/payment-types`)
}

const getMethodOfRepayments = () => {
  return axios.get(SERVICE_ELEND_DOMAIN + `/api/loans/method-repayments`)
}

const confirmRepayment = (data) => {
  return axios.post(
    SERVICE_ELEND_DOMAIN + `/api/loans/${data.loanId}/repayment/${data.id}/complete`,
    data,
  )
}

const payoffDebtRepayment = (data) => {
  return axios.post(SERVICE_ELEND_DOMAIN + `/api/loans/${data.loanId}/repayment/payoff`, data)
}

const closeLoan = (data) => {
  return axios.post(SERVICE_ELEND_DOMAIN + `/api/loans/${data.loanId}/close`, data)
}

const getLoanNotes = (data) => {
  return axios.get(SERVICE_ELEND_DOMAIN + `/api/loans/${data.loanId}/notes`)
}

const getLoanNoteDetail = (data) => {
  return axios.get(SERVICE_ELEND_DOMAIN + `/api/loans/${data.loanId}/notes/${data.loanNoteId}`)
}

const createLoanNote = (data) => {
  return axios.post(SERVICE_ELEND_DOMAIN + `/api/loans/${data.loanId}/notes/create`, data)
}

const updateLoanNote = (data) => {
  return axios.put(SERVICE_ELEND_DOMAIN + `/api/loans/${data.loanId}/notes/update`, data)
}

const deleteLoanNote = (data) => {
  return axios.delete(
    SERVICE_ELEND_DOMAIN + `/api/loans/${data.loanId}/notes/${data.loanNoteId}/delete`,
  )
}

const generateLoanCalculator = (data) => {
  return axios.post(SERVICE_ELEND_DOMAIN + '/api/loans/calculator/process-loan-calculator', data)
}

export const loanService = {
  createLoan,
  updateLoan,
  deleteLoanById,
  getAllLoans,
  getLoanById,
  approveLoanById,
  undoApproval,
  rejectLoanById,
  blacklistLoanById,
  undoRejection,
  disburseLoan,
  undoDisbursement,
  createRepayment,
  getRepaymentDetail,
  reverseRepayment,
  updateRepayment,
  processLoanCalculator,
  getPaymentTypes,
  getMethodOfRepayments,
  confirmRepayment,
  payoffDebtRepayment,
  closeLoan,
  getLoanNotes,
  getLoanNoteDetail,
  createLoanNote,
  updateLoanNote,
  deleteLoanNote,
  updateLoanPenalty,
  updateLoanWarning,
  updateLoanWatching,
  generateLoanCalculator,
}
