import moment from 'moment'

const getBadge = (status, isHex = false) => {
  if (!status) return
  return getColorByStatus(status.toLowerCase(), isHex)
}

const getColorByStatus = (status, isHex) => {
  switch (status.toLowerCase()) {
    case 'active':
    case 'approve':
    case 'approved':
      return isHex ? '#2eb85c' : 'success'
    case 'inactive':
      return isHex ? '#636f83' : 'secondary'
    case 'pending':
      return isHex ? '#f9b115' : 'warning'
    case 'banned':
    case 'decline':
    case 'declined':
    case 'rejected':
      return isHex ? '#e55353' : 'danger'
    default:
      return isHex ? '#ced2d8' : 'primary'
  }
}

const getColorByTaskStatus = (status, isHex) => {
  switch (status.toLowerCase()) {
    case 'done':
      return isHex ? '#2eb85c' : 'success'
    case 'todo':
      return isHex ? '#636f83' : 'secondary'
    case 'in_progress':
    case 'review':
      return isHex ? '#1e9ff2' : 'primary'
    case 'high':
      return isHex ? '#f9b115' : 'warning'
    case 'critical':
      return isHex ? '#e55353' : 'danger'
    case 'low':
      return isHex ? '#636f83' : 'secondary'
    default:
      return isHex ? '#ced2d8' : 'primary'
  }
}

const getDayInArrearsColor = (item) => {
  const today = moment()
  const dueDate = moment(item.repaymentDueDate)
  const duration = dueDate.diff(today, 'days')
  if (duration < -7) return '#e55353'
  else if (duration <= -7) return '#ff9f43'
  return '#1e9ff2'
}

export const colorHelpers = {
  getBadge,
  getColorByStatus,
  getDayInArrearsColor,
  getColorByTaskStatus,
}
