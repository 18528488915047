export const USERS_GET_ALL_REQUEST = 'USERS_GET_ALL_REQUEST'
export const USERS_GET_ALL_SUCCESS = 'USERS_GET_ALL_SUCCESS'
export const USERS_GET_ALL_FAILURE = 'USERS_GET_ALL_FAILURE'

export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST'
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS'
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE'

export const RESET_IS_CREATED_ACCOUNT_REQUEST = 'RESET_IS_CREATED_ACCOUNT_REQUEST'
export const RESET_IS_CREATED_ACCOUNT_SUCCESS = 'RESET_IS_CREATED_ACCOUNT_SUCCESS'

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE'

export const USER_DETAIL_GET_REQUEST = 'USER_DETAIL_GET_REQUEST'
export const USER_DETAIL_GET_SUCCESS = 'USER_DETAIL_GET_SUCCESS'
export const USER_DETAIL_GET_FAILURE = 'USER_DETAIL_GET_FAILURE'

export const UPDATE_USER_ADDRESS_REQUEST = 'UPDATE_USER_ADDRESS_REQUEST'
export const UPDATE_USER_ADDRESS_SUCCESS = 'UPDATE_USER_ADDRESS_SUCCESS'
export const UPDATE_USER_ADDRESS_FAILURE = 'UPDATE_USER_ADDRESS_FAILURE'

export const CUSTOMERS_GET_ALL_REQUEST = 'CUSTOMERS_GET_ALL_REQUEST'
export const CUSTOMERS_GET_ALL_SUCCESS = 'CUSTOMERS_GET_ALL_SUCCESS'
export const CUSTOMERS_GET_ALL_FAILURE = 'CUSTOMERS_GET_ALL_FAILURE'

export const CUSTOMER_DETAIL_GET_REQUEST = 'CUSTOMER_DETAIL_GET_REQUEST'
export const CUSTOMER_DETAIL_GET_SUCCESS = 'CUSTOMER_DETAIL_GET_SUCCESS'
export const CUSTOMER_DETAIL_GET_FAILURE = 'CUSTOMER_DETAIL_GET_FAILURE'

export const USER_ROLES_GET_ALL_REQUEST = 'USER_ROLES_GET_ALL_REQUEST'
export const USER_ROLES_GET_ALL_SUCCESS = 'USER_ROLES_GET_ALL_SUCCESS'
export const USER_ROLES_GET_ALL_FAILURE = 'USER_ROLES_GET_ALL_FAILURE'

export const USER_ROLES_GET_DETAILS_ALL_REQUEST = 'USER_ROLES_GET_DETAILS_ALL_REQUEST'
export const USER_ROLES_GET_DETAILS_ALL_SUCCESS = 'USER_ROLES_GET_DETAILS_ALL_SUCCESS'
export const USER_ROLES_GET_DETAILS_ALL_FAILURE = 'USER_ROLES_GET_DETAILS_ALL_FAILURE'

export const USER_ROLE_DETAIL_GET_REQUEST = 'USER_ROLE_DETAIL_GET_REQUEST'
export const USER_ROLE_DETAIL_GET_SUCCESS = 'USER_ROLE_DETAIL_GET_SUCCESS'
export const USER_ROLE_DETAIL_GET_FAILURE = 'USER_ROLE_DETAIL_GET_FAILURE'

export const CREATE_USER_ROLES_REQUEST = 'CREATE_USER_ROLES_REQUEST'
export const CREATE_USER_ROLES_SUCCESS = 'CREATE_USER_ROLES_SUCCESS'
export const CREATE_USER_ROLES_FAILURE = 'CREATE_USER_ROLES_FAILURE'

export const EDIT_USER_ROLES_REQUEST = 'EDIT_USER_ROLES_REQUEST'
export const EDIT_USER_ROLES_SUCCESS = 'EDIT_USER_ROLES_SUCCESS'
export const EDIT_USER_ROLES_FAILURE = 'EDIT_USER_ROLES_FAILURE'

export const DELETE_USER_ROLES_REQUEST = 'DELETE_USER_ROLES_REQUEST'
export const DELETE_USER_ROLES_SUCCESS = 'DELETE_USER_ROLES_SUCCESS'
export const DELETE_USER_ROLES_FAILURE = 'DELETE_USER_ROLES_FAILURE'

export const PERMISSION_GET_ALL_REQUEST = 'PERMISSION_GET_ALL_REQUEST'
export const PERMISSION_GET_ALL_SUCCESS = 'PERMISSION_GET_ALL_SUCCESS'
export const PERMISSION_GET_ALL_FAILURE = 'PERMISSION_GET_ALL_FAILURE'

export const USERS_GET_ADDRESS_BOOKS_REQUEST = 'USERS_GET_ADDRESS_BOOKS_REQUEST'
export const USERS_GET_ADDRESS_BOOKS_SUCCESS = 'USERS_GET_ADDRESS_BOOKS_SUCCESS'
export const USERS_GET_ADDRESS_BOOKS_FAILURE = 'USERS_GET_ADDRESS_BOOKS_FAILURE'

export const USERS_GET_ADDRESS_BOOK_BY_ID_REQUEST = 'USERS_GET_ADDRESS_BOOK_BY_ID_REQUEST'
export const USERS_GET_ADDRESS_BOOK_BY_ID_SUCCESS = 'USERS_GET_ADDRESS_BOOK_BY_ID_SUCCESS'
export const USERS_GET_ADDRESS_BOOK_BY_ID_FAILURE = 'USERS_GET_ADDRESS_BOOK_BY_ID_FAILURE'

export const CREATE_USER_ADDRESS_BOOK_REQUEST = 'CREATE_USER_ADDRESS_BOOK_REQUEST'
export const CREATE_USER_ADDRESS_BOOK_SUCCESS = 'CREATE_USER_ADDRESS_BOOK_SUCCESS'
export const CREATE_USER_ADDRESS_BOOK_FAILURE = 'CREATE_USER_ADDRESS_BOOK_FAILURE'

export const UPDATE_USER_ADDRESS_BOOK_REQUEST = 'UPDATE_USER_ADDRESS_BOOK_REQUEST'
export const UPDATE_USER_ADDRESS_BOOK_SUCCESS = 'UPDATE_USER_ADDRESS_BOOK_SUCCESS'
export const UPDATE_USER_ADDRESS_BOOK_FAILURE = 'UPDATE_USER_ADDRESS_BOOK_FAILURE'

export const DELETE_USER_ADDRESS_BOOK_REQUEST = 'DELETE_USER_ADDRESS_BOOK_REQUEST'
export const DELETE_USER_ADDRESS_BOOK_SUCCESS = 'DELETE_USER_ADDRESS_BOOK_SUCCESS'
export const DELETE_USER_ADDRESS_BOOK_FAILURE = 'DELETE_USER_ADDRESS_BOOK_FAILURE'

export const GET_CLIENT_APPS_REQUEST = 'GET_CLIENT_APPS_REQUEST'
export const GET_CLIENT_APPS_SUCCESS = 'GET_CLIENT_APPS_SUCCESS'
export const GET_CLIENT_APPS_FAILURE = 'GET_CLIENT_APPS_FAILURE'

export const CREATE_USER_ROLE_MAPPING_REQUEST = 'CREATE_USER_ROLE_MAPPING_REQUEST'
export const CREATE_USER_ROLE_MAPPING_SUCCESS = 'CREATE_USER_ROLE_MAPPING_SUCCESS'
export const CREATE_USER_ROLE_MAPPING_FAILURE = 'CREATE_USER_ROLE_MAPPING_FAILURE'

export const UPDATE_USER_APPLICATION_ROLE_LOCAL_REQUEST =
  'UPDATE_USER_APPLICATION_ROLE_LOCAL_REQUEST'
export const UPDATE_USER_APPLICATION_ROLE_LOCAL_SUCCESS =
  'UPDATE_USER_APPLICATION_ROLE_LOCAL_SUCCESS'
export const UPDATE_USER_APPLICATION_ROLE_LOCAL_FAILURE =
  'UPDATE_USER_APPLICATION_ROLE_LOCAL_FAILURE'

export const GET_ALL_ELIGIBILITY_REQUEST = 'GET_ALL_ELIGIBILITY_REQUEST'
export const GET_ALL_ELIGIBILITY_SUCCESS = 'GET_ALL_ELIGIBILITY_SUCCESS'
export const GET_ALL_ELIGIBILITY_FAILURE = 'GET_ALL_ELIGIBILITY_FAILURE'

export const GET_ALL_PUBLIC_ELIGIBILITY_REQUEST = 'GET_ALL_PUBLIC_ELIGIBILITY_REQUEST'
export const GET_ALL_PUBLIC_ELIGIBILITY_SUCCESS = 'GET_ALL_PUBLIC_ELIGIBILITY_SUCCESS'
export const GET_ALL_PUBLIC_ELIGIBILITY_FAILURE = 'GET_ALL_PUBLIC_ELIGIBILITY_FAILURE'

export const GET_PUBLIC_ELIGIBILITY_DETAIL_REQUEST = 'GET_PUBLIC_ELIGIBILITY_DETAIL_REQUEST'
export const GET_PUBLIC_ELIGIBILITY_DETAIL_SUCCESS = 'GET_PUBLIC_ELIGIBILITY_DETAIL_SUCCESS'
export const GET_PUBLIC_ELIGIBILITY_DETAIL_FAILURE = 'GET_PUBLIC_ELIGIBILITY_DETAIL_FAILURE'

export const UPDATE_ITEMS_PER_PAGE_REQUEST = 'UPDATE_ITEMS_PER_PAGE_REQUEST'
export const UPDATE_ITEMS_PER_PAGE_SUCCESS = 'UPDATE_ITEMS_PER_PAGE_SUCCESS'

export const EMPLOYEES_GET_ALL_REQUEST = 'EMPLOYEES_GET_ALL_REQUEST'
export const EMPLOYEES_GET_ALL_SUCCESS = 'EMPLOYEES_GET_ALL_SUCCESS'
export const EMPLOYEES_GET_ALL_FAILURE = 'EMPLOYEES_GET_ALL_FAILURE'
