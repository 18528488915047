import { toast } from 'react-toastify'
import * as t from './actionTypes'
import api from '../../utils/api'

export const organizationActions = {
  createOrganization,
  updateOrganization,
  updateOrganizationLogoUrl,
  getOrganization,
  getOrganizationList,
  deleteOrganization,
  searchMembersToOrganizationList,
  searchMembersInOrganizationList,
  updateMembersOrganization,
  updateAddMembersToOrganization,
  updateRemoveMembersFromOrganization,
}

const addSuccessAlert = (message, key, data) => {
  toast.success(message)
}

function createOrganization(data) {
  return (dispatch) => {
    dispatch(request(data))
    return api.organizationService
      .createOrganization(data)
      .then((response) => {
        dispatch(success(response.data))
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }

  function request() {
    return { type: t.CREATE_ORGANIZATION_REQUEST }
  }

  function success(organization) {
    return { type: t.CREATE_ORGANIZATION_SUCCESS, organization }
  }

  function failure(error) {
    return { type: t.CREATE_ORGANIZATION_FAILURE, error }
  }
}

function updateOrganization(data) {
  return (dispatch) => {
    dispatch(request(data))
    return api.organizationService
      .updateOrganization(data)
      .then((response) => {
        addSuccessAlert(`The changes have been saved.`)
        dispatch(success(response.data))
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }

  function request() {
    return { type: t.UPDATE_ORGANIZATION_REQUEST }
  }

  function success(organization) {
    return { type: t.UPDATE_ORGANIZATION_SUCCESS, organization }
  }

  function failure(error) {
    return { type: t.UPDATE_ORGANIZATION_FAILURE, error }
  }
}

function updateOrganizationLogoUrl(logoFile) {
  return (dispatch) => {
    dispatch(request())
    dispatch(success(logoFile))
  }

  function request() {
    return { type: t.UPDATE_ORGANIZATION_LOGO_URL_REQUEST }
  }

  function success(logoUrl) {
    return { type: t.UPDATE_ORGANIZATION_LOGO_URL_SUCCESS, logoUrl }
  }
}

function getOrganizationList(data) {
  return (dispatch) => {
    dispatch(request(data))
    return api.organizationService
      .getOrganizations(data)
      .then((response) => {
        dispatch(success(response))
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }

  function request() {
    return { type: t.GET_ORGANIZATIONS_REQUEST }
  }

  function success(response) {
    return { type: t.GET_ORGANIZATIONS_SUCCESS, response }
  }

  function failure(error) {
    return { type: t.GET_ORGANIZATIONS_FAILURE, error }
  }
}

function getOrganization(id) {
  return (dispatch) => {
    dispatch(request(id))
    return api.organizationService
      .getOrganization(id)
      .then((response) => {
        dispatch(success(response.data))
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }

  function request() {
    return { type: t.GET_ORGANIZATION_REQUEST }
  }

  function success(organization) {
    return { type: t.GET_ORGANIZATION_SUCCESS, organization }
  }

  function failure(error) {
    return { type: t.GET_ORGANIZATION_FAILURE, error }
  }
}

function deleteOrganization(id) {
  return (dispatch) => {
    dispatch(request(id))
    return api.organizationService
      .deleteOrganization(id)
      .then((response) => {
        dispatch(success(response))
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }

  function request() {
    return { type: t.DELETE_ORGANIZATION_REQUEST }
  }

  function success() {
    return { type: t.DELETE_ORGANIZATION_SUCCESS }
  }

  function failure(error) {
    return { type: t.DELETE_ORGANIZATION_FAILURE, error }
  }
}

function searchMembersToOrganizationList(data) {
  return (dispatch) => {
    dispatch(request(data))
    return api.organizationService
      .searchUserInOrganization(data.organizatonId, false)
      .then((response) => {
        dispatch(success(response))
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }

  function request() {
    return { type: t.GET_SEARCH_MEMBERS_TO_ORGANIZATION_REQUEST }
  }

  function success(response) {
    return { type: t.GET_SEARCH_MEMBERS_TO_ORGANIZATION_SUCCESS, response }
  }

  function failure(error) {
    return { type: t.GET_SEARCH_MEMBERS_TO_ORGANIZATION_FAILURE, error }
  }
}

function searchMembersInOrganizationList(data) {
  return (dispatch) => {
    dispatch(request(data))
    return api.organizationService
      .searchUserInOrganization(data.organizatonId, true)
      .then((response) => {
        dispatch(success(response))
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }

  function request() {
    return { type: t.GET_SEARCH_MEMBERS_IN_ORGANIZATION_REQUEST }
  }

  function success(response) {
    return { type: t.GET_SEARCH_MEMBERS_IN_ORGANIZATION_SUCCESS, response }
  }

  function failure(error) {
    return { type: t.GET_SEARCH_MEMBERS_IN_ORGANIZATION_FAILURE, error }
  }
}

function updateMembersOrganization(data) {
  return (dispatch) => {
    dispatch(request(data))
    return api.organizationService
      .updateMembersOrganization(data)
      .then((response) => {
        addSuccessAlert(`The changes have been saved.`)
        dispatch(success(response.data))
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }

  function request() {
    return { type: t.UPDATE_MEMBERS_ORGANIZATION_REQUEST }
  }

  function success() {
    return { type: t.UPDATE_MEMBERS_ORGANIZATION_SUCCESS }
  }

  function failure(error) {
    return { type: t.UPDATE_MEMBERS_ORGANIZATION_FAILURE, error }
  }
}

function updateAddMembersToOrganization(members) {
  return (dispatch) => {
    dispatch(request())
    dispatch(success(members))
  }

  function request() {
    return { type: t.UPDATE_ADD_MEMBERS_TO_ORGANIZATION_REQUEST }
  }

  function success(members) {
    return { type: t.UPDATE_ADD_MEMBERS_TO_ORGANIZATION_SUCCESS, members }
  }
}

function updateRemoveMembersFromOrganization(members) {
  return (dispatch) => {
    dispatch(request())
    dispatch(success(members))
  }

  function request() {
    return { type: t.UPDATE_REMOVE_MEMBERS_FROM_ORGANIZATION_REQUEST }
  }

  function success(members) {
    return { type: t.UPDATE_REMOVE_MEMBERS_FROM_ORGANIZATION_SUCCESS, members }
  }
}
