import axios from 'axios'
import { SERVICE_DOMAIN } from 'src/constants/constants'

const createAccount = (payLoad) => {
  return axios.post(SERVICE_DOMAIN + '/api/public/initialize', payLoad)
}

const activateAccount = (data) => {
  return axios.get(SERVICE_DOMAIN + `/api/public/activate?key=${data.key}`)
}

export const registerService = {
  createAccount,
  activateAccount,
}
