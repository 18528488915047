import { authService } from '../services/auth.service'
import { cheveretoService } from '../services/chevereto.service'
import { employerService } from '../services/employer.service'
import { userService } from '../services/user.service'
import { permissionService } from '../services/permission.service'
import { organizationService } from '../services/organization.service'
import { borrowerService } from '../services/borrower.service'
import { loanService } from '../services/loan.service'
import { taskService } from '../services/task.service'
import { reportService } from '../services/report.service'
import { registerService } from '../services/register.service'
import { alarmService } from '../services/alarm.service'

const api = {
  authService,
  cheveretoService,
  employerService,
  permissionService,
  userService,
  organizationService,
  borrowerService,
  loanService,
  taskService,
  reportService,
  registerService,
  alarmService,
}

export default api
