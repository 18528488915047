import { authHelpers } from './auth-helper'

const permissionEnums = {
  ROLE_SUPER_ADMIN: 'ROLE_SUPER_ADMIN',
  ROLE_ADMIN: 'ROLE_ADMIN',
  ROLE_ORGANIZATION_ADMIN: 'ROLE_ORGANIZATION_ADMIN',
  ROLE_ACCOUNTANT: 'ROLE_ACCOUNTANT',
  ROLE_SUPERVISOR: 'ROLE_SUPERVISOR',
  ROLE_CONTRACTOR: 'ROLE_CONTRACTOR',
  ROLE_SUPER_COLLECTOR: 'ROLE_SUPER_COLLECTOR',
  ROLE_COLLECTOR: 'ROLE_COLLECTOR',
  ROLE_KYC: 'ROLE_KYC',
  ROLE_USER: 'ROLE_USER',
}

const whitelist = ['localhost', 'cc']

export const accountHelpers = {
  getAccount,
  getMyOrganization,
  hasAccessPermission,
  getUserPermissions,
  hasLoanCreatePermission,
  hasViewLoanTablePermission,
  hasNoteAccessPermission,
  hasRoleSuperAdmin,
  hasBorrowerCreateUpdatePermission,
  hasDashboardAccessPermission,
  hasLoanProcessPermission,
  hasAddRepayment,
  hasPayDebtEarlyPermission,
  hasContractorPermission,
  hasDisburseLoanProcessPermission,
  hasEnabledPenaltyPermission,
  hasFilterLoanByEmployeePermission,
  hasViewPrincipalLoanTablePermission,
  getSubdomain,
  getMyAccessOrganizationId,
  permissionEnums,
  hasSuperAmin,
  hasKYCCreation,
}

function getAccount() {
  let userAccount
  try {
    userAccount = JSON.parse(localStorage.getItem('account'))
  } catch (error) {
    console.error('missing account!')
  }
  return userAccount
}

function getMyOrganization() {
  const account = getAccount()
  return account && account.organizations && account.organizations.length > 0
    ? account.organizations[0]
    : null
}

function getMyAccessOrganizationId() {
  const account = getAccount()
  return account && account.organizations && account.organizations.length > 0
    ? account.organizations[0].organizationId
    : null
}

function getUserPermissions() {
  const userAccount = getAccount()
  const userPermissions =
    userAccount && userAccount.authorities && userAccount.authorities.length > 0
      ? userAccount.authorities.map((a) => a.name)
      : []
  return userPermissions
}

function hasSuperAmin() {
  const userAccount = getAccount()
  return userAccount.email && userAccount.email.indexOf(process.env.REACT_APP_SUPER_ADMIN) > -1
}

function hasRoleSuperAdmin(defaultRoles = [permissionEnums.ROLE_SUPER_ADMIN]) {
  const foundPermissions = authHelpers.findMatchRolePermissions(getUserPermissions(), defaultRoles)
  return foundPermissions && foundPermissions.length > 0
}

function hasAccessPermission(
  defaultRoles = [
    permissionEnums.ROLE_SUPER_ADMIN,
    permissionEnums.ROLE_ADMIN,
    permissionEnums.ROLE_ORGANIZATION_ADMIN,
  ],
) {
  const foundPermissions = authHelpers.findMatchRolePermissions(getUserPermissions(), defaultRoles)
  return foundPermissions && foundPermissions.length > 0
}

function hasNoteAccessPermission(
  defaultRoles = [
    permissionEnums.ROLE_SUPER_ADMIN,
    permissionEnums.ROLE_ADMIN,
    permissionEnums.ROLE_ORGANIZATION_ADMIN,
    permissionEnums.ROLE_ACCOUNTANT,
  ],
) {
  const foundPermissions = authHelpers.findMatchRolePermissions(getUserPermissions(), defaultRoles)
  return foundPermissions && foundPermissions.length > 0
}

function hasViewLoanTablePermission(
  defaultRoles = [
    permissionEnums.ROLE_SUPER_ADMIN,
    permissionEnums.ROLE_ADMIN,
    permissionEnums.ROLE_ORGANIZATION_ADMIN,
    permissionEnums.ROLE_ACCOUNTANT,
  ],
) {
  const foundPermissions = authHelpers.findMatchRolePermissions(getUserPermissions(), defaultRoles)
  return foundPermissions && foundPermissions.length > 0
}

function hasEnabledPenaltyPermission(
  defaultRoles = [
    permissionEnums.ROLE_SUPER_ADMIN,
    permissionEnums.ROLE_ADMIN,
    permissionEnums.ROLE_ORGANIZATION_ADMIN,
    // permissionEnums.ROLE_COLLECTOR,
    // permissionEnums.ROLE_SUPER_COLLECTOR,
  ],
) {
  const foundPermissions = authHelpers.findMatchRolePermissions(getUserPermissions(), defaultRoles)
  return foundPermissions && foundPermissions.length > 0
}

function hasDashboardAccessPermission(
  defaultRoles = [
    permissionEnums.ROLE_SUPER_ADMIN,
    permissionEnums.ROLE_ADMIN,
    permissionEnums.ROLE_ORGANIZATION_ADMIN,
    permissionEnums.ROLE_ACCOUNTANT,
  ],
) {
  const foundPermissions = authHelpers.findMatchRolePermissions(getUserPermissions(), defaultRoles)
  return foundPermissions && foundPermissions.length > 0
}

function hasLoanProcessPermission(
  defaultRoles = [
    permissionEnums.ROLE_SUPER_ADMIN,
    permissionEnums.ROLE_ADMIN,
    permissionEnums.ROLE_ORGANIZATION_ADMIN,
    permissionEnums.ROLE_ACCOUNTANT,
    permissionEnums.ROLE_COLLECTOR,
  ],
) {
  const foundPermissions = authHelpers.findMatchRolePermissions(getUserPermissions(), defaultRoles)
  return foundPermissions && foundPermissions.length > 0
}

function hasDisburseLoanProcessPermission(
  defaultRoles = [
    permissionEnums.ROLE_SUPER_ADMIN,
    permissionEnums.ROLE_ADMIN,
    permissionEnums.ROLE_ORGANIZATION_ADMIN,
    permissionEnums.ROLE_ACCOUNTANT,
  ],
) {
  const foundPermissions = authHelpers.findMatchRolePermissions(getUserPermissions(), defaultRoles)
  return foundPermissions && foundPermissions.length > 0
}

function hasAddRepayment(
  defaultRoles = [
    permissionEnums.ROLE_SUPER_ADMIN,
    permissionEnums.ROLE_ADMIN,
    permissionEnums.ROLE_ORGANIZATION_ADMIN,
    permissionEnums.ROLE_SUPERVISOR,
    permissionEnums.ROLE_COLLECTOR,
  ],
) {
  const foundPermissions = authHelpers.findMatchRolePermissions(getUserPermissions(), defaultRoles)
  return foundPermissions && foundPermissions.length > 0
}

function hasLoanCreatePermission(
  defaultRoles = [
    permissionEnums.ROLE_SUPER_ADMIN,
    permissionEnums.ROLE_ADMIN,
    permissionEnums.ROLE_ORGANIZATION_ADMIN,
    permissionEnums.ROLE_SUPERVISOR,
    permissionEnums.ROLE_CONTRACTOR,
    permissionEnums.ROLE_COLLECTOR,
  ],
) {
  const foundPermissions = authHelpers.findMatchRolePermissions(getUserPermissions(), defaultRoles)
  return foundPermissions && foundPermissions.length > 0
}

function hasBorrowerCreateUpdatePermission(
  defaultRoles = [
    permissionEnums.ROLE_SUPER_ADMIN,
    permissionEnums.ROLE_ADMIN,
    permissionEnums.ROLE_ORGANIZATION_ADMIN,
    permissionEnums.ROLE_SUPERVISOR,
    permissionEnums.ROLE_CONTRACTOR,
  ],
) {
  const foundPermissions = authHelpers.findMatchRolePermissions(getUserPermissions(), defaultRoles)
  return foundPermissions && foundPermissions.length > 0
}

function hasPayDebtEarlyPermission(
  defaultRoles = [
    permissionEnums.ROLE_SUPER_ADMIN,
    permissionEnums.ROLE_ADMIN,
    permissionEnums.ROLE_ORGANIZATION_ADMIN,
    permissionEnums.ROLE_SUPERVISOR,
    permissionEnums.ROLE_SUPER_COLLECTOR,
    permissionEnums.ROLE_COLLECTOR,
  ],
) {
  const foundPermissions = authHelpers.findMatchRolePermissions(getUserPermissions(), defaultRoles)
  return foundPermissions && foundPermissions.length > 0
}

function hasFilterLoanByEmployeePermission(
  defaultRoles = [
    permissionEnums.ROLE_SUPER_ADMIN,
    permissionEnums.ROLE_ADMIN,
    permissionEnums.ROLE_ORGANIZATION_ADMIN,
    permissionEnums.ROLE_COLLECTOR,
    permissionEnums.ROLE_ACCOUNTANT,
  ],
) {
  const foundPermissions = authHelpers.findMatchRolePermissions(getUserPermissions(), defaultRoles)
  return foundPermissions && foundPermissions.length > 0
}

function hasViewPrincipalLoanTablePermission(
  defaultRoles = [
    permissionEnums.ROLE_SUPER_ADMIN,
    permissionEnums.ROLE_ADMIN,
    permissionEnums.ROLE_ORGANIZATION_ADMIN,
    permissionEnums.ROLE_ACCOUNTANT,
    permissionEnums.ROLE_COLLECTOR,
  ],
) {
  const foundPermissions = authHelpers.findMatchRolePermissions(getUserPermissions(), defaultRoles)
  return foundPermissions && foundPermissions.length > 0
}

function hasKYCCreation(defaultRoles = [permissionEnums.ROLE_KYC]) {
  const foundPermissions = authHelpers.findMatchRolePermissions(getUserPermissions(), defaultRoles)
  return foundPermissions && foundPermissions.length > 0
}

function hasContractorPermission(defaultRoles = [permissionEnums.ROLE_CONTRACTOR]) {
  const foundPermissions = authHelpers.findMatchRolePermissions(getUserPermissions(), defaultRoles)
  return foundPermissions && foundPermissions.length > 0
}

function getSubdomain() {
  let subdomain = window.location.hostname.split('.')[0]
  subdomain = whitelist.indexOf(subdomain) > -1 ? 'elend' : subdomain
  return subdomain
}
