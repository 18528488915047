import moment from 'moment'

const formatter = new Intl.NumberFormat('vi-VN', {
  minimumFractionDigits: 0,
})

const getShortId = (id) => {
  return id.substring(0, 8).toUpperCase()
}

const currencyFormatter = (number) => {
  if (!number) return 0
  return formatter.format(Math.round(number))
}

const dateFormatter = (date) => {
  return moment(date).toISOString()
}

const addLeadingZeros = (num, totalLength) => {
  return String(num).padStart(totalLength, '0')
}

const exportCsvFile = (data, filename, mime, bom) => {
  const blobData = typeof bom !== 'undefined' ? [bom, data] : [data]
  const blob = new Blob(blobData, { type: mime || 'application/octet-stream' })
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    // IE workaround for "HTML7007: One or more blob URLs were
    // revoked by closing the blob for which they were created.
    // These URLs will no longer resolve as the data backing
    // the URL has been freed."
    window.navigator.msSaveBlob(blob, filename)
  } else {
    const blobURL =
      window.URL && window.URL.createObjectURL
        ? window.URL.createObjectURL(blob)
        : window.webkitURL.createObjectURL(blob)
    const tempLink = document.createElement('a')
    tempLink.style.display = 'none'
    tempLink.href = blobURL
    tempLink.setAttribute('download', filename)

    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop safari if pop up blocking
    // is enabled.
    if (typeof tempLink.download === 'undefined') {
      tempLink.setAttribute('target', '_blank')
    }

    document.body.appendChild(tempLink)
    tempLink.click()

    // Fixes "webkit blob resource error 1"
    setTimeout(function () {
      document.body.removeChild(tempLink)
      window.URL.revokeObjectURL(blobURL)
    }, 200)
  }
}

const shortDateFormatter = (date) => {
  return moment(date).format('DD-MM-yyyy')
}

const exportLoanTransactionHTML = (t, profile, account, transaction, paymentMethod) => {
  const html = `
    <!DOCTYPE html>
<html>
<head>
<meta charset="utf-8" />
<title>${t('receipt.repaymentReceiptHeader')}</title>
<link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet" />
<style>
.invoice-box {
max-width: 800px;
margin: auto;
padding: 30px;
border: 1px solid #eee;
box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
font-size: 16px;
line-height: 24px;
font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
color: #555;
}

.invoice-box table {
width: 100%;
line-height: inherit;
text-align: left;
}

.invoice-box table td {
padding: 5px;
vertical-align: top;
}

.invoice-box table tr td:nth-child(2) {
text-align: right;
}

.invoice-box table tr.top table td {
padding-bottom: 20px;
}

.invoice-box table tr.top table td.title {
font-size: 45px;
line-height: 45px;
color: #333;
}

.invoice-box table tr.information table td {
padding-bottom: 40px;
}

.invoice-box table tr.heading td {
background: #eee;
border-bottom: 1px solid #ddd;
font-weight: bold;
}

.invoice-box table tr.details td {
padding-bottom: 20px;
}

.invoice-box table tr.item td {
border-bottom: 1px solid #eee;
}

.invoice-box table tr.item.last td {
border-bottom: none;
}

.invoice-box table tr.total td:nth-child(2) {
border-top: 2px solid #eee;
font-weight: bold;
}

@media only screen and (max-width: 600px) {
.invoice-box table tr.top table td {
width: 100%;
display: block;
text-align: center;
}

.invoice-box table tr.information table td {
width: 100%;
display: block;
text-align: center;
}
}

/** RTL **/
.invoice-box.rtl {
direction: rtl;
font-family: Tahoma, 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
}

.invoice-box.rtl table {
text-align: right;
}

.invoice-box.rtl table tr td:nth-child(2) {
text-align: left;
}

.text-secondary-d1 {
    color: #728299!important;
}
</style>
</head>

<body>
<div class="invoice-box">
<div class="row">
<div class="col-xs-12">
<h2 style="text-align: center;">${t('receipt.repaymentReceiptTitle')}</h2>
</div>
</div>
<table cellpadding="0" cellspacing="0">
<tr class="top">
<td colspan="2">
<table>
<tr>
<td class="title">
<img src="https://res.cloudinary.com/tvsales/image/upload/v1654925975/elend/logo-text.svg" style="height: 100px;" />
</td>

<td>
${t('view.Borrowers.tabTransaction.TransactionId')} #: ${transaction.id}<br />
${t('view.Borrowers.tabTransaction.EffectiveDate')}: ${transaction.createdDate}<br />
${t('receipt.Status')}: ${
    transaction.completedDate ? t('receipt.confirmed') : t('receipt.paid')
  }<br />
</td>
</tr>
</table>
</td>
</tr>

<tr class="information">
<td colspan="2">
<table>
<tr>
<td>
Công ty Elend<br />
12 Đường 33<br />
Phường 10, Quận 6, TpHCM <br />
<i class="fa fa-phone fa-flip-horizontal text-secondary"></i> 0833.100.188<br />
<span>Người thu: <b>${account.lastName + ' ' + account.firstName}</b></span>
</td>

<td>
<span>Mã khách hàng: <b>${profile.id.substring(0, 8)}</b></span><br />
<b>${profile.lastName + ' ' + profile.firstName}</b><br />
<i class="fa fa-phone fa-flip-horizontal text-secondary"></i>
${profile.phone.replace('_', ' - ')}
</td>
</tr>
</table>
</td>
</tr>

<tr class="heading">
<td>${t('receipt.paymentMethod')}</td>

<td>${t('receipt.amount')} #</td>
</tr>

<tr class="details">
<td>${paymentMethod}</td>

<td>${currencyFormatter(transaction.amount)} VNĐ</td>
</tr>
</table>
<hr />
<div>
    <span class="text-secondary-d1 text-105">${t('receipt.thankYou')}</span>
</div>
</div>
</body>
</html>
    `
  return html
}

export const loanHelpers = {
  currencyFormatter,
  dateFormatter,
  addLeadingZeros,
  getShortId,
  exportCsvFile,
  shortDateFormatter,
  exportLoanTransactionHTML,
}
