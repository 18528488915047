import { combineReducers } from 'redux'
import { changeStateReducer } from './ui.reducer'
import { employerCreation } from '../views/employers/employer.creation.reducer'
import users from '../views/users/reducer'
import organizations from '../views/organization/reducer'
import { loadingBarReducer } from 'react-redux-loading-bar'
import { alert } from './alert.reducer'
import auths from './auth.reducer'
import borrowers from '../views/borrowers/reducer'
import loans from '../views/loans/reducer'
import report from './report.reducer'
import register from '../views/registers/reducer'
import authentication from '../views/authentications/reducer'
import tasks from '../views/tasks/reducer'

const rootReducer = combineReducers({
  changeState: changeStateReducer,
  loadingBar: loadingBarReducer,
  employerCreation,
  users,
  organizations,
  alert,
  auths,
  borrowers,
  loans,
  report,
  register,
  authentication,
  tasks,
})

export default rootReducer
