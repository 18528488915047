import * as t from './actionTypes'

const initialState = {
  isFetched: false,
  isFetching: false,
  organization: null,
  organizations: [],
  isRedirect: false,
  totalPages: 0,
  totalPagesAllMembers: 0,
  totalPagesAssignedMembers: 0,
  itemsPerPage: 10,
  allMembers: [],
  assignedMembers: [],
}

const _generateOrgCurrentBrand = (organization) => {
  if (organization.brands && organization.brands.length > 0) {
    organization.currentBrand = organization.brands[0]
  }
  return organization
}

const organizationReducer = (state = initialState, action) => {
  switch (action.type) {
    case t.CREATE_ORGANIZATION_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
        isRedirect: false,
      })
    case t.CREATE_ORGANIZATION_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: true,
        isRedirect: true,
        organization: _generateOrgCurrentBrand(action.organization),
      })
    case t.CREATE_ORGANIZATION_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorFetch: action.error,
      })
    case t.UPDATE_ORGANIZATION_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
        isRedirect: false,
      })
    case t.UPDATE_ORGANIZATION_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: true,
        isRedirect: true,
        organization: _generateOrgCurrentBrand(action.organization),
      })
    case t.UPDATE_ORGANIZATION_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorFetch: action.error,
      })

    case t.UPDATE_ORGANIZATION_LOGO_URL_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
        isRedirect: false,
      })
    case t.UPDATE_ORGANIZATION_LOGO_URL_SUCCESS:
      const organization = state.organization
      organization.currentBrand.logoUrl = action.logoUrl
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: true,
        isRedirect: true,
        organization: organization,
      })
    case t.GET_ORGANIZATION_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
        isRedirect: false,
      })
    case t.GET_ORGANIZATION_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: true,
        organization: _generateOrgCurrentBrand(action.organization),
      })
    case t.GET_ORGANIZATION_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorFetch: action.error,
      })
    case t.GET_ORGANIZATIONS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
        isRedirect: false,
      })
    case t.GET_ORGANIZATIONS_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: true,
        organizations: action.response.data,
        totalPages: Math.ceil(action.response.headers['x-total-count'] / state.itemsPerPage),
      })
    case t.GET_ORGANIZATIONS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorFetch: action.error,
      })
    case t.DELETE_ORGANIZATION_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
        isRedirect: false,
      })
    case t.DELETE_ORGANIZATION_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: true,
      })
    case t.DELETE_ORGANIZATION_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorFetch: action.error,
      })
    case t.GET_SEARCH_MEMBERS_TO_ORGANIZATION_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
        isRedirect: false,
      })
    case t.GET_SEARCH_MEMBERS_TO_ORGANIZATION_SUCCESS:
      const data = action.response.data
      if (data && data.length > 0) {
        data.forEach((i) => {
          i.fullName = `${i.lastName.trim()} ${i.firstName.trim()}`
        })
      }
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: true,
        allMembers: data,
        totalPagesAllMembers: Math.ceil(
          action.response.headers['x-total-count'] / state.itemsPerPage,
        ),
      })
    case t.GET_SEARCH_MEMBERS_TO_ORGANIZATION_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorFetch: action.error,
      })
    case t.GET_SEARCH_MEMBERS_IN_ORGANIZATION_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
        isRedirect: false,
      })
    case t.GET_SEARCH_MEMBERS_IN_ORGANIZATION_SUCCESS:
      const assignedMembers = action.response.data
      if (assignedMembers && assignedMembers.length > 0) {
        assignedMembers.forEach((i) => {
          i.fullName = `${i.lastName.trim()} ${i.firstName.trim()}`
        })
      }
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: true,
        assignedMembers: assignedMembers,
        totalPagesAssignedMembers: Math.ceil(
          action.response.headers['x-total-count'] / state.itemsPerPage,
        ),
      })
    case t.GET_SEARCH_MEMBERS_IN_ORGANIZATION_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorFetch: action.error,
      })
    case t.UPDATE_MEMBERS_ORGANIZATION_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
      })
    case t.UPDATE_MEMBERS_ORGANIZATION_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: true,
      })
    case t.UPDATE_MEMBERS_ORGANIZATION_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorFetch: action.error,
      })

    case t.UPDATE_ADD_MEMBERS_TO_ORGANIZATION_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
        isRedirect: false,
      })
    case t.UPDATE_ADD_MEMBERS_TO_ORGANIZATION_SUCCESS:
      const remainAllMembers = state.allMembers.filter(
        (i) => action.members.filter((m) => m.email === i.email).length === 0,
      )
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: true,
        isRedirect: true,
        allMembers: remainAllMembers,
        assignedMembers: state.assignedMembers.concat(action.members),
      })
    case t.UPDATE_REMOVE_MEMBERS_FROM_ORGANIZATION_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
        isRedirect: false,
      })
    case t.UPDATE_REMOVE_MEMBERS_FROM_ORGANIZATION_SUCCESS:
      const remainAssignMembers = state.assignedMembers.filter(
        (i) => action.members.filter((m) => m.email === i.email).length === 0,
      )
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: true,
        isRedirect: true,
        allMembers: state.allMembers.concat(action.members),
        assignedMembers: remainAssignMembers,
      })
    default:
      return state
  }
}
export default organizationReducer
