export const CREATE_TASK_REQUEST = 'CREATE_TASK_REQUEST'
export const CREATE_TASK_SUCCESS = 'CREATE_TASK_SUCCESS'
export const CREATE_TASK_FAILURE = 'CREATE_TASK_FAILURE'

export const ASSIGNED_MEMBERS_TASK_REQUEST = 'ASSIGNED_MEMBERS_TASK_REQUEST'
export const ASSIGNED_MEMBERS_TASK_SUCCESS = 'ASSIGNED_MEMBERS_TASK_SUCCESS'

export const UPDATE_TASK_REQUEST = 'UPDATE_TASK_REQUEST'
export const UPDATE_TASK_SUCCESS = 'UPDATE_TASK_SUCCESS'
export const UPDATE_TASK_FAILURE = 'UPDATE_TASK_FAILURE'

export const DELETE_TASK_REQUEST = 'DELETE_TASK_REQUEST'
export const DELETE_TASK_SUCCESS = 'DELETE_TASK_SUCCESS'
export const DELETE_TASK_FAILURE = 'DELETE_TASK_FAILURE'

export const GET_ALL_TASKS_REQUEST = 'GET_ALL_TASKS_REQUEST'
export const GET_ALL_TASKS_SUCCESS = 'GET_ALL_TASKS_SUCCESS'
export const GET_ALL_TASKS_FAILURE = 'GET_ALL_TASKS_FAILURE'

export const GET_TASK_REQUEST = 'GET_TASK_REQUEST'
export const GET_TASK_SUCCESS = 'GET_TASK_SUCCESS'
export const GET_TASK_FAILURE = 'GET_TASK_FAILURE'

export const GET_TASK_COMMENTS_REQUEST = 'GET_TASK_COMMENTS_REQUEST'
export const GET_TASK_COMMENTS_SUCCESS = 'GET_TASK_COMMENTS_SUCCESS'
export const GET_TASK_COMMENTS_FAILURE = 'GET_TASK_COMMENTS_FAILURE'

export const GET_TASK_COMMENT_DETAIL_REQUEST = 'GET_TASK_COMMENT_DETAIL_REQUEST'
export const GET_TASK_COMMENT_DETAIL_SUCCESS = 'GET_TASK_COMMENT_DETAIL_SUCCESS'
export const GET_TASK_COMMENT_DETAIL_FAILURE = 'GET_TASK_COMMENT_DETAIL_FAILURE'

export const CREATE_TASK_COMMENT_REQUEST = 'CREATE_TASK_COMMENT_REQUEST'
export const CREATE_TASK_COMMENT_SUCCESS = 'CREATE_TASK_COMMENT_SUCCESS'
export const CREATE_TASK_COMMENT_FAILURE = 'CREATE_TASK_COMMENT_FAILURE'

export const UPDATE_TASK_COMMENT_REQUEST = 'UPDATE_TASK_COMMENT_REQUEST'
export const UPDATE_TASK_COMMENT_SUCCESS = 'UPDATE_TASK_COMMENT_SUCCESS'
export const UPDATE_TASK_COMMENT_FAILURE = 'UPDATE_TASK_COMMENT_FAILURE'

export const DELETE_TASK_COMMENT_REQUEST = 'DELETE_TASK_COMMENT_REQUEST'
export const DELETE_TASK_COMMENT_SUCCESS = 'DELETE_TASK_COMMENT_SUCCESS'
export const DELETE_TASK_COMMENT_FAILURE = 'DELETE_TASK_COMMENT_FAILURE'

export const RESET_TASK_REQUEST = 'RESET_TASK_REQUEST'
export const RESET_TASK_SUCCESS = 'RESET_TASK_SUCCESS'

export const CREATE_ALARM_REQUEST = 'CREATE_ALARM_REQUEST'
export const CREATE_ALARM_SUCCESS = 'CREATE_ALARM_SUCCESS'
export const CREATE_ALARM_FAILURE = 'CREATE_ALARM_FAILURE'

export const UPDATE_ALARM_REQUEST = 'UPDATE_ALARM_REQUEST'
export const UPDATE_ALARM_SUCCESS = 'UPDATE_ALARM_SUCCESS'
export const UPDATE_ALARM_FAILURE = 'UPDATE_ALARM_FAILURE'

export const DELETE_ALARM_REQUEST = 'DELETE_ALARM_REQUEST'
export const DELETE_ALARM_SUCCESS = 'DELETE_ALARM_SUCCESS'
export const DELETE_ALARM_FAILURE = 'DELETE_ALARM_FAILURE'

export const GET_ALL_ALARMS_REQUEST = 'GET_ALL_ALARMS_REQUEST'
export const GET_ALL_ALARMS_SUCCESS = 'GET_ALL_ALARMS_SUCCESS'
export const GET_ALL_ALARMS_FAILURE = 'GET_ALL_ALARMS_FAILURE'

export const GET_ALARM_REQUEST = 'GET_ALARM_REQUEST'
export const GET_ALARM_SUCCESS = 'GET_ALARM_SUCCESS'
export const GET_ALARM_FAILURE = 'GET_ALARM_FAILURE'

export const RESET_ALARM_REQUEST = 'RESET_ALARM_REQUEST'
export const RESET_ALARM_SUCCESS = 'RESET_ALARM_SUCCESS'
