import axios from 'axios'
import { SERVICE_DOMAIN } from 'src/constants/constants'

const createOrganization = (data) => {
  return axios.post(SERVICE_DOMAIN + '/api/cms/organization/create', data)
}

const updateOrganization = (data) => {
  return axios.put(SERVICE_DOMAIN + '/api/cms/organization/update', data)
}

const getOrganization = (organizationId) => {
  return axios.get(SERVICE_DOMAIN + `/api/cms/organization/${organizationId}`)
}

const getOrganizations = (data) => {
  let url = SERVICE_DOMAIN + `/api/cms/organization?sort=createdDate,desc`

  if (data && data.all) {
    return axios.get(url)
  } else {
    url += `&page=${data.page}&size=${data.size}`
  }
  return axios.get(url)
}

const deleteOrganization = (organizationId) => {
  return axios.delete(SERVICE_DOMAIN + `/api/cms/organization/delete/${organizationId}`)
}

const searchUserInOrganization = (organizationId, isBelonged, keyword) => {
  let fullURL = isBelonged
    ? `get?organizationId.equals=${organizationId}`
    : `get?organizationId.notEquals=${organizationId}`
  return axios.get(SERVICE_DOMAIN + `/api/cms/account/${fullURL}`)
}

const updateMembersOrganization = (data) => {
  return axios.post(SERVICE_DOMAIN + '/api/cms/organization/assignUser', data)
}

export const organizationService = {
  createOrganization,
  updateOrganization,
  getOrganizations,
  getOrganization,
  deleteOrganization,
  searchUserInOrganization,
  updateMembersOrganization,
}
