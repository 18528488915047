import { toast } from 'react-toastify'
import * as t from './actionTypes'
import api from '../../utils/api'

function createLoan(data) {
  return (dispatch) => {
    dispatch(request(data))
    return api.loanService
      .createLoan(data)
      .then((response) => {
        toast.success('Tạo khoản vay thành công.')
        dispatch(success(response.data))
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }

  function request() {
    return { type: t.CREATE_LOAN_REQUEST }
  }

  function success(loan) {
    return { type: t.CREATE_LOAN_SUCCESS, loan }
  }

  function failure(error) {
    return { type: t.CREATE_LOAN_FAILURE, error }
  }
}

function updateLoan(data) {
  return (dispatch) => {
    dispatch(request(data))
    return api.loanService
      .updateLoan(data)
      .then((response) => {
        toast.success('Cập nhật khoản vay thành công.')
        dispatch(success(response.data))
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }

  function request() {
    return { type: t.UPDATE_LOAN_REQUEST }
  }

  function success(loan) {
    return { type: t.UPDATE_LOAN_SUCCESS, loan }
  }

  function failure(error) {
    return { type: t.UPDATE_LOAN_FAILURE, error }
  }
}

function updateLoanPenalty(data) {
  return (dispatch) => {
    dispatch(request(data))
    return api.loanService
      .updateLoanPenalty(data)
      .then((response) => {
        toast.success('Cập nhật khoản vay thành công.')
        dispatch(success(response.data))
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }

  function request() {
    return { type: t.UPDATE_LOAN_REQUEST }
  }

  function success(loan) {
    return { type: t.UPDATE_LOAN_SUCCESS, loan }
  }

  function failure(error) {
    return { type: t.UPDATE_LOAN_FAILURE, error }
  }
}

function getAllLoans(data) {
  return (dispatch) => {
    dispatch(request(data))
    return api.loanService
      .getAllLoans(data)
      .then((response) => {
        dispatch(success(response))
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }

  function request() {
    return { type: t.GET_ALL_LOANS_REQUEST }
  }

  function success(response) {
    return { type: t.GET_ALL_LOANS_SUCCESS, response }
  }

  function failure(error) {
    return { type: t.GET_ALL_LOANS_FAILURE, error }
  }
}

function deleteLoan(loanId) {
  return (dispatch) => {
    dispatch(request())
    return api.loanService
      .deleteLoanById(loanId)
      .then((response) => {
        dispatch(success(response))
        toast.warning('Đã xóa khoản vay!')
        window.location.href = `${window.location.origin}/#/loans`
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }

  function request() {
    return { type: t.DELETE_LOAN_REQUEST }
  }

  function success() {
    return { type: t.DELETE_LOAN_SUCCESS }
  }

  function failure(error) {
    return { type: t.DELETE_LOAN_FAILURE, error }
  }
}

function getLoanById(loanId) {
  return (dispatch) => {
    dispatch(request())
    return api.loanService
      .getLoanById(loanId)
      .then((response) => {
        dispatch(success(response.data))
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }

  function request() {
    return { type: t.GET_LOAN_REQUEST }
  }

  function success(loan) {
    return { type: t.GET_LOAN_SUCCESS, loan }
  }

  function failure(error) {
    return { type: t.GET_LOAN_FAILURE, error }
  }
}

function approveLoan(data) {
  return (dispatch) => {
    dispatch(request(data))
    return api.loanService
      .approveLoanById(data)
      .then((response) => {
        dispatch(success(response.data))
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }

  function request() {
    return { type: t.APPROVE_LOAN_REQUEST }
  }

  function success(loan) {
    return { type: t.APPROVE_LOAN_SUCCESS, loan }
  }

  function failure(error) {
    return { type: t.APPROVE_LOAN_FAILURE, error }
  }
}

function undoApprovalLoan(data) {
  return (dispatch) => {
    dispatch(request(data))
    return api.loanService
      .undoApproval(data)
      .then((response) => {
        dispatch(success(response.data))
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }

  function request() {
    return { type: t.UNDO_APPROVAL_LOAN_REQUEST }
  }

  function success(loan) {
    return { type: t.UNDO_APPROVAL_LOAN_SUCCESS, loan }
  }

  function failure(error) {
    return { type: t.UNDO_APPROVAL_LOAN_FAILURE, error }
  }
}

function rejectLoan(data) {
  return (dispatch) => {
    dispatch(request(data))
    return api.loanService
      .rejectLoanById(data)
      .then((response) => {
        dispatch(success(response.data))
        window.location.href = `${window.location.origin}/#/loans/rejected`
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }

  function request() {
    return { type: t.REJECT_LOAN_REQUEST }
  }

  function success(loan) {
    return { type: t.REJECT_LOAN_SUCCESS, loan }
  }

  function failure(error) {
    return { type: t.REJECT_LOAN_FAILURE, error }
  }
}

function undoRejectLoan(data) {
  return (dispatch) => {
    dispatch(request(data))
    return api.loanService
      .undoRejection(data)
      .then((response) => {
        dispatch(success(response.data))
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }

  function request() {
    return { type: t.UNDO_REJECT_LOAN_REQUEST }
  }

  function success(loan) {
    return { type: t.UNDO_REJECT_LOAN_SUCCESS, loan }
  }

  function failure(error) {
    return { type: t.UNDO_REJECT_LOAN_FAILURE, error }
  }
}

function disburseLoan(data) {
  return (dispatch) => {
    dispatch(request(data))
    return api.loanService
      .disburseLoan(data)
      .then((response) => {
        dispatch(success(response.data))
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }

  function request() {
    return { type: t.DISBURSE_LOAN_REQUEST }
  }

  function success(loan) {
    return { type: t.DISBURSE_LOAN_SUCCESS, loan }
  }

  function failure(error) {
    return { type: t.DISBURSE_LOAN_FAILURE, error }
  }
}

function undoDisbursement(data) {
  return (dispatch) => {
    dispatch(request(data))
    return api.loanService
      .undoDisbursement(data)
      .then((response) => {
        dispatch(success(response.data))
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }

  function request() {
    return { type: t.UNDO_DISBURSE_LOAN_REQUEST }
  }

  function success(loan) {
    return { type: t.UNDO_DISBURSE_LOAN_SUCCESS, loan }
  }

  function failure(error) {
    return { type: t.UNDO_DISBURSE_LOAN_FAILURE, error }
  }
}

function createRepayment(data) {
  return (dispatch) => {
    dispatch(request(data))
    return api.loanService
      .createRepayment(data)
      .then((response) => {
        toast.success('Tạo giao dịch thành công.')
        dispatch(success(response.data))
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }

  function request() {
    return { type: t.CREATE_PAYMENT_REQUEST }
  }

  function success(loan) {
    return { type: t.CREATE_PAYMENT_SUCCESS, loan }
  }

  function failure(error) {
    return { type: t.CREATE_PAYMENT_FAILURE, error }
  }
}

function getRepaymentDetail(data) {
  return (dispatch) => {
    dispatch(request(data))
    return api.loanService
      .getRepaymentDetail(data)
      .then((response) => {
        dispatch(success(response.data))
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }

  function request() {
    return { type: t.GET_PAYMENT_REQUEST }
  }

  function success(loan) {
    return { type: t.GET_PAYMENT_SUCCESS, loan }
  }

  function failure(error) {
    return { type: t.GET_PAYMENT_FAILURE, error }
  }
}

function reverseRepayment(data) {
  return (dispatch) => {
    dispatch(request(data))
    return api.loanService
      .reverseRepayment(data)
      .then((response) => {
        toast.success('Đã hủy giao dịch thành công.')
        dispatch(success(response.data))
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }

  function request() {
    return { type: t.REVERSE_PAYMENT_REQUEST }
  }

  function success(loan) {
    return { type: t.REVERSE_PAYMENT_SUCCESS, loan }
  }

  function failure(error) {
    return { type: t.REVERSE_PAYMENT_FAILURE, error }
  }
}

function updateRepayment(data) {
  return (dispatch) => {
    dispatch(request(data))
    return api.loanService
      .updateRepayment(data)
      .then((response) => {
        toast.success('Cập nhật giao dịch thành công.')
        dispatch(success(response.data))
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }

  function request() {
    return { type: t.UPDATE_PAYMENT_REQUEST }
  }

  function success(loan) {
    return { type: t.UPDATE_PAYMENT_SUCCESS, loan }
  }

  function failure(error) {
    return { type: t.UPDATE_PAYMENT_FAILURE, error }
  }
}

function processLoanCalculator(data) {
  return (dispatch) => {
    dispatch(request(data))
    return api.loanService
      .processLoanCalculator(data)
      .then((response) => {
        dispatch(success(response.data))
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }

  function request() {
    return { type: t.PROCESS_LOAN_CALCULATOR_REQUEST }
  }

  function success(loan) {
    return { type: t.PROCESS_LOAN_CALCULATOR_SUCCESS, loan }
  }

  function failure(error) {
    return { type: t.PROCESS_LOAN_CALCULATOR_FAILURE, error }
  }
}

function getPaymentTypes() {
  return (dispatch) => {
    dispatch(request())
    return api.loanService
      .getPaymentTypes()
      .then((response) => {
        dispatch(success(response.data))
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }

  function request() {
    return { type: t.GET_PAYMENT_TYPES_REQUEST }
  }

  function success(paymentTypes) {
    return { type: t.GET_PAYMENT_TYPES_SUCCESS, paymentTypes }
  }

  function failure(error) {
    return { type: t.GET_PAYMENT_TYPES_FAILURE, error }
  }
}

function getMethodRepayments() {
  return (dispatch) => {
    dispatch(request())
    return api.loanService
      .getMethodOfRepayments()
      .then((response) => {
        dispatch(success(response.data))
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }

  function request() {
    return { type: t.GET_METHOD_REPAYMENTS_REQUEST }
  }

  function success(methodRepayments) {
    return { type: t.GET_METHOD_REPAYMENTS_SUCCESS, methodRepayments }
  }

  function failure(error) {
    return { type: t.GET_METHOD_REPAYMENTS_FAILURE, error }
  }
}

function resetLoan() {
  return (dispatch) => {
    dispatch(request())
    dispatch(success())
  }

  function request() {
    return { type: t.RESET_LOAN_REQUEST }
  }

  function success() {
    return { type: t.RESET_LOAN_SUCCESS }
  }
}

function updateItemsPerPage(pages) {
  return (dispatch) => {
    dispatch(request(pages))
    dispatch(success(pages))
  }

  function request(pages) {
    return { type: t.UPDATE_LOANS_ITEMS_PER_PAGE_REQUEST, pages }
  }

  function success(itemsPerPage) {
    return { type: t.UPDATE_LOANS_ITEMS_PER_PAGE_SUCCESS, itemsPerPage }
  }
}

function confirmRepayment(data) {
  return (dispatch) => {
    dispatch(request(data))
    return api.loanService
      .confirmRepayment(data)
      .then((response) => {
        toast.success('Xác nhận giao dịch thành công.')
        dispatch(success(response.data))
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }

  function request() {
    return { type: t.CONFIRM_REPAYMENT_REQUEST }
  }

  function success(loan) {
    return { type: t.CONFIRM_REPAYMENT_SUCCESS, loan }
  }

  function failure(error) {
    return { type: t.CONFIRM_REPAYMENT_FAILURE, error }
  }
}

function payoffDebtRepayment(data) {
  return (dispatch) => {
    dispatch(request(data))
    return api.loanService
      .payoffDebtRepayment(data)
      .then((response) => {
        toast.success('Tất toán khoản vay thành công.')
        dispatch(success(response.data))
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }

  function request() {
    return { type: t.PAY_OFF_DEBT_REPAYMENT_REQUEST }
  }

  function success(loan) {
    return { type: t.PAY_OFF_DEBT_REPAYMENT_SUCCESS, loan }
  }

  function failure(error) {
    return { type: t.PAY_OFF_DEBT_REPAYMENT_FAILURE, error }
  }
}

function closeLoan(data) {
  return (dispatch) => {
    dispatch(request(data))
    return api.loanService
      .closeLoan(data)
      .then((response) => {
        dispatch(success(response.data))
        window.location.reload()
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }

  function request() {
    return { type: t.CLOSE_LOAN_REQUEST }
  }

  function success(loan) {
    return { type: t.CLOSE_LOAN_SUCCESS, loan }
  }

  function failure(error) {
    return { type: t.CLOSE_LOAN_FAILURE, error }
  }
}

function getLoanNotes(data) {
  return (dispatch) => {
    dispatch(request(data))
    return api.loanService
      .getLoanNotes(data)
      .then((response) => {
        dispatch(success(response.data))
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }

  function request() {
    return { type: t.GET_LOAN_NOTES_REQUEST }
  }

  function success(data) {
    return { type: t.GET_LOAN_NOTES_SUCCESS, data }
  }

  function failure(error) {
    return { type: t.GET_LOAN_NOTES_FAILURE, error }
  }
}

function getLoanNoteDetail(data) {
  return (dispatch) => {
    dispatch(request(data))
    return api.loanService
      .getLoanNoteDetail(data)
      .then((response) => {
        dispatch(success(response.data))
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }

  function request() {
    return { type: t.GET_LOAN_NOTE_DETAIL_REQUEST }
  }

  function success(loanNote) {
    return { type: t.GET_LOAN_NOTE_DETAIL_SUCCESS, loanNote }
  }

  function failure(error) {
    return { type: t.GET_LOAN_NOTE_DETAIL_FAILURE, error }
  }
}

function createLoanNote(data) {
  return (dispatch) => {
    dispatch(request(data))
    return api.loanService
      .createLoanNote(data)
      .then((response) => {
        toast.success('Tạo ghi chú thành công.')
        dispatch(success(response.data))
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }

  function request() {
    return { type: t.CREATE_LOAN_NOTE_REQUEST }
  }

  function success(data) {
    return { type: t.CREATE_LOAN_NOTE_SUCCESS, data }
  }

  function failure(error) {
    return { type: t.CREATE_LOAN_NOTE_FAILURE, error }
  }
}

function updateLoanNote(data) {
  return (dispatch) => {
    dispatch(request(data))
    return api.loanService
      .updateLoanNote(data)
      .then((response) => {
        toast.success('Cập nhật ghi chú thành công')
        dispatch(success(response.data))
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }

  function request() {
    return { type: t.UPDATE_LOAN_NOTE_REQUEST }
  }

  function success(data) {
    return { type: t.UPDATE_LOAN_NOTE_SUCCESS, data }
  }

  function failure(error) {
    return { type: t.UPDATE_LOAN_NOTE_FAILURE, error }
  }
}

function deleteLoanNote(data) {
  return (dispatch) => {
    dispatch(request(data))
    return api.loanService
      .deleteLoanNote(data)
      .then((response) => {
        toast.success('Xóa ghi chú thành công.')
        dispatch(success(response.data))
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }

  function request() {
    return { type: t.DELETE_LOAN_NOTE_REQUEST }
  }

  function success(data) {
    return { type: t.DELETE_LOAN_NOTE_SUCCESS, data }
  }

  function failure(error) {
    return { type: t.DELETE_LOAN_NOTE_FAILURE, error }
  }
}

function blacklistLoan(data) {
  return (dispatch) => {
    dispatch(request(data))
    return api.loanService
      .blacklistLoanById(data)
      .then((response) => {
        dispatch(success(response.data))
        window.location.href = `${window.location.origin}/#/loans/banned`
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }

  function request() {
    return { type: t.BLACKLIST_LOAN_REQUEST }
  }

  function success(loan) {
    return { type: t.BLACKLIST_LOAN_SUCCESS, loan }
  }

  function failure(error) {
    return { type: t.BLACKLIST_LOAN_FAILURE, error }
  }
}

function updateLoanPledges(pledges) {
  return (dispatch) => {
    dispatch(request(pledges))
    dispatch(success(pledges))
  }

  function request(pledges) {
    return { type: t.UPDATE_LOAN_PLEDGES_REQUEST, pledges }
  }

  function success(pledges) {
    return { type: t.UPDATE_LOAN_PLEDGES_SUCCESS, pledges }
  }
}

function updateLoanWarning(data) {
  return (dispatch) => {
    dispatch(request(data))
    return api.loanService
      .updateLoanWarning(data)
      .then((response) => {
        toast.success('Cập nhật cảnh báo khoản vay thành công.')
        dispatch(success(response.data))
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }

  function request() {
    return { type: t.UPDATE_LOAN_REQUEST }
  }

  function success(loan) {
    return { type: t.UPDATE_LOAN_SUCCESS, loan }
  }

  function failure(error) {
    return { type: t.UPDATE_LOAN_FAILURE, error }
  }
}

function updateLoanWatching(data) {
  return (dispatch) => {
    dispatch(request(data))
    return api.loanService
      .updateLoanWatching(data)
      .then((response) => {
        toast.success('Cập nhật theo dõi khoản vay thành công.')
        dispatch(success(response.data))
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }

  function request() {
    return { type: t.UPDATE_LOAN_REQUEST }
  }

  function success(loan) {
    return { type: t.UPDATE_LOAN_SUCCESS, loan }
  }

  function failure(error) {
    return { type: t.UPDATE_LOAN_FAILURE, error }
  }
}

function createLoanCalculator(data) {
  return (dispatch) => {
    dispatch(request(data))
    return api.loanService
      .generateLoanCalculator(data)
      .then((response) => {
        dispatch(success(response.data))
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }

  function request() {
    return { type: t.CALCULATE_LOAN_REQUEST }
  }

  function success(loan) {
    return { type: t.CALCULATE_LOAN_SUCCESS, loan }
  }

  function failure(error) {
    return { type: t.CALCULATE_LOAN_FAILURE, error }
  }
}

export const loanActions = {
  createLoan,
  updateLoan,
  updateLoanPenalty,
  deleteLoan,
  resetLoan,
  getAllLoans,
  getLoanById,
  approveLoan,
  undoApprovalLoan,
  rejectLoan,
  blacklistLoan,
  undoRejectLoan,
  disburseLoan,
  undoDisbursement,
  createRepayment,
  getRepaymentDetail,
  reverseRepayment,
  updateRepayment,
  processLoanCalculator,
  getPaymentTypes,
  getMethodRepayments,
  confirmRepayment,
  payoffDebtRepayment,
  closeLoan,
  getLoanNotes,
  getLoanNoteDetail,
  createLoanNote,
  updateLoanNote,
  deleteLoanNote,
  updateItemsPerPage,
  updateLoanPledges,
  updateLoanWarning,
  createLoanCalculator,
  updateLoanWatching,
}
