const usersData = [
  {
    id: 0,
    name: 'John Doe',
    email: 'john.doe@mailinator.com',
    username: 'john.doe@mailinator.com',
    phone: '0909123456',
    registered: '2018/01/01',
    role: 'Guest',
    status: 'Pending',
  },
  {
    id: 1,
    name: 'Samppa Nori',
    email: '',
    username: '',
    phone: '0909123456',
    registered: '2018/01/01',
    role: 'Member',
    status: 'Active',
  },
  {
    id: 2,
    name: 'Estavan Lykos',
    email: '',
    username: '',
    phone: '0909123456',
    registered: '2018/02/01',
    role: 'Staff',
    status: 'Banned',
  },
  {
    id: 3,
    name: 'Chetan Mohamed',
    email: '',
    username: '',
    phone: '0909123456',
    registered: '2018/02/01',
    role: 'Admin',
    status: 'Inactive',
  },
  {
    id: 4,
    name: 'Derick Maximinus',
    email: '',
    username: '',
    phone: '0909123456',
    registered: '2018/03/01',
    role: 'Member',
    status: 'Pending',
  },
  {
    id: 5,
    name: 'Friderik Dávid',
    email: '',
    username: '',
    phone: '0909123456',
    registered: '2018/01/21',
    role: 'Staff',
    status: 'Active',
  },
  {
    id: 6,
    name: 'Yiorgos Avraamu',
    email: '',
    username: '',
    phone: '0909123456',
    registered: '2018/01/01',
    role: 'Member',
    status: 'Active',
  },
  {
    id: 7,
    name: 'Avram Tarasios',
    email: '',
    username: '',
    phone: '0909123456',
    registered: '2018/02/01',
    role: 'Staff',
    status: 'Banned',
  },
  {
    id: 8,
    name: 'Quintin Ed',
    email: '',
    username: '',
    phone: '0909123456',
    registered: '2018/02/01',
    role: 'Admin',
    status: 'Inactive',
  },
  {
    id: 9,
    name: 'Enéas Kwadwo',
    email: '',
    username: '',
    phone: '0909123456',
    registered: '2018/03/01',
    role: 'Member',
    status: 'Pending',
  },
  {
    id: 10,
    name: 'Agapetus Tadeáš',
    email: '',
    username: '',
    phone: '0909123456',
    registered: '2018/01/21',
    role: 'Staff',
    status: 'Active',
  },
  {
    id: 11,
    name: 'Carwyn Fachtna',
    email: '',
    username: '',
    phone: '0909123456',
    registered: '2018/01/01',
    role: 'Member',
    status: 'Active',
  },
  {
    id: 12,
    name: 'Nehemiah Tatius',
    email: '',
    username: '',
    phone: '0909123456',
    registered: '2018/02/01',
    role: 'Staff',
    status: 'Banned',
  },
  {
    id: 13,
    name: 'Ebbe Gemariah',
    email: '',
    username: '',
    phone: '0909123456',
    registered: '2018/02/01',
    role: 'Admin',
    status: 'Inactive',
  },
  {
    id: 14,
    name: 'Eustorgios Amulius',
    email: '',
    username: '',
    phone: '0909123456',
    registered: '2018/03/01',
    role: 'Member',
    status: 'Pending',
  },
  {
    id: 15,
    name: 'Leopold Gáspár',
    email: '',
    username: '',
    phone: '0909123456',
    registered: '2018/01/21',
    role: 'Staff',
    status: 'Active',
  },
  {
    id: 16,
    name: 'Pompeius René',
    email: '',
    username: '',
    phone: '0909123456',
    registered: '2018/01/01',
    role: 'Member',
    status: 'Active',
  },
  {
    id: 17,
    name: 'Paĉjo Jadon',
    email: '',
    username: '',
    phone: '0909123456',
    registered: '2018/02/01',
    role: 'Staff',
    status: 'Banned',
  },
  {
    id: 18,
    name: 'Micheal Mercurius',
    email: '',
    username: '',
    phone: '0909123456',
    registered: '2018/02/01',
    role: 'Admin',
    status: 'Inactive',
  },
  {
    id: 19,
    name: 'Ganesha Dubhghall',
    email: '',
    username: '',
    phone: '0909123456',
    registered: '2018/03/01',
    role: 'Member',
    status: 'Pending',
  },
  {
    id: 20,
    name: 'Hiroto Šimun',
    email: '',
    username: '',
    phone: '0909123456',
    registered: '2018/01/21',
    role: 'Staff',
    status: 'Active',
  },
  {
    id: 21,
    name: 'Vishnu Serghei',
    email: '',
    username: '',
    phone: '0909123456',
    registered: '2018/01/01',
    role: 'Member',
    status: 'Active',
  },
  {
    id: 22,
    name: 'Zbyněk Phoibos',
    email: '',
    username: '',
    phone: '0909123456',
    registered: '2018/02/01',
    role: 'Staff',
    status: 'Banned',
  },
  {
    id: 23,
    name: 'Aulus Agmundr',
    email: '',
    username: '',
    phone: '0909123456',
    registered: '2018/01/01',
    role: 'Member',
    status: 'Pending',
  },
  {
    id: 42,
    name: 'Ford Prefect',
    email: '',
    username: '',
    phone: '0909123456',
    registered: '2001/05/25',
    role: 'Alien',
    status: "Don't panic!",
  },
]

export default usersData
