import axios from 'axios'
import { SERVICE_DOMAIN, SERVICE_ELEND_DOMAIN } from 'src/constants/constants'
import { accountHelpers } from '../utils/account-helper'

const getReportBorrowers = (organizationId, year) => {
  const accessedOrganizationId = accountHelpers.getMyAccessOrganizationId()
  if (accessedOrganizationId) {
    if (year) {
      return axios.get(
        SERVICE_ELEND_DOMAIN +
          `/api/reports/organization/${accessedOrganizationId}/year/${year}/borrowers`,
      )
    }
    return axios.get(
      SERVICE_ELEND_DOMAIN + `/api/reports/organization/${accessedOrganizationId}/borrowers`,
    )
  }
  return axios.get(SERVICE_ELEND_DOMAIN + `/api/reports/borrowers`)
}

const getReportLoans = (organizationId, year) => {
  const accessedOrganizationId = accountHelpers.getMyAccessOrganizationId()
  if (accessedOrganizationId) {
    if (year) {
      return axios.get(
        SERVICE_ELEND_DOMAIN +
          `/api/reports/organization/${accessedOrganizationId}/year/${year}/loans`,
      )
    }
    return axios.get(
      SERVICE_ELEND_DOMAIN + `/api/reports/organization/${accessedOrganizationId}/loans`,
    )
  }
  return axios.get(SERVICE_ELEND_DOMAIN + `/api/reports/loans`)
}

// const getReportLoanAnalysis = (organizationId, loanStatus, year) => {
//   const accessedOrganizationId = accountHelpers.getMyAccessOrganizationId()
//   if (accessedOrganizationId) {
//     if (year) {
//       return axios.get(
//         SERVICE_ELEND_DOMAIN +
//           `/api/reports/organization/${accessedOrganizationId}/year/${year}/loans/analysis?status=${loanStatus}`,
//       )
//     }
//     return axios.get(
//       SERVICE_ELEND_DOMAIN +
//         `/api/reports/organization/${accessedOrganizationId}/loans/analysis?status=${loanStatus}`,
//     )
//   }
//   return axios.get(SERVICE_ELEND_DOMAIN + `/api/reports/loans/analysis?status=${loanStatus}`)
// }

const getReportLoanAnalysisByMonthly = (organizationId, year) => {
  const accessedOrganizationId = accountHelpers.getMyAccessOrganizationId()
  if (accessedOrganizationId) {
    return axios.get(
      SERVICE_ELEND_DOMAIN +
        `/api/reports/organization/${accessedOrganizationId}/loans/analysis/${year}/months`,
    )
  }
  return axios.get(SERVICE_ELEND_DOMAIN + `/api/reports/loans/analysis/${year}/months`)
}

const getReportAllLoansDisbursement = (data) => {
  let url = SERVICE_ELEND_DOMAIN + `/api/reports/loans/disbursement?sort=createdDate,desc`
  const accessedOrganizationId = accountHelpers.getMyAccessOrganizationId()
  if (accessedOrganizationId) {
    url += `&organizationId.equals=${accessedOrganizationId}`
  }

  if (data.isWatching) {
    url += `&isWatching.equals=${data.isWatching}`
  }

  if (data.startDate && data.endDate) {
    url += `&startDate.equals=${data.startDate}&endDate.equals=${data.endDate}`
  }

  return axios.get(url)
}

const getReportAllLoanTransaction = (data) => {
  let url = SERVICE_ELEND_DOMAIN + `/api/reports/loans/transaction?sort=createdDate,desc`
  const accessedOrganizationId = accountHelpers.getMyAccessOrganizationId()
  if (accessedOrganizationId) {
    url += `&organizationId.equals=${accessedOrganizationId}`
  }
  if (data.startDate && data.endDate) {
    url += `&startDate.equals=${data.startDate}&endDate.equals=${data.endDate}`
  }

  if (data.loanOfficerId && data.loanOfficerId.length > 0) {
    url += `&loanOfficerId.equals=${data.loanOfficerId}`
  }

  if (data.selectedMethodOption && data.selectedMethodOption.toLowerCase() !== 'all') {
    url += `&methodRepayment.equals=${data.selectedMethodOption}`
  }
  if (data.selectedConfirmOption > -1) {
    url += `&isConfirmation.equals=${data.selectedConfirmOption === 1}`
  }

  return axios.get(url)
}

const getEmployees = (data) => {
  let url = SERVICE_DOMAIN + `/api/cms/account/get?sort=createdDate,desc`

  if (data.name && data.name.length > 0) {
    url += `&name.equals=${data.name}`
  }
  const accessedOrganizationId = accountHelpers.getMyAccessOrganizationId()
  if (accessedOrganizationId) {
    url += `&organizationId.equals=${accessedOrganizationId}`
  }

  if (data.authorities && data.authorities.length > 0) {
    url += `&authorities.equals=${data.authorities}`
  }

  if (data && data.all) {
    return axios.get(url)
  } else {
    url += `&page=${data.page}&size=${data.size}`
  }

  return axios.get(url)
}

const downloadReportAllLoanTransaction = (data) => {
  let url =
    SERVICE_ELEND_DOMAIN +
    `/api/reports/loans/transaction/reportLoansTransaction.csv?sort=createdDate,desc`
  const accessedOrganizationId = accountHelpers.getMyAccessOrganizationId()
  if (accessedOrganizationId) {
    url += `&organizationId.equals=${accessedOrganizationId}`
  }
  if (data.startDate && data.endDate) {
    url += `&startDate.equals=${data.startDate}&endDate.equals=${data.endDate}`
  }

  if (data.loanOfficerId && data.loanOfficerId.length > 0) {
    url += `&loanOfficerId.equals=${data.loanOfficerId}`
  }

  if (data.selectedMethodOption && data.selectedMethodOption.toLowerCase() !== 'all') {
    url += `&methodRepayment.equals=${data.selectedMethodOption}`
  }
  if (data.selectedConfirmOption > -1) {
    url += `&isConfirmation.equals=${data.selectedConfirmOption === 1}`
  }

  if (data.lang && data.lang.length > 0) {
    url += `&lang.equals=${data.lang}`
  }

  return axios.get(url)
}

const downloadReportAllLoansDisbursement = (data) => {
  let url =
    SERVICE_ELEND_DOMAIN + `/api/reports/loans/disbursement/reportLoans.csv?sort=createdDate,desc`
  const accessedOrganizationId = accountHelpers.getMyAccessOrganizationId()
  if (accessedOrganizationId) {
    url += `&organizationId.equals=${accessedOrganizationId}`
  }
  if (data.startDate && data.endDate) {
    url += `&startDate.equals=${data.startDate}&endDate.equals=${data.endDate}`
  }
  if (data.lang && data.lang.length > 0) {
    url += `&lang.equals=${data.lang}`
  }

  return axios.get(url)
}

const getReportAllOverdueLoans = (data) => {
  let url = SERVICE_ELEND_DOMAIN + `/api/reports/borrowers/loan-overdue?sort=createdDate,desc`
  const accessedOrganizationId = accountHelpers.getMyAccessOrganizationId()
  if (accessedOrganizationId) {
    url += `&organizationId.equals=${accessedOrganizationId}`
  }

  if (!data.startDate) {
    url += `&endDate.equals=${data.endDate}`
  } else if (data.startDate && data.endDate) {
    url += `&startDate.equals=${data.startDate}&endDate.equals=${data.endDate}`
  }

  return axios.get(url)
}

const getDownloadReportAllOverdueLoans = (data) => {
  let url =
    SERVICE_ELEND_DOMAIN +
    `/api/reports/borrowers/loan-overdue/reportBorrowersHaveOverdueLoans.csv?sort=createdDate,desc`
  const accessedOrganizationId = accountHelpers.getMyAccessOrganizationId()
  if (accessedOrganizationId) {
    url += `&organizationId.equals=${accessedOrganizationId}`
  }

  if (!data.startDate) {
    url += `&endDate.equals=${data.endDate}`
  } else if (data.startDate && data.endDate) {
    url += `&startDate.equals=${data.startDate}&endDate.equals=${data.endDate}`
  }

  if (data.lang && data.lang.length > 0) {
    url += `&lang.equals=${data.lang}`
  }

  return axios.get(url)
}

const getReportLoanSummary = (organizationId, year) => {
  const accessedOrganizationId = accountHelpers.getMyAccessOrganizationId()
  return axios.get(
    SERVICE_ELEND_DOMAIN +
      `/api/reports/loans/summary/organization/${accessedOrganizationId}/year/${year}`,
  )
}
const getReportLoanRepaymentSchedules = (organizationId, year) => {
  const accessedOrganizationId = accountHelpers.getMyAccessOrganizationId()
  return axios.get(
    SERVICE_ELEND_DOMAIN +
      `/api/reports/loans/repayments/organization/${accessedOrganizationId}/year/${year}`,
  )
}

const getReportLoanTransactionsPerYear = (organizationId, year) => {
  const accessedOrganizationId = accountHelpers.getMyAccessOrganizationId()
  return axios.get(
    SERVICE_ELEND_DOMAIN +
      `/api/reports/loans/transactions/organization/${accessedOrganizationId}/year/${year}`,
  )
}

export const reportService = {
  getReportBorrowers,
  getReportLoans,
  // getReportLoanAnalysis,
  getReportLoanAnalysisByMonthly,
  getReportAllLoansDisbursement,
  getReportAllLoanTransaction,
  getEmployees,
  downloadReportAllLoansDisbursement,
  downloadReportAllLoanTransaction,
  getReportAllOverdueLoans,
  getDownloadReportAllOverdueLoans,
  getReportLoanSummary,
  getReportLoanRepaymentSchedules,
  getReportLoanTransactionsPerYear,
}
