export const CREATE_ORGANIZATION_REQUEST = 'CREATE_ORGANIZATION_REQUEST'
export const CREATE_ORGANIZATION_SUCCESS = 'CREATE_ORGANIZATION_SUCCESS'
export const CREATE_ORGANIZATION_FAILURE = 'CREATE_ORGANIZATION_FAILURE'

export const UPDATE_ORGANIZATION_REQUEST = 'UPDATE_ORGANIZATION_REQUEST'
export const UPDATE_ORGANIZATION_SUCCESS = 'UPDATE_ORGANIZATION_SUCCESS'
export const UPDATE_ORGANIZATION_FAILURE = 'UPDATE_ORGANIZATION_FAILURE'

export const UPDATE_ORGANIZATION_LOGO_URL_REQUEST = 'UPDATE_ORGANIZATION_LOGO_URL_REQUEST'
export const UPDATE_ORGANIZATION_LOGO_URL_SUCCESS = 'UPDATE_ORGANIZATION_LOGO_URL_SUCCESS'

export const UPDATE_ADD_MEMBERS_TO_ORGANIZATION_REQUEST =
  'UPDATE_ADD_MEMBERS_TO_ORGANIZATION_REQUEST'
export const UPDATE_ADD_MEMBERS_TO_ORGANIZATION_SUCCESS =
  'UPDATE_ADD_MEMBERS_TO_ORGANIZATION_SUCCESS'

export const UPDATE_REMOVE_MEMBERS_FROM_ORGANIZATION_REQUEST =
  'UPDATE_REMOVE_MEMBERS_FROM_ORGANIZATION_REQUEST'
export const UPDATE_REMOVE_MEMBERS_FROM_ORGANIZATION_SUCCESS =
  'UPDATE_REMOVE_MEMBERS_FROM_ORGANIZATION_SUCCESS'

export const GET_ORGANIZATION_REQUEST = 'GET_ORGANIZATION_REQUEST'
export const GET_ORGANIZATION_SUCCESS = 'GET_ORGANIZATION_SUCCESS'
export const GET_ORGANIZATION_FAILURE = 'GET_ORGANIZATION_FAILURE'

export const GET_ORGANIZATIONS_REQUEST = 'GET_ORGANIZATIONS_REQUEST'
export const GET_ORGANIZATIONS_SUCCESS = 'GET_ORGANIZATIONS_SUCCESS'
export const GET_ORGANIZATIONS_FAILURE = 'GET_ORGANIZATIONS_FAILURE'

export const DELETE_ORGANIZATION_REQUEST = 'DELETE_ORGANIZATION_REQUEST'
export const DELETE_ORGANIZATION_SUCCESS = 'DELETE_ORGANIZATION_SUCCESS'
export const DELETE_ORGANIZATION_FAILURE = 'DELETE_ORGANIZATION_FAILURE'

export const GET_SEARCH_MEMBERS_TO_ORGANIZATION_REQUEST =
  'GET_SEARCH_MEMBERS_TO_ORGANIZATION_REQUEST'
export const GET_SEARCH_MEMBERS_TO_ORGANIZATION_SUCCESS =
  'GET_SEARCH_MEMBERS_TO_ORGANIZATION_SUCCESS'
export const GET_SEARCH_MEMBERS_TO_ORGANIZATION_FAILURE =
  'GET_SEARCH_MEMBERS_TO_ORGANIZATION_FAILURE'

export const GET_SEARCH_MEMBERS_IN_ORGANIZATION_REQUEST =
  'GET_SEARCH_MEMBERS_IN_ORGANIZATION_REQUEST'
export const GET_SEARCH_MEMBERS_IN_ORGANIZATION_SUCCESS =
  'GET_SEARCH_MEMBERS_IN_ORGANIZATION_SUCCESS'
export const GET_SEARCH_MEMBERS_IN_ORGANIZATION_FAILURE =
  'GET_SEARCH_MEMBERS_IN_ORGANIZATION_FAILURE'

export const UPDATE_MEMBERS_ORGANIZATION_REQUEST = 'UPDATE_MEMBERS_ORGANIZATION_REQUEST'
export const UPDATE_MEMBERS_ORGANIZATION_SUCCESS = 'UPDATE_MEMBERS_ORGANIZATION_SUCCESS'
export const UPDATE_MEMBERS_ORGANIZATION_FAILURE = 'UPDATE_MEMBERS_ORGANIZATION_FAILURE'
