import * as React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import PrivateRoute from './components/private-route'

const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Page403 = React.lazy(() => import('./views/pages/page403/Page403'))
const TermsAndCondition = React.lazy(() => import('./views/terms-condition/terms-and-condition'))
const PrivacyPolicy = React.lazy(() => import('./views/terms-condition/privacy-policy'))

export const AppRouter = () => {
  return (
    <Routes>
      <Route element={<PrivateRoute />}>
        <Route path="/*" element={<DefaultLayout />} />
      </Route>
      <Route path="/" element={<Navigate to="dashboard" replace />} />
      <Route path="/login" element={<Login />} />
      <Route path="/403" element={<Page403 />} />
      <Route path="/terms-and-condition" element={<TermsAndCondition />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
    </Routes>
  )
}
